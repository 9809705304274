import { useQuery, UseQueryResult } from "react-query";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";
// @ts-ignore
import { mask } from "remask";
import { OptionsFilter } from "../../@types";
import { Contact } from "../../pages/create/CreateClient/Contacts";
import { Concept } from "./useClients";

export type RequestUpdateClient = {
  id: number;
  created_at: Date;
  finished_at: Date;
  type: string;
  typeUser: string;
  observation: string;

  typeFormatted: string;
  typeUserFormatted: string;
  created_atFormatted: string;
  statusFormatted: string;
  clientFormatted: string;
  finished_atFormatted: string;
  concept: Concept;

  file: {
    id: true;
  }[];

  seller: {
    cod: number;
    abbreviation: string;
    email: string;
    fullName: string;

    allOfficeSeller?: {
      cod: number;
      abbreviation: string;
      typeSeller: string;
    }[];
  };

  user?: {
    id: number;
    name: string;
    lastname: string;
    email: string;
    permission: {
      name: string;
    };
  };

  client_pj?: {
    cod: number;
    trade_name: string;
    cnpj: string;
    email: string;
    emailInvoice?: string;
    suframa?: string;
    stateRegistration?: string;
    overseer_name?: string;
    phone: string;
    phone2: string;
    cellPhone: string;
    cnpjFormatted: string;
    company_name: string;
    concept: Concept;

    registerGroup: {
      cod: string;
      name: string;
    };

    adresses?: {
      city: string;
      neighborhood: string;
      numberStreet: number;
      street: string;
      uf: string;
      zip_code: string;
      zip_codeFormat: string;
      complement?: string;
    };

    contacts: Contact[];
  };

  client_pj_duplicate?: {
    cod?: number;
    cnpj: string;
    trade_name: string;
    emailInvoice: string;
    emailAccountant: string;
    overseer_name: string;
    phone: string;
    phone2: string;
    cellPhone: string;
    cnpjFormatted: string;
    company_name: string;
    suframa: string;
    stateRegistration: string;
    observation: string;

    registerGroup: {
      cod: string;
      name: string;
    };

    contacts: Contact[];

    concept: Concept;
    address?: {
      city: string;
      neighborhood: string;
      numberStreet: number;
      street: string;
      uf: string;
      zip_code: string;
      zip_codeFormat: string;
      complement?: string;
    };
  };

  status?: {
    cod: number;
    name: string;
    color: string;
    is_finisher: string;
  };
  resposable: {
    id: number;
    name: string;
    lastname: string;
    email: string;
  };
};

export type RequestUpdateClientFilters = {
  removeUserId?: number;
  client?: string;
  type?: string;
  statusCode?: string;
  isFinished?: string;
};

type GetRequestUpdateClientsResponse = {
  requestUpdateClients: RequestUpdateClient[];
  filters: FilterOptions;
  totalCount: number;
};

export interface FilterOptions {
  status: OptionsFilter[];
  types: OptionsFilter[];
  sellers: OptionsFilter[];
}

interface RequestUpdateClientApiResponse extends ResponsePanelApiDefault {
  contents: RequestUpdateClient[];
  filters: FilterOptions;
}

export function typeToFormatted(type: string) {
  switch (type) {
    case "image":
      return "Imagem";
    case "register":
      return "Cadastro";
    case "update":
      return "Alteração";

    default:
      return "-";
  }
}

export function userTypeToFormatted(type: string) {
  switch (type) {
    case "client":
      return "Cliente";
    case "seller":
      return "Representante";
    case "user":
      return "Operador";

    default:
      return "-";
  }
}

export async function getRequestUpdateClient(
  page: number,
  filters?: RequestUpdateClientFilters,
  registersPerPag?: number
): Promise<GetRequestUpdateClientsResponse> {
  const { data } = await api.get<RequestUpdateClientApiResponse>(
    "/request-update-client",
    {
      params: {
        pageRequest: page - 1,
        limitRequest: registersPerPag,
        ...filters,
      },
    }
  );

  const requestUpdateClients = data.contents.map((request) => ({
    ...request,
    clientFormatted:
      (request.type === "register"
        ? request.client_pj_duplicate?.company_name
        : request.client_pj?.company_name) ?? "",
    statusFormatted: request.status ? request.status.name : "-",
    client_pj: request.client_pj
      ? {
          ...request.client_pj,
          cnpjFormatted: request.client_pj?.cnpj
            ? mask(request.client_pj?.cnpj, ["99.999.999/9999-99"])
            : "-",
        }
      : undefined,
    client_pj_duplicate: request.client_pj_duplicate
      ? {
          ...request.client_pj_duplicate,
          cnpjFormatted: request.client_pj_duplicate?.cnpj
            ? mask(request.client_pj_duplicate?.cnpj, ["99.999.999/9999-99"])
            : "-",
        }
      : undefined,
    typeFormatted: typeToFormatted(request.type),

    created_atFormatted: new Date(request.created_at).toLocaleDateString(
      "pt-BR",
      {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }
    ),
    finished_atFormatted: new Date(request.finished_at).toLocaleDateString(
      "pt-BR",
      {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }
    ),
  }));
  const totalCount = data.total;

  return {
    totalCount,
    requestUpdateClients,
    filters: data.filters,
  };
}

export function useRequestUpdateClient(
  page: number,
  filters?: RequestUpdateClientFilters,
  registersPerPag?: number
): UseQueryResult<GetRequestUpdateClientsResponse> {
  return useQuery(
    ["RequestUpdateClients", page, filters],
    () => getRequestUpdateClient(page, filters, registersPerPag),
    {
      staleTime: 10,
    }
  );
}

export async function getOneRequestUpdateClient(
  id?: number
): Promise<RequestUpdateClient | undefined> {
  if (!id) return undefined;

  const { data } = await api.get<RequestUpdateClient>(
    `/request-update-client/${id}`
  );

  return {
    ...data,

    client_pj: data.client_pj
      ? {
          ...data.client_pj,
          adresses: data.client_pj?.adresses
            ? {
                ...data.client_pj?.adresses,
                zip_codeFormat: mask(data.client_pj?.adresses?.zip_code, [
                  "99999-999",
                ]),
              }
            : undefined,
          cnpjFormatted: data.client_pj?.cnpj
            ? mask(data.client_pj?.cnpj, ["99.999.999/9999-99"])
            : "-",
        }
      : undefined,
    client_pj_duplicate: data.client_pj_duplicate
      ? {
          ...data.client_pj_duplicate,
          address: data.client_pj_duplicate?.address
            ? {
                ...data.client_pj_duplicate?.address,
                zip_codeFormat: mask(
                  data.client_pj_duplicate?.address?.zip_code,
                  ["99999-999"]
                ),
              }
            : undefined,
          cnpjFormatted: data.client_pj_duplicate?.cnpj
            ? mask(data.client_pj_duplicate?.cnpj, ["99.999.999/9999-99"])
            : "-",
        }
      : undefined,
    typeFormatted: typeToFormatted(data.type),
    typeUserFormatted: userTypeToFormatted(data?.typeUser),
    created_atFormatted: new Date(data.created_at).toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }),
    finished_atFormatted: new Date(data.finished_at).toLocaleDateString(
      "pt-BR",
      {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }
    ),
  };
}

export function useOneRequestUpdateClient(
  id?: number
): UseQueryResult<RequestUpdateClient> {
  return useQuery(
    ["RequestUpdateClient", id],
    () => getOneRequestUpdateClient(id),
    {
      staleTime: 10,
    }
  );
}
