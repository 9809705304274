import * as ExcelJS from "exceljs";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { MdArrowDownward } from "react-icons/md";
import { RiFileExcel2Line } from "react-icons/ri";
import { VscFileSymlinkFile } from "react-icons/vsc";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Loading from "../../../components/loadings/Loading";
import useOutsideAlerter from "../../../hook/outsideAlerter";
import api from "../../../service/api";
import { generateXlsx } from "../../../utils/generateXlsx";

import axios from "axios";
import { AiOutlineFileSearch } from "react-icons/ai";
import { Accordion } from "../../../components/Accordion";
import {
  normalizedDateTypeAndBillingDate,
  positionValid,
} from "../../../hook/queries/useOrders";
import getImageByUrl from "../../../utils/getImageByUrl";
import { groupByObj } from "../../../utils/groupByObj";
import { ReportDownload } from "../../panel/styles";
import {
  Container,
  ContainerListProducts,
  InfoDevolution,
  InfoStatus,
  ScreenLoading,
} from "../styles";
import { Product } from "./Product";
import { Tracking } from "./Tracking";

interface HomeProps {
  location: {
    state: {
      content: OrderProps;
      position: string;
    };
  };

  match: {
    params: {
      id: number;
    };
  };

  admin: boolean;
  client: boolean;
  seller: boolean;
  is_manager?: boolean;
  is_supervisor?: boolean;
}

interface IMailTo {
  name: string | undefined;
  email: string | undefined;
}

type OrderProps = {
  id: number;
  cod: number;
  initialsOrder: string | null;
  merchandiseValue: number;
  species: number | null;
  deliveryDate: Date;
  billingDate: Date;
  updated_at: Date;
  paymentCondition: string | null;

  items: ItemOrderProps[];
  groupItems: GroupItemsStatus[];

  status: {
    name: string;
    description: string;
    order_position_filter: {
      name: string;
      description: string;
    } | null;
  } | null;
  client: {
    cod: number;
    cnpj: string;
    email: string;
    company_name: string;
    trade_name: string;
    adresses: {
      city: string;
      uf: string;
    } | null;
  } | null;
  seller: {
    id: number;
    cod: number;
    abbreviation: string;
    fullName: string;
    email: string;
  } | null;
  brand: {
    id: number;
    cod?: number;
    name?: string;
    is_not_show_delivery_at?: boolean;
  } | null;
};

type GroupItemsStatus = {
  status: string;
  totalItens: number;
  groups: GroupItemsOrder[];
};
type GroupItemsOrder = {
  currentOrderCod?: number;
  documentNumber?: string;
  suspended?: boolean;
  invoiceDate?: Date;
  tracking?: TrackingProps;
  items: ItemOrderProps[];
  pointedCod: number | null;
  pointedDesc: string | null;
  refuseCod: number | null;
  refuseDesc: string | null;
  highlighter: { description: string }[];
};

type ItemOrderProps = {
  id: string;
  status: string;
  quantity: number;
  value: number;
  totalValue: number;
  discount: number;

  currentOrderCod: number | null;
  documentNumber: string | null;
  pointedCod: number | null;
  pointedDesc: string | null;
  refuseCod: number | null;
  refuseDesc: string | null;
  suspended?: boolean;

  product: ProductProps;
  highlighter: { description: string }[];
};

type ProductProps = {
  cod: number;
  description: string;
  reference: string;
  grid: string;
  origin: string;
  primaryColor: string;
  secondaryColor: string;
};

type TrackingProps = {
  cod: string;
  description: string;
  expectedDate?: Date;
  deliveryDate?: Date;
  date: Date;
  isDelivered: Boolean;
};

//const APP_LINK =process.env.NODE_ENV !== "development"? "http://portal.alpardobrasil.com.br": "http://localhost:3000";

const Order: React.FC<HomeProps> = ({ location, match, client }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [contentPage, setContentPage] = useState<OrderProps>({} as OrderProps);
  const [loading, setLoading] = useState(false);

  const [loadingReport, setLoadingReport] = useState(false);
  const outSideRefReport = useRef(null);
  const [visibleReport, setVisibleReport] = useState(false);

  const [onSelectExportOrder, setOnSelectExportOrder] = useState(false);
  const outSideRefSelectExportOrder = useRef(null);

  const [loadingEmail, setLoadingEmail] = useState(false);
  const [visibleEmail, setVisibleEmail] = useState(false);
  const outSideRefEmail = useRef(null);

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const orderDB = await api.get<OrderProps>(
          `/orderSankhya/show/${match.params.id}`
        );

        if (!orderDB) return history.push("/404");

        setContentPage(orderDB.data);
        setLoading(false);
      } catch (error) {
        history.push("/404");
      }
    })();
  }, [location, match, history]);

  async function hadleReportCreate() {
    setLoadingReport(true);
    try {
      const response = await api.post(
        `/report/orderSankhya/product/${contentPage.id}`
      );

      generateXlsx({
        filename: "EAN e NCM",
        data: response.data,
      });
      setLoadingReport(false);
    } catch (error) {
      setLoadingReport(false);
    }
  }

  async function printOrder(orderCod: number) {
    try {
      const response = await api.post(`/orderSankhya/print/${orderCod}`);

      var popup = window.open("", "popup", "width=650, height=700");
      if (popup) popup.document.write(response.data);
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function sendOrderMail(
    opt: number,
    idOrder: number,
    to: IMailTo,
    cp?: IMailTo
  ) {
    addToast(`Encaminhando pedido...`, {
      appearance: "info",
      autoDismiss: true,
    });

    setVisibleEmail(false);

    switch (opt) {
      case 1:
        try {
          setLoadingEmail(true);

          await api.post(`/orderSankhya/mail/${idOrder}`, {
            to: {
              email: to.email,
              name: to.name,
            },
          });

          addToast(`Pedido foi enviado com sucesso para e-mail ${to.email}.`, {
            appearance: "success",
            autoDismiss: true,
          });
          setLoadingEmail(false);
        } catch (error) {
          addToast(
            "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          setLoadingEmail(false);
        }

        break;
      case 2:
        try {
          setLoadingEmail(true);
          await api.post(`/orderSankhya/mail/${idOrder}`, {
            to: {
              email: to.email,
              name: to.name,
            },
            cp: cp
              ? {
                  email: cp.email,
                  name: cp.name,
                }
              : undefined,
          });

          addToast(
            `Pedido foi enviado com sucesso para e-mail ${
              to.email
            } com copia para e-mail ${cp ? cp.email : undefined}`,
            {
              appearance: "success",
              autoDismiss: true,
            }
          );
          setLoadingEmail(false);
        } catch (error) {
          addToast(
            "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          setLoadingEmail(false);
        }
        break;

      default:
        break;
    }
  }

  async function exportProducts({ noImage = false }: { noImage?: boolean }) {
    addToast(`Exportando dados.`, {
      appearance: "info",
      autoDismiss: true,
    });

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.getRow(1).height = 25;
    sheet.getRow(1).font = {
      name: "Roboto",
      family: 4,
      size: 16,
      bold: true,
    };

    const columns = [
      {
        header: "Foto",
        key: "image",
        width: 20,
      },
      {
        header: "Cód. Pedido",
        key: "orderCod",
        width: 25,

        alignment: {
          horizontal: "center",
          vertical: "middle",
        },
      },
      {
        header: "Cód. Desdobro",
        key: "currentOrderCod",
        width: 25,

        alignment: {
          horizontal: "center",
          vertical: "middle",
        },
      },

      {
        header: "Cliente",
        key: "client",
        width: 20,
      },
      {
        header: "Representante",
        key: "seller",
        width: 20,
      },
      {
        header: "Tipo",
        key: "type",
        width: 20,
      },
      {
        header: "Situação",
        key: "status",
        width: 20,
      },
      {
        header: "Cód. Produto",
        key: "productCod",
        width: 25,

        alignment: {
          horizontal: "center",
          vertical: "middle",
        },
      },

      {
        header: "Referência",
        key: "reference",
        width: 20,
      },
      {
        header: "Descrição",
        key: "description",
        width: 30,
      },
      {
        header: "Cor 1",
        key: "color1",
        width: 20,
      },
      {
        header: "Cor 2",
        key: "color2",
        width: 20,
      },
      {
        header: "Marca",
        key: "brand",
        width: 10,
      },
      {
        header: "Origem",
        key: "origin",
        width: 10,
      },
      {
        header: "Grade/Tamanho",
        key: "grid",
        width: 26,
      },
      {
        header: "Preço unitário",
        key: "value",
        width: 26,
        numFmt: "numFmt = '$#,##0.00;[Red]-$#,##0.00';",
      },
      {
        header: "Desconto",
        key: "discount",
        width: 26,
        numFmt: "numFmt = '$#,##0.00;[Red]-$#,##0.00';",
      },
      {
        header: "Subtotal",
        key: "total",
        width: 26,
        numFmt: "numFmt = '$#,##0.00;[Red]-$#,##0.00';",
      },
    ];

    //@ts-ignore
    sheet.columns = noImage
      ? columns.filter((f) => f.key !== "image")
      : columns;

    const getImages: { reference: string; imageBase64: string }[] = [];

    if (!noImage) {
      const promiseAllImages = Promise.all(
        groupByObj(contentPage.items, (p) => p.product.reference).map(
          async (productGroup) => {
            const reference = productGroup.value as string;

            const getImageBase64 = await getImageByUrl(
              `https://alpar2.sfo3.digitaloceanspaces.com/Produtos/${
                reference + "_01"
              }_smaller`
            );

            getImages.push({
              reference: reference,
              imageBase64: getImageBase64,
            });
          }
        )
      );
      await promiseAllImages;
    }

    const promise = Promise.all(
      contentPage.items.map(async (item, index) => {
        sheet.addRow({
          orderCod: contentPage.cod ?? "-",
          currentOrderCod: item.currentOrderCod ?? "-",
          type: `${
            contentPage.species === 9 ? "VF" : "PE"
          } ${normalizedDateTypeAndBillingDate(contentPage.billingDate)}`,
          client: contentPage.client?.company_name
            ? `${contentPage.client?.company_name} (${contentPage.client?.cod})`
            : "-",
          seller: contentPage.seller?.abbreviation
            ? `${contentPage.seller?.abbreviation} (${contentPage.seller.cod})`
            : "-",
          status: item.status ?? "-",
          productCod: item.product.cod ?? "-",
          reference: item.product.reference ?? "-",
          description: item.product.description ?? "-",
          color1: item.product.primaryColor ?? "-",
          color2: item.product.secondaryColor ?? "-",
          origin: item.product.origin ?? "-",
          brand: contentPage.brand?.name ?? "-",
          grid: item.product.grid ?? "-",
          value: item.value ?? 0,
          total: item.totalValue ?? 0,
          discount: item.discount ?? 0,
        });

        if (!noImage) {
          const imageId = workbook.addImage({
            base64:
              getImages.find((f) => f.reference === item.product.reference)
                ?.imageBase64 ?? "",
            extension: "jpeg",
          });

          sheet.addImage(imageId, {
            tl: { col: 0, row: index + 1 },
            ext: { width: 110, height: 85 },
            editAs: "oneCells",
          });
        }
      })
    );

    sheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        //@ts-ignore
        let columnKey = cell["_column"]["_key"];

        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };

        if (rowNumber !== 1) {
          row.height = 90;
        }

        if (rowNumber === 1) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "4473c5" },
          };

          cell.font.color = { argb: "ffffff" };

          if (["total"].includes(columnKey)) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "22a883" },
            };
          }
        }
      });
    });

    await promise;

    const currentDataFormat = moment().format("DD-MM-YY");

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `Pedido ${contentPage.cod} (${currentDataFormat}).xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }

  async function downloadNfe(documentNumber: string, invoiceDate?: Date) {
    try {
      addToast(`Gerando NF-E...`, {
        appearance: "info",
        autoDismiss: true,
      });

      const getLinkInvoice = await api.post(
        `/orderSankhya/invoice/${documentNumber}`,
        {
          invoiceDate: invoiceDate ? invoiceDate : contentPage?.billingDate,
          initialsOrder: contentPage?.initialsOrder,
        }
      );
      downloadURI(getLinkInvoice.data.uri, `Nota Fiscal ${documentNumber}.zip`);
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  function downloadURI(uri: string, name: string) {
    axios({
      url: uri,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  useOutsideAlerter(outSideRefReport, () => {
    if (visibleReport) setVisibleReport(false);
  });
  useOutsideAlerter(outSideRefEmail, () => {
    if (visibleEmail) setVisibleEmail(false);
  });
  useOutsideAlerter(outSideRefSelectExportOrder, () => {
    if (onSelectExportOrder) setOnSelectExportOrder(false);
  });

  return (
    <Container>
      {contentPage !== null && !loading ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="arrowBack"
            >
              <FaArrowLeft size={18} />
            </button>
            <h2>Detalhes do pedido</h2>

            <ReportDownload
              className="reportDownload"
              onClick={() => setVisibleReport(!visibleReport)}
            >
              {loadingReport ? (
                <Loading
                  borderSize={5}
                  size={20}
                  colorLoading="#259457"
                  borderColor="rgba(32, 114, 69, 0.5)"
                />
              ) : (
                <div className="icon-execel">
                  <RiFileExcel2Line color="#207245" size={24} />
                  <div
                    className={visibleReport ? "row-excel-active" : "row-excel"}
                  >
                    <MdArrowDownward color="#207245" size={16} />
                  </div>

                  <span className="text-execel">Exportação de EAN e NCM</span>
                </div>
              )}

              {visibleReport && (
                <ul ref={outSideRefReport} className="optionReport">
                  <li onClick={() => hadleReportCreate()}>
                    <div className="icon-execel">
                      <RiFileExcel2Line color="#207245" size={18} />
                    </div>
                    <span>Gerar Relatório</span>
                  </li>
                </ul>
              )}
            </ReportDownload>

            <div className="infoUpdate">
              <span>
                Dados atualizados em{"  "}
                {contentPage && contentPage.updated_at
                  ? moment(contentPage.updated_at).format(
                      `DD/MM/YYYY - hh:mm A `
                    )
                  : "-"}
              </span>
            </div>
          </header>

          <InfoStatus>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>SITUAÇÃO</h5>
                </div>
                <p>{contentPage?.status?.description}</p>
              </div>
            </div>

            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>VALOR DA TOTAL</h5>
                </div>
                <p>
                  {Number(contentPage.merchandiseValue).toLocaleString(
                    "pt-br",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  )}
                </p>
              </div>
            </div>

            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>DATA PEDIDO</h5>
                </div>
                <p>{moment(contentPage.deliveryDate).format("DD/MM/YYYY")}</p>
              </div>
            </div>
          </InfoStatus>

          <InfoDevolution>
            <div className="headerDevoltion">
              <h2>Informações</h2>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="containerTracking">
                  <button
                    type="button"
                    onClick={() => printOrder(contentPage.id)}
                  >
                    <span>
                      <VscFileSymlinkFile size={20} />
                    </span>
                    <p>Espelho do pedido</p>
                  </button>
                </div>

                <ReportDownload className="containerTracking">
                  {!loadingEmail ? (
                    <button
                      type="button"
                      onClick={() => {
                        !client
                          ? setVisibleEmail(true)
                          : contentPage.client
                          ? sendOrderMail(1, contentPage.id, {
                              name: contentPage.client.company_name,
                              email: contentPage.client.email,
                            })
                          : addToast(
                              "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
                              {
                                appearance: "error",
                                autoDismiss: true,
                              }
                            );
                      }}
                    >
                      <span>
                        <HiOutlineMail size={20} />
                      </span>
                      <p>Enviar e-mail</p>
                    </button>
                  ) : (
                    <button
                      style={{ width: 100 }}
                      type="button"
                      onClick={() => {}}
                    >
                      <Loading
                        borderSize={5}
                        size={20}
                        colorLoading="#333"
                        borderColor="rgba(200, 200, 200, 0.8)"
                      />
                    </button>
                  )}

                  {visibleEmail && (
                    <ul ref={outSideRefEmail} className="optionReport">
                      <li
                        onClick={() => {
                          contentPage.client
                            ? sendOrderMail(1, contentPage.id, {
                                name: contentPage.client.company_name,
                                email: contentPage.client.email,
                              })
                            : addToast(
                                "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
                                {
                                  appearance: "error",
                                  autoDismiss: true,
                                }
                              );
                        }}
                      >
                        <span>Enviar Cliente</span>
                      </li>
                      <li
                        onClick={() => {
                          contentPage.seller
                            ? sendOrderMail(1, contentPage.id, {
                                name: contentPage.seller.fullName,
                                email: contentPage.seller.email,
                              })
                            : addToast(
                                "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
                                {
                                  appearance: "error",
                                  autoDismiss: true,
                                }
                              );
                        }}
                      >
                        <span>Enviar Representante</span>
                      </li>
                      <li
                        onClick={() => {
                          contentPage.client && contentPage.seller
                            ? sendOrderMail(
                                2,
                                contentPage.id,
                                {
                                  name: contentPage.client.company_name,
                                  email: contentPage.client.email,
                                },
                                {
                                  name: contentPage.seller.fullName,
                                  email: contentPage.seller.email,
                                }
                              )
                            : addToast(
                                "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
                                {
                                  appearance: "error",
                                  autoDismiss: true,
                                }
                              );
                        }}
                      >
                        <span>Enviar Ambos</span>
                      </li>
                    </ul>
                  )}
                </ReportDownload>

                <ReportDownload className="containerTracking">
                  <button
                    type="button"
                    onClick={() => {
                      setOnSelectExportOrder((d) => !d);
                    }}
                  >
                    <span>
                      <RiFileExcel2Line size={20} />
                    </span>
                    <p>Exportar pedido</p>
                  </button>

                  {onSelectExportOrder && (
                    <ul
                      ref={outSideRefSelectExportOrder}
                      className="optionReport"
                    >
                      <li
                        onClick={async () => {
                          await exportProducts({ noImage: false });
                        }}
                      >
                        <span>Com imagens</span>
                      </li>
                      <li
                        onClick={async () => {
                          await exportProducts({ noImage: true });
                        }}
                      >
                        <span>Sem imagens</span>
                      </li>
                    </ul>
                  )}
                </ReportDownload>
              </div>
            </div>

            <div className="bodyDevoltion">
              {!client && (
                <div className="conatinerInfo">
                  <h3>Cliente</h3>
                  <div className="line"></div>

                  <div>
                    <h4>CÓDIGO CLIENTE</h4>
                    <span>{contentPage.client?.cod}</span>

                    <h4>CNPJ</h4>
                    <span>{contentPage.client?.cnpj}</span>

                    <h4>RAZÃO SOCIAL</h4>
                    <span>{contentPage.client?.company_name}</span>

                    <h4>NOME FANTASIA</h4>
                    <span>{contentPage.client?.trade_name}</span>
                    <h4>CIDADE</h4>

                    <span>{contentPage.client?.adresses?.city}</span>

                    <h4>ESTADO</h4>
                    <span>{contentPage.client?.adresses?.uf}</span>
                  </div>
                </div>
              )}

              <div className="conatinerInfo">
                <h3>Pedido</h3>
                <div className="line"></div>

                <div>
                  <>
                    <h4>CÓDIGO DO PEDIDO</h4>
                    <span>
                      {contentPage.initialsOrder}/{contentPage.cod}
                    </span>
                  </>
                  <>
                    <h4>TIPO DO PEDIDO</h4>
                    <span>
                      {contentPage.species === 9
                        ? "Venda Futura"
                        : contentPage.species !== 9
                        ? "Pronta entrega"
                        : "-"}
                    </span>
                  </>

                  <h4>MARCA</h4>
                  <span>{contentPage.brand?.name}</span>

                  {contentPage.brand?.is_not_show_delivery_at ? null : (
                    <>
                      {positionValid.errorValid.includes(
                        contentPage.status?.name ?? ""
                      ) && (
                        <>
                          <h4>DATA DE CANCELAMENTO</h4>
                          <span>
                            {moment(contentPage.billingDate).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </>
                      )}

                      {[
                        ...positionValid.awaitValid,
                        ...positionValid.partialSuccessValid,
                      ].includes(contentPage.status?.name ?? "") && (
                        <>
                          <h4>MÊS DE ENTREGA</h4>
                          <span>
                            {moment(contentPage.billingDate).format("MM/YYYY")}
                          </span>
                        </>
                      )}

                      {[
                        ...positionValid.successValid,
                        ...positionValid.noteValid,
                      ].includes(contentPage.status?.name ?? "") && (
                        <>
                          <h4>DATA DE FATURAMENTO</h4>
                          <span>
                            {moment(contentPage.billingDate).format(
                              contentPage.species === 9
                                ? "MM/YYYY"
                                : "DD/MM/YYYY"
                            )}
                          </span>
                        </>
                      )}
                    </>
                  )}

                  <h4>CONDIÇÃO DE PAGAMENTO</h4>
                  <span>{contentPage.paymentCondition}</span>
                </div>
              </div>

              <div className="conatinerInfo">
                <h3>Representante</h3>
                <div className="line"></div>

                <div>
                  <h4>CÓDIGO REPRESENTANTE </h4>
                  <span>{contentPage.seller?.cod}</span>

                  <h4>ABREVIAÇÃO</h4>
                  <span>{contentPage.seller?.abbreviation}</span>

                  <h4>NOME COMPLETO</h4>
                  <span>{contentPage.seller?.fullName}</span>
                </div>
              </div>
            </div>
          </InfoDevolution>

          <ContainerListProducts>
            <div className="headerTable">
              <h3>PRODUTOS DO PEDIDO </h3>
            </div>

            {contentPage?.groupItems?.map((groupItem) => (
              <div key={groupItem.status} style={{ marginBottom: "1rem" }}>
                <Accordion
                  isOpenDefault={false}
                  title={`${groupItem.status} (${groupItem.totalItens} itens)`}
                  headerStyles={{
                    color:
                      groupItem.status === "Cancelado"
                        ? "#d21e26"
                        : groupItem.status === "Faturado"
                        ? "#21b543"
                        : groupItem.status === "Recusado" ||
                          groupItem.status === "Em análise"
                        ? "#9700c2"
                        : "#417DAB",
                  }}
                >
                  {groupItem.groups.map((groupOrder) => {
                    return (
                      <div
                        key={groupOrder.currentOrderCod}
                        style={{ padding: "1rem" }}
                      >
                        <Accordion
                          isOpenDefault={false}
                          title={
                            groupOrder.documentNumber
                              ? `Nº NOTA FISCAL: ${groupOrder.documentNumber}: desdobro (${groupOrder.currentOrderCod})`
                              : `Desdobro do pedido (${contentPage.cod}): (${groupOrder.currentOrderCod})`
                          }
                          size="sm"
                          headerStyles={{
                            background: "#f0f0f0",
                            borderRadius: 6,
                          }}
                          bodyStyles={{
                            background: "#f0f0f0",
                            borderRadius: "0 0 6px 6px",
                          }}
                          tags={
                            groupOrder?.suspended
                              ? [
                                  {
                                    title: "TÍTULOS SUSTADO",
                                    color: "#fff",
                                    bg: "#f87501",
                                  },
                                ]
                              : undefined
                          }
                        >
                          <div style={{ padding: "1rem" }}>
                            {!!groupOrder?.documentNumber && (
                              <>
                                <button
                                  style={{
                                    borderRadius: 6,
                                    padding: 6,
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    background: "#333",
                                    color: "white",
                                    marginBottom: "1rem",
                                  }}
                                  onClick={() =>
                                    downloadNfe(
                                      String(groupOrder.documentNumber),
                                      groupOrder.invoiceDate
                                    )
                                  }
                                >
                                  <span>
                                    <AiOutlineFileSearch size={"1.5rem"} />
                                  </span>
                                  <p>Gerar NF-E</p>
                                </button>

                                {groupOrder?.tracking && (
                                  <Tracking
                                    data={{
                                      ...groupOrder?.tracking,
                                      orderDate: contentPage.deliveryDate,
                                    }}
                                  />
                                )}
                              </>
                            )}

                            {!client && (
                              <>
                                {!!groupOrder.pointedCod &&
                                  groupItem.status === "Cancelado" && (
                                    <div style={{ marginBottom: "1rem" }}>
                                      <p
                                        style={{
                                          fontSize: "1rem",
                                          color: "#333",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Motivo do cancelamento
                                      </p>
                                      <span
                                        style={{
                                          fontSize: "0.875rem",
                                          color: "#555",
                                        }}
                                      >{`${groupOrder.pointedCod} ${groupOrder.pointedDesc}`}</span>
                                    </div>
                                  )}

                                {!!groupOrder.refuseCod &&
                                  groupItem.status === "Recusado" && (
                                    <div style={{ marginBottom: "1rem" }}>
                                      <p
                                        style={{
                                          fontSize: "1rem",
                                          color: "#333",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Motivo da recusa
                                      </p>
                                      <span
                                        style={{
                                          fontSize: "0.875rem",
                                          color: "#555",
                                        }}
                                      >{`${groupOrder.refuseCod} ${groupOrder.refuseDesc}`}</span>
                                    </div>
                                  )}

                                {!!groupOrder?.highlighter &&
                                  groupOrder?.highlighter.length > 0 && (
                                    <div style={{ marginBottom: "1rem" }}>
                                      <p
                                        style={{
                                          fontSize: "1rem",
                                          color: "#333",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Destacador
                                      </p>
                                      <span
                                        style={{
                                          fontSize: "0.875rem",
                                          color: "#555",
                                        }}
                                      >
                                        {groupOrder?.highlighter
                                          .map((desc) => desc.description)
                                          .join(",")}
                                      </span>
                                    </div>
                                  )}
                              </>
                            )}

                            {groupOrder.items.map((item) => (
                              <Product
                                key={item.id}
                                data={{
                                  id: item.id,
                                  description: item.product.description,
                                  grid: item.product.grid,
                                  quantity: String(item.quantity),
                                  reference: item.product.reference,
                                  status: item.status,
                                  origin: item.product.origin,

                                  totalValue: Number(
                                    item.totalValue
                                  ).toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                  }),
                                  value: Number(item.value).toLocaleString(
                                    "pt-br",
                                    {
                                      style: "currency",
                                      currency: "BRL",
                                    }
                                  ),
                                  discount: Number(
                                    item.discount
                                  ).toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                  }),
                                  uri: `https://alpar2.sfo3.digitaloceanspaces.com/Produtos/${item.product.reference}_01`,
                                }}
                              />
                            ))}
                          </div>
                        </Accordion>
                      </div>
                    );
                  })}
                </Accordion>
              </div>
            ))}

            {/* <TableContainerProducts>
              {contentPage.items &&
                contentPage.items.map((item) => (
                  <Product
                    key={item.id}
                    data={{
                      id: item.id,
                      description: item.product.description,
                      grid: item.product.grid,
                      quantity: String(item.quantity),
                      reference: item.product.reference,
                      status: item.status,

                      totalValue: Number(item.totalValue).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      ),
                      value: Number(item.value).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      }),
                      uri: `https://alpar2.sfo3.digitaloceanspaces.com/Produtos/${item.product.reference}_01`,
                    }}
                  />
                ))}
            </TableContainerProducts> */}
          </ContainerListProducts>
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default Order;
