import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../components/loadings/Loading";
import api from "../../service/api";
import * as authClient from "../../service/authClient";
import * as authSeller from "../../service/authSeller";
import * as clientStorage from "../../service/localStorage/client";
import * as sellerStorage from "../../service/localStorage/seller";
import logout from "../../utils/logout";
import { Container } from "./style";

interface IHeaderProps {
  match: {
    params: {
      token: string;
    };
  };
}

interface IToken {
  access_token: string;
  iat: number;
  exp: number;
}

interface IClient {
  id: string;
  cod: number;
  cnpj: string;
  company_name: string;
  trade_name: string;
  email: string;
}

interface IResponseAccessClient {
  client: IClient;
  token: IToken;
}
interface IResponseAccessSeller {
  seller: sellerStorage.SellerProps;
  token: IToken;
}

interface IResponseVerify {
  access: any;
  functionality: "boletos";
  entity: "cliente" | "representante";
}

const SSO: React.FC<IHeaderProps> = ({ match }) => {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        if (match.params.token) {
          const responseVerify = await api.post<IResponseVerify>(
            "/sso/verify",
            {
              token: match.params.token,
            }
          );

          const { entity, functionality, access } = responseVerify.data;

          if (entity === "cliente") {
            logout();
            const accessClient = access as IResponseAccessClient;

            clientStorage.setUser({
              id: accessClient.client.id,
              cod: accessClient.client.cod,
              cnpj: accessClient.client.cnpj,
              company_name: accessClient.client.company_name,
              trade_name: accessClient.client.trade_name,
              email: accessClient.client.email,
            });

            const { access_token, exp, iat } = accessClient.token;
            authClient.setToken({
              access_token,
              exp,
              iat,
            });

            history.push(`/${functionality}`);
            window.location.reload();
          }

          if (entity === "representante") {
            logout();
            const accessSeller = access as IResponseAccessSeller;

            sellerStorage.setUser({
              id: accessSeller.seller.id,
              email: accessSeller.seller.email,
              cod: accessSeller.seller.cod,
              abbreviation: accessSeller.seller.abbreviation,
              fullName: accessSeller.seller.fullName,
              is_manager: accessSeller.seller.is_manager,
              is_supervisor: accessSeller.seller.is_supervisor,
              brands: accessSeller.seller.brands,
              isSankhya: accessSeller.seller.isSankhya,
              sellerTeamOn: accessSeller.seller.sellerTeamOn,
              securityCode: accessSeller.seller.securityCode,
              isSecurityCode: accessSeller.seller.isSecurityCode,
            });

            const { access_token, exp, iat } = accessSeller.token;
            authSeller.setToken({
              access_token,
              exp,
              iat,
            });

            history.push(`/representante/${functionality}`);
            window.location.reload();
          }

          history.push("/");
        } else {
          history.push("/");
        }
      } catch (error) {
        history.push("/");
      }
    })();
    // eslint-disable-next-line
  }, [match.params.token]);

  return (
    <Container>
      <Loading
        colorLoading="#333"
        size={40}
        borderColor={"#888"}
        borderSize={5}
      />
    </Container>
  );
};

export default SSO;
