import React, { ReactNode, useState } from "react";

import { IoIosArrowDown } from "react-icons/io";
import { Body, Container, Header, Tag } from "./styles";

interface AccordionProps {
  title: ReactNode;

  children: ReactNode;

  isOpenDefault?: boolean;

  size?: "lg" | "sm";
  headerStyles?: React.CSSProperties;
  bodyStyles?: React.CSSProperties;

  tags?: { title: string; bg: string; color: string }[];
}

export function Accordion({
  title,
  size = "lg",
  isOpenDefault = true,
  children,
  headerStyles,
  bodyStyles,
  tags = [],
}: AccordionProps) {
  const [isOpen, setIsOpen] = useState(isOpenDefault);

  return (
    <Container>
      <Header
        size={size}
        isOpen={isOpen}
        style={headerStyles}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>
          <span className="accordion-title">{title}</span>
          {tags.map((tag) => (
            <Tag
              key={tag.title}
              style={{ background: tag.bg, color: tag.color }}
            >
              {tag.title}
            </Tag>
          ))}
        </div>

        <IoIosArrowDown
          className="accordion-arrow"
          color="#333"
          fontSize="1.5rem"
        />
      </Header>

      <Body isOpen={isOpen} style={bodyStyles}>
        {children}
      </Body>
    </Container>
  );
}
