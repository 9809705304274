import { useEffect, useState } from "react";
import * as localCookies from "../service/localStorage/sellerAccessCode";

export default function useIsSellerAccessCode() {
  const [isSellerAccessCode, setIsSellerAccessCode] = useState(false);

  useEffect(() => {
    const cookiesConsentData = localCookies.get();

    if (cookiesConsentData && cookiesConsentData.date) {
      const dateBeforeDays = new Date();
      dateBeforeDays.setDate(dateBeforeDays.getDate() - 1);

      if (new Date(cookiesConsentData.date) < dateBeforeDays) {
        setIsSellerAccessCode(false);
      } else {
        setIsSellerAccessCode(true);
      }
    }

    // eslint-disable-next-line
  }, [localCookies.get()]);

  useEffect(() => {
    console.log(isSellerAccessCode);
    if (isSellerAccessCode) {
      localCookies.set({ date: new Date() });
    }
  }, [isSellerAccessCode]);

  return { isSellerAccessCode, setIsSellerAccessCode };
}
