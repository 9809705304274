import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  border-radius: 6px;
  transition: all 0.2s;
`;

type HEADER_PARAMS = {
  isOpen: boolean;
  size: "lg" | "sm";
};

export const Header = styled.div<HEADER_PARAMS>`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  .accordion-arrow {
    transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
    transition: all 0.4s;
  }

  .accordion-title {
    font-size: ${({ size }) => (size === "lg" ? 1.3 : 0.875)}rem;
    font-weight: bold;
  }
`;

type BODY_PARAMS = {
  isOpen: boolean;
};

export const Body = styled.div<BODY_PARAMS>`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
`;

export const Tag = styled.span`
  border-radius: 6px;
  padding: 6px;
  margin-left: 0.5rem;
  font-weight: bold;
`;
