import { useFormik } from "formik";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";

import { Input } from "../../../../../components/Form/Input";
import Loading from "../../../../../components/Loading";
import { Form } from "../../../../create/styles";
import { ButtunSubmit } from "../../../styles";
import { Container, ContainerForm } from "./styles";

import useIsSellerAccessCode from "../../../../../hook/useIsSellerAccessCode";
import * as sellerLocalStorage from "../../../../../service/localStorage/seller";

interface ModalValidServiceInvoiceProps {
  onRequestUpdate: (isValid: boolean) => void;
  onRequestClose: () => void;
}

export const ModalValidServiceInvoice: React.FC<
  ModalValidServiceInvoiceProps
> = ({ onRequestUpdate, onRequestClose }) => {
  const [loading, setLoading] = useState(false);

  const [sellerStorage] = useState<sellerLocalStorage.SellerProps>(() => {
    return sellerLocalStorage.getUser() as sellerLocalStorage.SellerProps;
  });
  const { setIsSellerAccessCode } = useIsSellerAccessCode();

  const { addToast } = useToasts();

  const formik = useFormik({
    initialValues: {
      securityCode: "",
    },
    validationSchema: Yup.object({
      securityCode: Yup.string().required("É obrigatório"),
    }),

    onSubmit: async (data) => await handleSubmitUpdate(data),
  });

  async function handleSubmitUpdate({
    securityCode,
  }: {
    securityCode: string;
  }) {
    try {
      setLoading(true);

      if (sellerStorage.securityCode === securityCode) {
        addToast("Código validado com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        onRequestUpdate(true);
        setIsSellerAccessCode(true);
        onRequestClose();
      } else {
        addToast("Código incorreto.", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      setLoading(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <Form
        onSubmit={
          loading
            ? (e) => {
                e.preventDefault();
              }
            : formik.handleSubmit
        }
      >
        <ContainerForm>
          <div className="containerForm">
            <Input
              label="Código segurança"
              {...formik.getFieldProps("securityCode")}
              error={
                formik.touched.securityCode && formik.errors.securityCode
                  ? formik.errors.securityCode
                  : undefined
              }
            />
          </div>

          <div className="containerFormButton">
            <ButtunSubmit style={{ margin: 0 }} type="submit">
              {loading ? (
                <div>
                  <Loading borderSize={2} size={20} />
                </div>
              ) : (
                "Validar"
              )}
            </ButtunSubmit>
          </div>
        </ContainerForm>
      </Form>
    </Container>
  );
};
