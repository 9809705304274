import { useQuery, UseQueryResult } from "react-query";
import { OptionsFilter } from "../../@types";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type ServiceInvoice = {
  id: string;
  created_at: Date;
  created_atNormalized: string;
  finished_atNormalized: string;
  finished_at?: Date;
  period_start?: Date;
  period_end?: Date;
  periodNormalized?: string;
  reason?: string;
  type: "comissao" | "outras";
  typeNormalized: string;

  salePrice?: number;
  commissionValue?: number;
  returnValue?: number;
  commissionRefunded?: number;
  saleBalance?: number;
  IRRFBase?: number;
  comissionBalance?: number;
  IRFValue?: number;
  liquidComission?: number;
  releasesBalance?: number;
  commissionPercetage?: number;
  IrfPercetage?: number;

  salePriceNormalized?: string;
  commissionValueNormalized?: string;
  returnValueNormalized?: string;
  commissionRefundedNormalized?: string;
  saleBalanceNormalized?: string;
  IRRFBaseNormalized?: string;
  comissionBalanceNormalized?: string;
  IRFValueNormalized?: string;
  liquidComissionNormalized?: string;
  releasesBalanceNormalized?: string;
  commissionPercetageNormalized?: string;
  IrfPercetageNormalized?: string;

  releases: Release[];

  logs: ServiceInvoiceLog[];

  invoices: {
    total: number;
    totalFormat: string;
    type: "devolucao" | "duplicata" | "incobraveis";
    typeNormalized: string;
    clients: {
      total: number;
      totalFormat: string;
      client: {
        cod: number;
        company_name: string;
        trade_name: string;
        cnpj: string;
      };
      invoice: Invoice[];
    }[];
  }[];
  file_pdf?: {
    id: number;
    name: string;
    mimetype: string;
    size: number;
    key: string;
  };
  file_xml?: {
    id: number;
    name: string;
    mimetype: string;
    size: number;
    key: string;
  };
  status: {
    cod: number;
    name: string;
    color: string;
  };
  seller: {
    cod: number;
    abbreviation: string;
    fullName: string;
  };
  operador?: {
    id: number;
    name: string;
    email: string;
    lastname: string;
  };
};

export type Release = {
  id: number;
  type: "credito" | "debito";
  description: string;
  value: number;
  valueNormalized: string;
};

export type Invoice = {
  id: number;
  sigla: string;
  sequence?: number;
  documentNumber: string;
  invoiceNumber: string;
  priceValue: number;
  commissionValue: number;
  priceValueNormalized: string;
  commissionValueNormalized: string;
  commissionPercetage: number;
  emissionDate?: Date;
  dueDate?: Date;
  receiptDate?: Date;
  emissionDateNormalized?: string;
  dueDateNormalized?: string;

  client_pj: {
    cod: number;
    company_name: string;
    trade_name: string;
    cnpj: string;
  };
};

export type ServiceInvoiceLog = {
  id: number;
  obs: string | null;
  created_at: Date;
  seller?: {
    cod: number;
    abbreviation: string;
  };
  status?: {
    name: string;
    cod: number;
  };
  user?: {
    id: number;
    name: string;
    lastname: string;
  };
};

export type ServiceInvoiceFilters = {
  cod?: string;
  type?: string;
  status?: string;
  seller?: string;
  operador?: string;
};

type GetServiceInvoicesResponse = {
  invoices: ServiceInvoice[];
  filters: ServiceInvoiceFilterOptions;
  totalCount: number;
};

interface ServiceInvoiceApiResponse extends ResponsePanelApiDefault {
  contents: ServiceInvoice[];
  filters: ServiceInvoiceFilterOptions;
}

export interface ServiceInvoiceFilterOptions {
  operador: OptionsFilter[];
  sellers: OptionsFilter[];
  status: OptionsFilter[];
}

export async function getServiceInvoice(
  page: number,
  filter?: ServiceInvoiceFilters,
  registersPerPag?: number
): Promise<GetServiceInvoicesResponse> {
  const { data } = await api.get<ServiceInvoiceApiResponse>(
    "/service-invoice",
    {
      params: {
        pageRequest: page - 1,
        limitRequest: registersPerPag,
        ...filter,
      },
    }
  );

  const normalized = data.contents.map((invoice) => ({
    ...invoice,
    typeNormalized: invoice.type === "comissao" ? "Notas de serviço" : "Outras",
    created_atNormalized: new Date(invoice.created_at).toLocaleDateString(
      "pt-BR",
      {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }
    ),
    finished_atNormalized: invoice.finished_at
      ? new Date(invoice.finished_at).toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      : "-",
    periodNormalized:
      invoice.period_start && invoice.period_end
        ? `${new Date(invoice.period_start).toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })} a ${new Date(invoice.period_end).toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}`
        : "-",
  }));

  return {
    totalCount: data.total,
    invoices: normalized,
    filters: data.filters,
  };
}

export function useServiceInvoice(
  page: number,
  filter?: ServiceInvoiceFilters,
  registersPerPag?: number
): UseQueryResult<GetServiceInvoicesResponse> {
  return useQuery(
    ["service-invoices", page, filter, registersPerPag],
    () => getServiceInvoice(page, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getOneServiceInvoice(
  id?: number
): Promise<ServiceInvoice | undefined> {
  if (!id && id !== 0) return undefined;

  const { data: invoice } = await api.get<ServiceInvoice>(
    `/service-invoice/show/${id}`
  );

  return {
    ...invoice,

    typeNormalized: invoice.type === "comissao" ? "Notas de serviço" : "Outras",
    created_atNormalized: new Date(invoice.created_at).toLocaleDateString(
      "pt-BR",
      {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        minute: "2-digit",
        hour: "2-digit",
      }
    ),
    finished_atNormalized: invoice.finished_at
      ? new Date(invoice.finished_at).toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      : "-",
    periodNormalized:
      invoice.period_start && invoice.period_end
        ? `${new Date(invoice.period_start).toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })} a ${new Date(invoice.period_end).toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}`
        : "-",
    salePriceNormalized: invoice.salePrice
      ? Number(invoice.salePrice).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      : "-",
    commissionValueNormalized: invoice.commissionValue
      ? Number(invoice.commissionValue).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      : "-",
    returnValueNormalized: invoice.returnValue
      ? Number(invoice.returnValue).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      : "-",
    commissionRefundedNormalized: invoice.commissionRefunded
      ? Number(invoice.commissionRefunded).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      : "-",
    saleBalanceNormalized: invoice.saleBalance
      ? Number(invoice.saleBalance).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      : "-",
    IRRFBaseNormalized: invoice.IRRFBase
      ? Number(invoice.IRRFBase).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      : "-",
    comissionBalanceNormalized: invoice.comissionBalance
      ? Number(invoice.comissionBalance).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      : "-",
    IRFValueNormalized: invoice.IRFValue
      ? Number(invoice.IRFValue).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      : "-",
    liquidComissionNormalized: invoice.liquidComission
      ? Number(invoice.liquidComission).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      : "-",
    releasesBalanceNormalized: invoice.releasesBalance
      ? Number(invoice.releasesBalance).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      : "-",
    commissionPercetageNormalized: invoice.commissionPercetage
      ? `${invoice.commissionPercetage}%`
      : "-",
    IrfPercetageNormalized: invoice.IrfPercetage
      ? `${invoice.IrfPercetage}%`
      : "-",
    releases: invoice.releases.map((item) => ({
      ...item,
      valueNormalized: Number(
        item.value * (item.type === "debito" ? -1 : 1)
      ).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }),
    })),
    invoices:
      invoice.invoices.map((item) => ({
        ...item,
        typeNormalized:
          item.type === "devolucao"
            ? "Devolução"
            : item.type === "duplicata"
            ? "Duplicata"
            : "Incobráveis",
        totalFormat: Number(item.total ?? 0).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),

        clients: item.clients.map((client) => ({
          ...client,
          totalFormat: Number(client.total ?? 0).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
          invoice: client.invoice.map((invoice) => ({
            ...invoice,
            emissionDateNormalized: invoice.emissionDate
              ? new Date(invoice.emissionDate).toLocaleDateString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              : "-",
            dueDateNormalized: invoice.dueDate
              ? new Date(invoice.dueDate).toLocaleDateString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              : "-",
            priceValueNormalized: Number(
              invoice.priceValue ?? 0
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
            commissionValueNormalized: Number(
              invoice.commissionValue ?? 0
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
          })),
        })),
      })) ?? [],
  };
}

export function useOneServiceInvoice(
  id?: number
): UseQueryResult<ServiceInvoice> | undefined {
  return useQuery(["service-invoices", id], () => getOneServiceInvoice(id), {
    staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
