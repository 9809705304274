import React, { useEffect, useState } from "react";

import bcrypt from "bcryptjs";
import { Field, Formik, useFormik } from "formik";
import * as Yup from "yup";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import api from "../../service/api";
import * as clientStorage from "../../service/localStorage/client";
import * as providerStorage from "../../service/localStorage/provider";
import * as sellerLocalStorage from "../../service/localStorage/seller";

import { FiAlertCircle } from "react-icons/fi";
import { Input } from "../../components/Form/Input";
import { Select } from "../../components/Form/Select";
import { ButtunSubmit, Container, ContainerForm, Error, Form } from "./styles";

interface Client {
  id?: string;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;

  acess_client: {
    password: string;
  };
}

interface Seller {
  cod: Number;
  id: Number;
  abbreviation: string;
  fullName: string;
  email: string;
  phone: string;

  acess_seller: {
    password: string;
  };
}

interface Provider {
  id: Number;

  acess_provider: {
    email: string;
    password: string;
  };
}

interface IPassword {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

interface HeaderProps {
  admin: boolean;
  client: boolean;
  seller: boolean;
  provider: boolean;
}

const Security: React.FC<HeaderProps> = ({
  admin,
  client,
  seller,
  provider,
}) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [sellerStorage] = useState<sellerLocalStorage.SellerProps>(() => {
    return sellerLocalStorage.getUser() as sellerLocalStorage.SellerProps;
  });

  const formikCode = useFormik({
    initialValues: {
      isSecurityCode: "",
      securityCode: "",
      confirmSecurityCode: "",
    },
    validationSchema: Yup.object({
      isSecurityCode: Yup.string().required("É obrigatório."),
      securityCode: Yup.string()
        .min(3, "No mínimo 3 caracteres.")
        .test("required", "É obrigatório.", function (value) {
          let error = true;
          if (formikCode.values.isSecurityCode === "1" && value === undefined)
            error = false;

          return error;
        }),
      confirmSecurityCode: Yup.string()
        .oneOf([Yup.ref("securityCode")], "Os código precisam ser iguais.")
        .test("required", "É obrigatório.", function (value) {
          let error = true;
          if (formikCode.values.isSecurityCode === "1" && value === undefined)
            error = false;

          return error;
        }),
    }),
    onSubmit: (data) => handleSubmitCode(data),
  });

  async function handleSubmit(pass: IPassword) {
    if (admin) {
      const verifyPass = await api.post("/user/verifyPass", {
        oldPassword: pass.oldPassword,
      });

      if (verifyPass.data)
        return addToast("Senha atual não corresponde", {
          appearance: "warning",
          autoDismiss: true,
        });

      try {
        await api.put(`/user/profile/`, { password: pass.password });
        addToast("Alterção de senha realizada com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push("/admin/home");
      } catch (error) {
        console.log(error);
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    }
    if (client) {
      const clientData = (await clientStorage.getUser()) as Client;
      const clientDB = await api.get<Client>(`/clientconfig/${clientData.id}`);

      const { password } = clientDB.data.acess_client;

      if (!(await bcrypt.compare(pass.oldPassword, password)))
        return addToast("Senha atual não corresponde", {
          appearance: "warning",
          autoDismiss: true,
        });

      try {
        await api.put(`/clientconfig/${clientData.id}`, {
          password: pass.password,
        });
        addToast("Alterção de senha realizada com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push("/home");
      } catch (error) {
        console.log(error);
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    }
    if (seller) {
      const sellerDB = await api.get<Seller>(
        `/sellerconfig/${sellerStorage.id}`
      );

      const { password } = sellerDB.data.acess_seller;

      if (!(await bcrypt.compare(pass.oldPassword, password)))
        return addToast("Senha atual não corresponde", {
          appearance: "warning",
          autoDismiss: true,
        });

      try {
        await api.put(`/sellerconfig/${sellerStorage.id}`, {
          password: pass.password,
        });
        addToast("Alteração de senha realizada com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push("/representante");
      } catch (error) {
        console.log(error);
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    }
    if (provider) {
      const providerData = (await providerStorage.getUser()) as Provider;
      const providerDB = await api.get<Provider>(
        `/provider/config/${providerData.id}`
      );

      const { password } = providerDB.data.acess_provider;

      if (!(await bcrypt.compare(pass.oldPassword, password)))
        return addToast("Senha atual não corresponde", {
          appearance: "warning",
          autoDismiss: true,
        });

      try {
        await api.put(`/provider/config/${providerData.id}`, {
          password: pass.password,
        });
        addToast("Alterção de senha realizada com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push("/fornecedor");
      } catch (error) {
        console.log(error);
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    }
  }

  async function handleSubmitCode({
    isSecurityCode,
    securityCode,
  }: {
    isSecurityCode: string;
    securityCode: string;
    confirmSecurityCode: string;
  }) {
    await api.put(`/sellerconfig/${sellerStorage.id}`, {
      securityCode: securityCode,
      isSecurityCode: isSecurityCode === "1",
    });

    sellerLocalStorage.setUser({
      ...sellerStorage,
      securityCode: securityCode,
      isSecurityCode: isSecurityCode === "1",
    });

    addToast("Alteração código para informações delicadas com sucesso.", {
      appearance: "success",
      autoDismiss: true,
    });
  }

  useEffect(() => {
    if (sellerStorage) {
      formikCode.setFieldValue(
        "isSecurityCode",
        sellerStorage.isSecurityCode ? "1" : "0"
      );
    }

    // eslint-disable-next-line
  }, [sellerStorage]);

  return (
    <Container>
      <header>
        <h2>Alteração senha</h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            oldPassword: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object({
            oldPassword: Yup.string().required("Senha Atual é obrigatoria"),
            password: Yup.string()
              .required("Nova senha é obrigatoria")
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "A senha precisa conter letrar e numeros, no mínimo 8 caracteres e um caracter especial."
              ),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref("password")], "As senhas precisam ser iguais ")
              .required("Confirma nova senha é obrigatoria"),
          })}
          onSubmit={(data) => handleSubmit(data)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <div className="field">
                <label htmlFor="oldPassword">Digite sua senha atual</label>
                <Field type="password" name="oldPassword" />
                {formik.touched.oldPassword && formik.errors.oldPassword ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.oldPassword} </span>
                  </Error>
                ) : null}
              </div>
              <div className="field">
                <label htmlFor="password">Nova senha</label>
                <Field type="password" name="password" />
                {formik.touched.password && formik.errors.password ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.password} </span>
                  </Error>
                ) : null}
              </div>
              <div className="field">
                <label htmlFor="confirmPassword">Confirme sua nova senha</label>
                <Field type="password" name="confirmPassword" />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.confirmPassword} </span>
                  </Error>
                ) : null}
              </div>

              <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
            </Form>
          )}
        </Formik>
      </ContainerForm>

      {seller && (
        <>
          <header style={{ marginTop: "1rem" }}>
            <h2>Código para informações delicadas</h2>
          </header>

          <ContainerForm>
            <Form onSubmit={formikCode.handleSubmit}>
              <Select
                label="Optante pelo código"
                data={[
                  { name: "Sim", value: "1" },
                  { name: "Não", value: "0" },
                ]}
                {...formikCode.getFieldProps("isSecurityCode")}
                error={
                  formikCode.touched.isSecurityCode &&
                  formikCode.errors.isSecurityCode
                    ? formikCode.errors.isSecurityCode
                    : ""
                }
              />

              {formikCode.values.isSecurityCode === "1" && (
                <>
                  <Input
                    label="Código de segurança"
                    type="password"
                    {...formikCode.getFieldProps("securityCode")}
                    error={
                      formikCode.touched.securityCode &&
                      formikCode.errors.securityCode
                        ? formikCode.errors.securityCode
                        : ""
                    }
                  />
                  <Input
                    label="Confirme código de segurança"
                    type="password"
                    {...formikCode.getFieldProps("confirmSecurityCode")}
                    error={
                      formikCode.touched.confirmSecurityCode &&
                      formikCode.errors.confirmSecurityCode
                        ? formikCode.errors.confirmSecurityCode
                        : ""
                    }
                  />
                </>
              )}

              <ButtunSubmit type="submit"> Enviar </ButtunSubmit>
            </Form>
          </ContainerForm>
        </>
      )}
    </Container>
  );
};

export default Security;
