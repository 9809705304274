import filesize from "filesize";
import { useFormik } from "formik";

import moment from "moment";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import {
  AiFillCloseCircle,
  BsFileEarmarkCheck,
  FaArrowLeft,
  FaCheck,
  FaUserLock,
  FiAlertCircle,
  FiEdit,
  IoClose,
  IoMdSend,
  RiUserUnfollowLine,
  VscTriangleRight,
} from "react-icons/all";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
// @ts-ignore
import { mask } from "remask";
import * as Yup from "yup";
import { IPermissionUser } from "../../../@types";
import iconPdf from "../../../assets/icon_pdf.png";
import iconXml from "../../../assets/icon_xml.png";
import FloatButton from "../../../components/FloatButton";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import ModalConfirm from "../../../components/ModalLib";
import { ModalModificationDevolution } from "../../../components/ModalModificationDevolution";
import Modal from "../../../components/ModalVisibleImageBase64";
import Dropzone from "../../../components/Upload/Dropzone";
import Loading from "../../../components/loadings/Loading";
import api from "../../../service/api";
import * as clientStorage from "../../../service/localStorage/client";
import * as sellerLocalStorage from "../../../service/localStorage/seller";
import * as userLocalStorage from "../../../service/localStorage/user";
import generateHash from "../../../utils/generateHash";
import {
  ButtunSubmit,
  Container,
  ContainerCheck,
  ContainerDropZone,
  ContainerGroup,
  ContainerPreviews,
  Error as ErrorDiv,
  Form,
  InfoDevolution,
  InfoStatus,
  ObsSpan,
  ObsText,
  PopUpConfirmation,
  ScreenLoading,
} from "../styles";

interface HomeProps {
  location: {
    state: {
      content: IDevolution;
    };
  };

  match: {
    params: {
      id: number;
    };
  };

  admin: boolean;
  client: boolean;
  seller: boolean;
  provider: boolean;
  permission: IPermissionUser;
}

interface IFile {
  id: string;
  name: string;
  size: Number;
  url?: string;
  key?: string;
  preview_url?: string;
  mimetype?: string;
  fileBase64?: string;
}

interface IDevolution {
  id?: string;
  status?: IStatus;
  visible: boolean;
  suspended?: boolean;
  refusal: boolean;
  blockClient: boolean;
  fineRep: boolean;
  blockClientAction: boolean;

  requesterName: string;
  phone: string;
  email: string;

  created_user?: "Transportadora" | "Cliente" | "Operador" | "Vendedor";

  //Dados da solicitação
  numberInvoice: string;
  valueInvoice: number;
  situationRequest: string;
  products: string;

  requestType: string;

  //Dados da Devolução & indenização
  reason: string;
  subReason: string;
  internalObservation: string;

  //devolução
  dateInvoice: Date;

  //indenização
  brandObj: {
    cod: number;
    name: string;
    is_licensed: boolean;
  };
  brand: string;
  brandCod: string;
  stateProducts: string;
  dateEmissionNFconsumer: string;
  invoiceORcoupon: string;

  //Dados bancários
  bank: string;
  agency: string;
  account: string;
  typeAccountBank: string;

  //Informações complementares
  complementaryInformation: string;

  //extravio
  lossNoteNumber: number;
  collectionNoteNumber: number;
  collectionAmount: number;

  created_at: Date;
  closingDate: Date;

  user: IUser;

  clients_pj: {
    id: string;
    cod: string;
    cnpj: string;
    company_name: string;
    trade_name: string;
    email: string;
    phone2: string;
    emailInvoice: true;

    adresses?: {
      zip_code?: string;
      uf?: string;
      city?: string;
      neighborhood?: string;
      street?: string;
      numberStreet?: string;
    };
  };

  provider?: {
    id: number;
    fullname: string;
  };

  //Dados internos
  //Dados NFD
  nfdNumber?: string;
  nfdValue: number;
  nfdDate: Date;

  //Representante / Gestor
  salesman?: string;
  brandManager?: string;

  //Trasporte
  freightBrand?: string;
  freightValue?: number;
  freightFine?: number;
  occurrenceDate: Date;

  files: IFile[];

  shippingCompany: {
    id: number;
    cod: number;
    description: string;
  };

  observation: {
    created_at: Date;
    authorType: number;
    text: string;
    actionDescription?: string;
    action: boolean;
    seller: {
      cod: number;
      abbreviation: string;
      fullName: string;
    };
    user: {
      name: true;
      lastname: true;
    };
    client: {
      company_name: true;
      cnpj: true;
      cod: true;
    };

    status: { name: true };
  }[];
}

interface IStatus {
  id?: number;
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
  is_active: boolean;
  valid: boolean;
  responsable: 0 | 1 | 2 | 3 | 4;
  user: 0 | 1 | 2 | 3 | 4;
  responsable_permission_id: number;
  user_permission_id: number;
  approval: boolean;
}

interface IUser {
  id?: string;
  name?: string;
  lastname?: string;
  email?: string;
}

interface IChat {
  id?: number;
  name?: string;
  typeUser: string;
  textMessage?: string;
  idDevolution?: number;
  created_at?: Date;
}

export interface IBrand {
  id?: number;
  cod: number;
  name: string;
  is_active: boolean;
}

export interface IBrandManager {
  id?: number;
  cod: number;
  abbreviation: string;
  fullName: string;
  email?: string;
  phone?: string;
  is_active: boolean;
}

export interface ISalesman {
  id?: number;
  cod: number;
  abbreviation: string;
  fullName: string;
  email?: string;
  phone?: string;
  is_active: boolean;
}

export interface IShippingCompanies {
  id?: number;
  cod: number;
  description: string;
}

interface IResponsibleFreight {
  id: number;
  cod: number;
  name: string;
  penalty: boolean;
  description: string;
}

interface IInternalReason {
  id?: number;
  cod: number;
  name: string;
  is_active: boolean;

  responsibleFreight: IResponsibleFreight[];
}

interface IResponseInternlReanson {
  reasonInternal: IInternalReason[];
  listResponsibleFreight: IResponsibleFreight[];
}

interface IStatus {
  id?: number;
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
  is_active: boolean;
  is_licensed: boolean;
  is_distribution: boolean;
  exclusive?: string;
}

interface ISeller {
  cod: Number;
  id: Number;
  abbreviation: string;
  fullName: string;
  email: string;
  phone: string;

  is_manager: boolean;
  is_supervisor: boolean;
}

const Devolution: React.FC<HomeProps> = ({
  location,
  admin,
  seller,
  provider,
  match,
  permission,
  client,
}) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const clientData = clientStorage.getUser() as clientStorage.Client;
  const userStorage = userLocalStorage.getUser() as userLocalStorage.User;
  const sellerStorage = sellerLocalStorage.getUser() as ISeller;

  const messageInternalRef = useRef(null);
  const messageRef = useRef(null);

  const [activeUpdateDevolutionModal, setActiveUpdateDevolutionModal] =
    useState<boolean>(false);

  const [contentPage, setContentPage] = useState<IDevolution | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [update, setUpdate] = useState(1);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalFileOpen, setModalFileOpen] = useState<number>();

  const [modalAlertBlockClient, setModalAlertBlockClient] = useState(false);

  const [modalConfirm, setModalConfirm] = useState(false);
  const [confirmData, setConfirmData] = useState<{
    color: string;
    name: "Reentregar" | "Aceitar";
  }>(
    {} as {
      color: string;
      name: "Reentregar" | "Aceitar";
    }
  );

  const [penaltySeller, setPenaltySeller] = useState<Boolean>();
  const [blockClient, setBlockClient] = useState<Boolean>();
  const [observation, setObservation] = useState("");
  const [errorObservation, setErrorObservation] = useState("");
  const [errorBlockClient, setErrorBlockClient] = useState("");

  const [confirmeBlockClientAction, setConfirmeBlockClientAction] =
    useState(false);

  const [typeUser, setTypeUser] = useState<
    "user" | "client" | "seller" | "manager" | undefined
  >();

  const [users, setUsers] = useState<IUser[]>([]);
  const [brandManager, setBrandManager] = useState<IBrandManager[]>([]);
  const [salesman, setSalesman] = useState<ISalesman[]>([]);
  const [, setShippingCompanies] = useState<IShippingCompanies[]>([]);
  const [internalReasons, setInternalReasons] = useState<IInternalReason[]>([]);
  const [responsibleFreight, setResponsibleFreight] = useState<
    IResponsibleFreight[] | undefined
  >([]);
  const [listResponsibleFreight, setListResponsibleFreight] = useState<
    IResponsibleFreight[]
  >([]);

  const [brands, setBrands] = useState<IBrand[]>([]);
  const [status, setStatus] = useState<IStatus[]>([]);

  const [chat, setChat] = useState<IChat[]>([]);
  const [textChat, setTextChat] = useState("");

  const [chatInternal, setChatInternal] = useState<IChat[]>([]);
  const [textChatInternal, setTextChatInternal] = useState("");

  const [selectFile, setSelectFile] = useState<File>();
  const [files, setFiles] = useState<File[]>([]);

  const [listfiles, setListFiles] = useState<IFile[]>([]);

  const [disabledInputs, setDisabledInputs] = useState(true);

  const formikInternalData = useFormik({
    validateOnChange: false,

    initialValues: {
      idUser: undefined,
      status: undefined,
      statusObservation: undefined,
      visible: undefined,
      refusal: undefined,

      subReason: undefined,
      internalObservation: undefined,

      //Dados NF
      numberInvoice: undefined,
      valueInvoice: undefined,

      //Dados NFD
      nfdNumber: undefined,
      nfdValue: undefined,
      nfdDate: undefined,

      //Representante / Gestor / Marca
      salesman: undefined,
      brandManager: undefined,

      //Trasporte
      freightBrand: undefined,
      freightPenalty: undefined,
      freightValue: undefined,
      freightFine: undefined,

      confirmAction: undefined,

      //Transportadora
      shippingCompany: undefined,

      //Extravio
      lossNoteNumber: undefined,
      collectionNoteNumber: undefined,
      collectionAmount: undefined,
    },

    validationSchema: Yup.object({
      idUser: Yup.number().required(
        "Responsável pela solicitação é obrigatório"
      ),
      status: Yup.string()
        .nullable()
        .test("Valid_Status", "Status inválido ", function (value) {
          let error = true;
          let typeSolicitation = formikInternalData.values.refusal
            ? "recusa"
            : "devolução";

          const status = selectStatus(Number(value));
          if (Boolean(status.exclusive?.trim()))
            if (String(status.exclusive) !== typeSolicitation) {
              error = false;
            }

          return error;
        }),
      confirmAction: Yup.boolean()
        .nullable()
        .test("Valid_confirmAction", "Obrigatório", function (value) {
          let error = true;

          const status = selectStatus(Number(formikInternalData.values.status));

          if (
            contentPage &&
            contentPage.status &&
            Number(formikInternalData.values.status) !==
              Number(contentPage.status.cod) &&
            status.finisher
          ) {
            // if (status.finisher) {
            if (value === true || value === false) {
            } else {
              error = false;
            }
          }

          return error;
        }),
      statusObservation: Yup.string()
        .nullable()
        .test(
          "Valid_Status",
          "Observação sobre alteração de status é obrigatório ",
          async function (value) {
            let error = true;

            const { status } = formikInternalData.values;

            if (
              contentPage &&
              contentPage.status &&
              Number(status) !== Number(contentPage.status.cod)
            ) {
              error = false;
            }

            if (value) {
              error = true;
            }

            return error;
          }
        ),
      subReason: Yup.string()
        .nullable()
        .required("Motivo Interno é Obrigatório"),

      salesman: Yup.string()
        .nullable()
        .test("is_Fisisher", "Obrigatório ", async function (value) {
          let error = true;

          const status = await selectStatus(
            Number(formikInternalData.values.status)
          );

          if (status.finisher) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }
          return error;
        }),

      brandManager: Yup.string()
        .nullable()
        .test("is_Fisisher", "Obrigatório ", async function (value) {
          let error = true;

          const status = await selectStatus(
            Number(formikInternalData.values.status)
          );

          if (status.finisher) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }
          return error;
        }),

      freightValue: Yup.number()
        .nullable()
        .test("is_Fisisher", "Obrigatório ", async function (value) {
          let error = true;

          const status = await selectStatus(
            Number(formikInternalData.values.status)
          );
          if (!formikInternalData.values.refusal)
            if (status.finisher) {
              //@ts-ignore
              if (value === undefined || value === "" || value === null)
                error = false;
            }
          return error;
        }),

      freightBrand: Yup.string()
        .nullable()
        .test("is_Fisisher", "Obrigatório ", async function (value) {
          let error = true;

          const status = await selectStatus(
            Number(formikInternalData.values.status)
          );
          if (!formikInternalData.values.refusal)
            if (status.finisher) {
              if (value === undefined || value === "" || value === null)
                error = false;
            }
          return error;
        }),

      freightFine: Yup.number()
        .nullable()
        .test("is_Fisisher", "Obrigatório ", async function (value) {
          let error = true;

          const status = await selectStatus(
            Number(formikInternalData.values.status)
          );
          if (!formikInternalData.values.refusal)
            if (
              status.finisher &&
              formikInternalData.values.freightBrand === "representante"
            ) {
              //@ts-ignore
              if (value === undefined || value === "" || value === null)
                error = false;
            }
          return error;
        }),

      //RECUSA
      valueInvoice: Yup.number().required("Valor é obrigatório"),
    }),
    //@ts-ignore
    onSubmit: (values) => (!loadingSubmit ? hadleSubumit(values) : () => {}),
  });

  useEffect(() => {
    if (seller) {
      if (sellerStorage) {
        if (sellerStorage.is_manager || sellerStorage.is_supervisor) {
          setTypeUser("manager");
        } else {
          setTypeUser("seller");
        }
      }
    }

    if (admin) setTypeUser("user");

    if (client) setTypeUser("client");
  }, [admin, seller, client, sellerStorage]);

  useEffect(() => {
    if (!modalConfirm) {
      setErrorBlockClient("");
      setErrorObservation("");
    }
  }, [modalConfirm]);

  useEffect(() => {
    if (
      admin &&
      permission &&
      permission.devolution &&
      permission.devolution > 3
    )
      setDisabledInputs(false);
  }, [admin, permission]);

  useEffect(() => {
    if (selectFile) setFiles([...files, selectFile]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFile]);

  useEffect(() => {
    (async () => {
      if (admin || client) {
        const brandDB = await api.get<IBrand[]>("/populate/brand");
        setBrands(brandDB.data);
      }
      if (seller) {
        const brandDB = await api.get<IBrand[]>("/populate/brand/seller");
        setBrands(brandDB.data);
      }

      if (admin) {
        const statusDB = await api.get<IStatus[]>("/devolution/status", {
          params: {
            active: 1,
            user_permission_id: Number(userStorage.permission),
          },
        });
        setStatus(statusDB.data);
      }
    })();

    if (admin || seller) {
      (async () => {
        const salesmanDB = await api.get<ISalesman[]>("/populate/salesman");
        setSalesman(salesmanDB.data);

        const shippingCompaniesDB = await api.get<IShippingCompanies[]>(
          "/populate/shipping_company/list"
        );
        setShippingCompanies(shippingCompaniesDB.data);

        const brandManagerDB = await api.get<IBrandManager[]>(
          "/populate/brandManager",
          { params: { active: 1 } }
        );
        setBrandManager(brandManagerDB.data);

        const reansonInternalDB = await api.get<IResponseInternlReanson>(
          "/populate/reason/devolution",
          { params: { active: 1 } }
        );
        setInternalReasons(reansonInternalDB.data.reasonInternal);
        setListResponsibleFreight(
          reansonInternalDB.data.listResponsibleFreight
        );

        const adminDB = await api.get<IUser[]>("/populate/userAdmin");
        setUsers(adminDB.data);
      })();
    }
    // eslint-disable-next-line
  }, [admin, seller, client]);

  useEffect(() => {
    if (contentPage && admin && !contentPage.refusal) {
      if (contentPage.freightBrand) {
        const penalty = selectResponsibleFreight(contentPage.freightBrand);

        if (penalty) {
          formikInternalData.setFieldValue("freightPenalty", penalty.penalty);
        }
      }
    }
    // eslint-disable-next-line
  }, [listResponsibleFreight, admin, contentPage]);

  useEffect(() => {
    if (contentPage && (admin || seller)) {
      if (contentPage.user && contentPage.user.id)
        formikInternalData.setFieldValue("idUser", contentPage.user.id);
      if (contentPage.status && contentPage.status.cod)
        formikInternalData.setFieldValue("status", contentPage.status.cod);
      if (contentPage.shippingCompany && contentPage.shippingCompany.id)
        formikInternalData.setFieldValue(
          "shippingCompany",
          contentPage.shippingCompany.id
        );
      if (contentPage.visible === false || contentPage.visible === true)
        formikInternalData.setFieldValue("visible", contentPage.visible);
      if (contentPage.refusal === false || contentPage.refusal === true)
        formikInternalData.setFieldValue("refusal", contentPage.refusal);
      if (contentPage.subReason)
        formikInternalData.setFieldValue("subReason", contentPage.subReason);

      if (contentPage.internalObservation)
        formikInternalData.setFieldValue(
          "internalObservation",
          contentPage.internalObservation
        );
      if (contentPage.numberInvoice)
        formikInternalData.setFieldValue(
          "numberInvoice",
          contentPage.numberInvoice
        );
      if (contentPage.valueInvoice)
        formikInternalData.setFieldValue(
          "valueInvoice",
          contentPage.valueInvoice
        );
      if (contentPage.nfdNumber)
        formikInternalData.setFieldValue("nfdNumber", contentPage.nfdNumber);
      if (contentPage.nfdValue)
        formikInternalData.setFieldValue("nfdValue", contentPage.nfdValue);
      if (contentPage.nfdDate)
        formikInternalData.setFieldValue(
          "nfdDate",
          contentPage?.nfdDate !== null
            ? moment(contentPage?.nfdDate).add("day", 1).format("YYYY-MM-DD")
            : undefined
        );
      if (contentPage.salesman)
        formikInternalData.setFieldValue("salesman", contentPage?.salesman);
      if (contentPage.brandManager)
        formikInternalData.setFieldValue(
          "brandManager",
          contentPage.brandManager
        );
      if (contentPage.freightBrand)
        formikInternalData.setFieldValue(
          "freightBrand",
          contentPage.freightBrand === null ? "" : contentPage?.freightBrand
        );
      if (contentPage.freightValue)
        formikInternalData.setFieldValue(
          "freightValue",
          contentPage.freightValue
        );
      if (contentPage.freightFine)
        formikInternalData.setFieldValue(
          "freightFine",
          contentPage.freightFine
        );
      if (contentPage.lossNoteNumber)
        formikInternalData.setFieldValue(
          "lossNoteNumber",
          contentPage.lossNoteNumber
        );
      if (contentPage.collectionNoteNumber)
        formikInternalData.setFieldValue(
          "collectionNoteNumber",
          contentPage.collectionNoteNumber
        );
      if (contentPage.collectionAmount)
        formikInternalData.setFieldValue(
          "collectionAmount",
          contentPage.collectionAmount
        );
    }

    (async () => {
      if (contentPage && contentPage.id && !provider) {
        const chatDB = await api.get<IChat[]>(
          `/message/devolution/${contentPage.id}`
        );
        setChat(chatDB.data);

        if (seller || admin) {
          const chatInternalDB = await api.get<IChat[]>(
            `/devolution/message/internal/${contentPage.id}`
          );
          setChatInternal(chatInternalDB.data);
        }

        const fileDB = await api.get<IFile[]>(
          `/devolution/file/${contentPage.id}`
        );
        setListFiles(fileDB.data);
      }
    })();

    // eslint-disable-next-line
  }, [contentPage, admin, provider, seller]);

  useEffect(() => {
    setLoading(true);

    (async () => {
      try {
        if (false && Boolean(location)) {
          setContentPage(location.state.content);

          const { blockClient } = location.state.content;

          if (blockClient === true || blockClient === false) {
            setBlockClient(blockClient);
          }

          if (admin && permission.devolution > 3) {
            await api.put(
              `/devolution/notification/off/${location.state.content.id}`
            );
          }
        } else {
          throw new Error("update");
        }
      } catch (error) {
        if (Boolean(match.params.id)) {
          try {
            if (admin || seller || provider) {
              const devolution = await api.get<IDevolution>(
                `/devolution/show/${match.params.id}`
              );

              if (!Boolean(devolution.data)) {
                return history.push("/404");
              }

              const { blockClient, fineRep } = devolution.data;

              if (blockClient === true || blockClient === false) {
                setBlockClient(blockClient);
              }

              if (fineRep === true || fineRep === false) {
                setPenaltySeller(fineRep);
              }

              setContentPage(devolution.data);
            }

            if (client) {
              const devolutionClient = await api.get<IDevolution>(
                `/devolution/show/${clientData.id}/${match.params.id}`
              );

              if (!Boolean(devolutionClient.data)) {
                return history.push("/404");
              }

              setContentPage(devolutionClient.data);
            }
          } catch (error) {
            return history.push("/404");
          }
        } else {
          history.push("/404");
        }
      }
      setLoading(false);
    })();

    // eslint-disable-next-line
  }, [location, history, admin, match.params.id, update]);

  useEffect(() => {
    if (
      Number(formikInternalData.values.subReason) &&
      internalReasons[0] &&
      !formikInternalData.values.refusal
    ) {
      const dataInternalReason = selectInternalReason(
        Number(formikInternalData.values.subReason)
      );

      if (
        internalReasons &&
        formikInternalData.values.subReason !== undefined &&
        dataInternalReason &&
        dataInternalReason.responsibleFreight
      ) {
        setResponsibleFreight(dataInternalReason.responsibleFreight);

        if (dataInternalReason.responsibleFreight.length === 1) {
          formikInternalData.setFieldValue(
            "freightBrand",
            dataInternalReason.responsibleFreight[0].name
          );
          formikInternalData.setFieldValue(
            "freightPenalty",
            dataInternalReason.responsibleFreight[0].penalty
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [formikInternalData.values.subReason, internalReasons]);

  useEffect(() => {
    if (client) {
      if (contentPage && chat && files && brands) {
        setLoading(false);
      }
    }

    if (admin || seller) {
      if (
        contentPage &&
        chat &&
        chatInternal &&
        files &&
        users &&
        brandManager &&
        salesman &&
        internalReasons &&
        status
      ) {
        setLoading(false);
      }
    }
  }, [
    contentPage,
    chat,
    files,
    brands,
    chatInternal,
    users,
    brandManager,
    salesman,
    internalReasons,
    status,
    admin,
    seller,
    client,
  ]);

  async function hadleAlterStatusClient() {
    try {
      if (contentPage) {
        await api.put(`/clientconfig/devolution/${contentPage.id}`, {
          status: 4,
        });

        addToast("Solicitação finalizada com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
      }

      history.push("/devolucoes");
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function hadleSubumit(data: IDevolution) {
    try {
      if (contentPage && contentPage.id) {
        setLoadingSubmit(true);
        await api.put(`/devolution/${contentPage?.id}`, data);
        addToast("Devolução alterado com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push("/admin/devolucoes");
        setLoadingSubmit(false);
      }
    } catch (error) {
      setLoadingSubmit(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  function selectStatus(s: number) {
    let value: IStatus = {} as IStatus;

    for (const i of status) {
      if (i.cod === s) {
        value = i;
      }
    }
    return value;
  }

  async function hadleDeleteFile(name: string) {
    files.forEach((element: File, index) => {
      if (element.name === name) {
        files.splice(index, 1);
      }
    });

    setFiles([]);

    setTimeout(() => {
      setFiles(files);
    }, 1);
  }

  async function hadleSubmitFiles() {
    if (!admin) {
      //@ts-ignore
      if (contentPage?.status?.finisher) {
        return addToast("Solicitação finalizada", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    }
    const formData = new FormData();

    if (selectFile) {
      files.forEach((element) => {
        formData.append("file", element);
      });
    }

    try {
      const filesResponse = await api.post<IFile[]>(
        "/devolution/upload",
        formData
      );

      if (contentPage && contentPage.id) {
        await api.put(`/clientconfig/devolution/${contentPage.id}`, {
          files: filesResponse.data.map((item) => ({
            id: item.id,
            name: item.name,
            size: item.size,
            url: item.url,
            mimetype: item.mimetype,
            key: item.key,
          })),
        });

        if (!admin)
          await api.put(`/devolution/notification/on/${contentPage.id}`);

        filesResponse.data.forEach((element) => {
          setListFiles([
            ...listfiles,
            {
              id: element.id,
              name: element.name,
              size: element.size,
              url: element.preview_url,
              mimetype: element.mimetype,
            },
          ]);
        });

        setFiles([]);

        addToast("Arquivo(s) anexado com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
      }
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function hadleSubitChat(e: FormEvent) {
    e.preventDefault();

    if (!admin) {
      //@ts-ignore
      if (contentPage?.status?.finisher) {
        return addToast("Solicitação finalizada", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    }

    if (!textChat) return;

    let message: IChat = {
      name: admin
        ? `${userStorage.name} ${userStorage.lastname}`
        : contentPage?.requesterName,
      textMessage: textChat,
      typeUser: admin ? "admin" : "client",
      idDevolution: Number(contentPage?.id),
    };

    if (admin) {
      await api.post(`/message/devolution/admin`, message);
      await api.put(`/devolution/notification/off/${contentPage?.id}`);
    } else {
      await api.put(`/devolution/notification/on/${contentPage?.id}`);
      await api.post(`/message/devolution/client`, message);
    }

    setChat([
      {
        id: 1,
        name: admin
          ? `${userStorage.name} ${userStorage.lastname}`
          : contentPage?.requesterName,

        textMessage: textChat,
        typeUser: admin ? "admin" : "client",

        created_at: new Date(),
      },
      ...chat,
    ]);

    setTextChat("");

    const inputText = messageRef as React.RefObject<HTMLInputElement>;

    if (inputText.current) {
      inputText.current.value = "";
    }
  }

  async function hadleSubitChatInternal(e: FormEvent) {
    e.preventDefault();

    if (!textChatInternal) return;

    if (contentPage) {
      // "client"
      // "admin"
      // "seller"
      // "seller_manager"
      var typeUser: "admin" | "seller" | "seller_manager" | undefined;

      if (admin) typeUser = "admin";
      if (seller) {
        if (sellerStorage.is_manager || sellerStorage.is_supervisor) {
          typeUser = "seller_manager";
        } else {
          typeUser = "seller";
        }
      }

      if (typeUser) {
        var name = seller
          ? `${sellerStorage.cod} - ${sellerStorage.fullName}`
          : `${userStorage.name} ${userStorage.lastname}`;

        let message: IChat = {
          name,
          textMessage: textChatInternal,
          typeUser,
        };

        await api.post(
          `/devolution/message/internal/${contentPage.id}`,
          message
        );

        setChatInternal([
          {
            ...message,
            id: chatInternal.length + 1,
            created_at: new Date(),
          },
          ...chatInternal,
        ]);

        setTextChatInternal("");

        if (typeUser === "admin") {
          await api.put(`/devolution/notification/off/${contentPage.id}`);
        }

        if (typeUser !== "admin") {
          await api.put(`/devolution/notification/on/${contentPage.id}`);
        }

        const inputText =
          messageInternalRef as React.RefObject<HTMLInputElement>;

        if (inputText.current) {
          inputText.current.value = "";
        }
      }
    }
  }

  async function handleSubmitConfirm() {
    try {
      setLoadingModal(true);
      var test = testSubmitConfirm();
      if (test && contentPage && contentPage.id && confirmData.name) {
        if (typeUser === "user") {
          // 16	Diretor acatou solicitação (Distribuição)
          // 17	Diretor recusou solicitação (Distribuição)

          // 20	Diretor acatou solicitação (Licenciamento)
          // 21	Diretor recusou solicitação (Licenciamento)
          var dataUser = {};

          dataUser = { ...dataUser, statusObservation: observation };

          if (confirmData.name === "Aceitar") {
            dataUser = {
              ...dataUser,
              status: contentPage.brandObj?.is_licensed ? 20 : 16,
              blockClient: blockClient,
              fineRep: permission?.is_licensed
                ? !!penaltySeller
                : blockClient === undefined
                ? undefined
                : blockClient
                ? false
                : true,
              shippingClient: permission?.is_licensed
                ? !!penaltySeller
                : blockClient === undefined
                ? undefined
                : blockClient
                ? false
                : true,
            };
          }

          if (confirmData.name === "Reentregar") {
            dataUser = {
              ...dataUser,
              status: contentPage.brandObj?.is_licensed ? 21 : 17,
            };
          }

          await api.put(`/devolution/${contentPage.id}`, dataUser);
          setModalConfirm(false);
          addToast("Devolução alterado com sucesso", {
            appearance: "success",
            autoDismiss: true,
          });
          history.goBack();
        }

        if (
          (typeUser === "manager" || typeUser === "seller") &&
          confirmData.name
        ) {
          var dataSeller = {};

          dataSeller = { ...dataSeller, observation: observation };

          if (typeUser === "manager") {
            // 12	Gerente acatou solicitação
            // 14	Gerente recusou solcitação
            // 23	Gerente acatou solicitação
            // 22	Gerente recusou solcitação

            // 16	Diretor acatou solicitação (Distribuição)
            // 17	Diretor recusou solicitação (Distribuição)

            // 7 Aguardando retorno do gestor

            if (confirmData.name === "Aceitar") {
              dataSeller = {
                ...dataSeller,
                status: contentPage.brandObj?.is_licensed
                  ? 23
                  : sellerStorage.cod === 2332
                  ? 16
                  : 12,
                blockClient,
                fineRep:
                  blockClient === undefined
                    ? undefined
                    : blockClient
                    ? false
                    : true,
                shippingClient:
                  blockClient === undefined
                    ? undefined
                    : blockClient
                    ? false
                    : true,
              };
            }

            if (confirmData.name === "Reentregar") {
              dataSeller = {
                ...dataSeller,
                status: contentPage.brandObj?.is_licensed
                  ? 22
                  : sellerStorage?.cod === 2332
                  ? 17
                  : 14,
              };
            }
          }

          if (typeUser === "seller") {
            // 11	Representante acatou solicitação
            // 13	Representante recusou solcitação

            // 15 Aguardando retorno do representante

            if (confirmData.name === "Aceitar") {
              dataSeller = {
                ...dataSeller,
                status: 11,
              };
            }

            if (confirmData.name === "Reentregar") {
              dataSeller = {
                ...dataSeller,
                status: 13,
              };
            }
          }

          await api.put(`/devolution/${contentPage.id}`, dataSeller);

          setModalConfirm(false);
          addToast("Devolução alterado com sucesso", {
            appearance: "success",
            autoDismiss: true,
          });
          history.goBack();
        }

        setLoadingModal(false);
      }
      setLoadingModal(false);
    } catch (err) {
      const error = err as any;
      setLoadingModal(false);
      setModalConfirm(false);
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleActiveConfirmationBlockClient() {
    var test = testSubmitConfirm();

    if (test) {
      if (typeUser === "user") {
        setModalAlertBlockClient(true);
      }
    }
  }

  async function handleActionBlockClient() {
    try {
      if (contentPage && contentPage.id) {
        await api.put(`/devolution/${contentPage.id}`, {
          blockClientAction: true,
          actionDescription: "realizou o bloqueio do cliente no ERP",
          action: true,
        });
        addToast("Devolução alterado com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push("/admin/devolucoes");
      }
    } catch (error) {
      setLoadingSubmit(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  function testSubmitConfirm() {
    var test = false;

    if (confirmData.name === "Aceitar" && typeUser === "manager") {
      if (blockClient !== undefined && observation) {
        test = true;
      } else {
        if (!observation) {
          setErrorObservation("É obrigatório");
        } else {
          setErrorObservation("");
        }
        if (blockClient === undefined) {
          setErrorBlockClient("É obrigatório");
        } else {
          setErrorBlockClient("");
        }
      }
    } else {
      if (observation) {
        test = true;
        setErrorObservation("");
      } else {
        setErrorObservation("É obrigatório");
      }
    }

    return test;
  }

  function selectInternalReason(brand: number): IInternalReason | undefined {
    let brandName = undefined;

    internalReasons.forEach((item) => {
      if (item.cod === brand) {
        return (brandName = item);
      }
    });

    return brandName;
  }

  function selectResponsibleFreight(
    brand: string
  ): IResponsibleFreight | undefined {
    let brandName = undefined;

    listResponsibleFreight.forEach((item) => {
      if (item.name === brand) {
        return (brandName = item);
      }
    });

    return brandName;
  }

  function isActionBlockClient(): boolean {
    var data = false;

    if (
      admin &&
      contentPage &&
      contentPage.status &&
      contentPage.status.finisher &&
      contentPage.blockClient &&
      !contentPage.blockClientAction &&
      userStorage &&
      Number(userStorage.permission) === 4
    ) {
      data = true;
    }

    return data;
  }

  function isDateInternal(): boolean {
    var data = false;

    if (permission && permission.devolution && permission.devolution > 3) {
      data = true;
    }

    return data;
  }

  function activeSeller(): boolean {
    var entity: "Seller" | "SellerManager" | "Director" | undefined = undefined;
    var data: boolean = false;

    if (seller) {
      entity = "Seller";
      if (sellerStorage.is_manager || sellerStorage.is_supervisor)
        entity = "SellerManager";

      if (sellerStorage?.cod === 2332) entity = "Director";
    }

    if (contentPage && contentPage.status) {
      const status = contentPage.status;

      if (!status.finisher) {
        switch (entity) {
          case "Seller":
            if (status.responsable === 3) data = true;
            break;

          case "SellerManager":
            if (
              status.responsable === 4 &&
              Number(contentPage?.brandManager) === Number(sellerStorage?.cod)
            )
              data = true;
            break;

          case "Director":
            if (
              (status.approval &&
                status.responsable === 2 &&
                [26, 10].includes(Number(contentPage?.brandCod))) ||
              (status.responsable === 4 &&
                Number(contentPage?.brandManager) ===
                  Number(sellerStorage?.cod))
            )
              data = true;
            break;

          default:
            data = false;
            break;
        }
      }
    }

    return data;
  }

  function selectAuthor(typeUser: string): "to" | "i" {
    const author = typeUser as "admin" | "seller" | "seller_manager";

    if (admin && author === "admin") {
      return "i";
    }

    if (
      seller &&
      author === "seller" &&
      (!sellerStorage.is_manager || !sellerStorage.is_supervisor)
    ) {
      return "i";
    }

    if (
      seller &&
      author === "seller_manager" &&
      (sellerStorage.is_manager || sellerStorage.is_supervisor)
    ) {
      return "i";
    }

    return "to";
  }

  return (
    <Container>
      {contentPage !== null && !loading ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="arrowBack"
            >
              <FaArrowLeft size={18} />
            </button>
            <h2>Detalhes da solicitação</h2>

            {admin && permission.devolution >= 2 && (
              <div className="containerActions">
                <button
                  type="button"
                  className="buttonActionDatasheet"
                  onClick={() => {
                    setActiveUpdateDevolutionModal(true);
                  }}
                >
                  {
                    <div>
                      <span>
                        <FiEdit color="#8f6e09" />
                      </span>
                      Editar
                    </div>
                  }
                </button>
              </div>
            )}
          </header>

          <InfoStatus>
            <div className="containerStatus">
              <div
                className="bannerLine"
                style={{
                  backgroundColor: contentPage.status?.color,
                }}
              />
              <div className="box">
                <div>
                  <h5>STATUS</h5>
                </div>
                <span
                  style={{
                    backgroundColor: contentPage.status?.color,
                  }}
                >
                  {contentPage.status?.name}
                </span>
              </div>
            </div>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>RESPONSÁVEL</h5>
                </div>
                <p>
                  {contentPage.user && contentPage.user.name !== undefined
                    ? `${contentPage.user.name} ${contentPage.user.lastname}`
                    : `-`}
                </p>
              </div>
            </div>

            {!contentPage.status?.finisher && (
              <div className="containerStatus">
                <div className="box">
                  <div>
                    <h5>CRIADO EM</h5>
                  </div>
                  <p>{moment(contentPage.created_at).format("DD/MM/YYYY")}</p>
                </div>
              </div>
            )}

            {contentPage.status?.finisher && (
              <>
                <div className="containerStatus">
                  <div className="box">
                    <div>
                      <h5>CRIADO EM</h5>
                    </div>
                    <p>{moment(contentPage.created_at).format("DD/MM/YYYY")}</p>
                  </div>
                </div>
                <div className="containerStatus">
                  <div className="box">
                    <div>
                      <h5>FINALIZADO EM</h5>
                    </div>
                    <p>
                      {moment(contentPage.closingDate).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
              </>
            )}
          </InfoStatus>

          <InfoDevolution>
            <div className="headerDevoltion">
              <h2>Informações</h2>

              {!contentPage.status?.finisher && client && (
                <div className="containerActions">
                  <button className="buttonActions" type="button">
                    Ações
                  </button>
                  <ul className="itemsActions">
                    <li onClick={hadleAlterStatusClient}>
                      <AiFillCloseCircle /> Finalizar solicitação
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="bodyDevoltion">
              <div className="conatinerInfo">
                <h3>Cliente</h3>
                <div className="line"></div>

                <div>
                  <h4>CÓD. CLIENTE</h4>
                  <span>{contentPage.clients_pj.cod}</span>

                  <h4>CNPJ</h4>
                  <span>{contentPage.clients_pj.cnpj}</span>

                  <h4>RAZÃO SOCIAL</h4>
                  <span>{contentPage.clients_pj.company_name}</span>

                  <h4>ENDEREÇO</h4>
                  <span>{`${contentPage.clients_pj.adresses?.street}, ${
                    contentPage.clients_pj.adresses?.numberStreet
                  } - ${contentPage.clients_pj.adresses?.neighborhood}, ${
                    contentPage.clients_pj.adresses?.city
                  } - ${contentPage.clients_pj.adresses?.uf}, ${mask(
                    contentPage.clients_pj.adresses?.zip_code,
                    ["99999-999"]
                  )}`}</span>

                  <h4>TELEFONE</h4>
                  <span>{`${contentPage.phone} / ${contentPage.clients_pj.phone2}`}</span>

                  <h4>E-MAIL</h4>
                  <span>{`${contentPage.email}`}</span>
                </div>
              </div>

              <div className="conatinerInfo">
                <h3>Solicitação</h3>
                <div className="line"></div>
                <div>
                  <h4>PROTOCOLO</h4>
                  <span>{contentPage.id}</span>

                  <h4>NOTA FISCAL N°</h4>
                  <span>{contentPage.numberInvoice}</span>

                  <h4>TIPO DE DEVOLUÇÃO</h4>
                  <span>{contentPage.situationRequest}</span>
                  {contentPage.situationRequest === "parcial" ? (
                    <>
                      <h4>PRODUTOS</h4>
                      <span>{contentPage.products}</span>
                    </>
                  ) : null}

                  <h4>TIPO DE SOLICITAÇÃO</h4>
                  <span>{contentPage.requestType}</span>

                  <h4>TÍTULOS SUSTADO</h4>
                  <span>
                    {contentPage?.suspended === undefined ||
                    contentPage?.suspended === null
                      ? "-"
                      : contentPage?.suspended
                      ? "Sim"
                      : "Não"}
                  </span>
                </div>
              </div>

              <div className="conatinerInfo">
                <h3>Devolução</h3>
                <div className="line"></div>
                <div>
                  {/* <h4>MOTIVO</h4>
                  <span>{contentPage.reason}</span> */}

                  <h4>MARCA</h4>
                  <span>{contentPage.brand}</span>

                  <h4>DATA NOTA FISCAL</h4>
                  <span>
                    {moment(contentPage.dateInvoice)
                      .add("day", 1)
                      .format("DD/MM/YYYY")}
                  </span>
                </div>
              </div>

              {contentPage?.provider && (
                <div className="conatinerInfo">
                  <h3> Forncedor / Transportadora</h3>
                  <div className="line"></div>

                  <div>
                    <h4>NOME</h4>
                    <span>{contentPage.provider?.fullname}</span>
                  </div>
                </div>
              )}

              <div className="conatinerInfo">
                <h3>Informações complementares</h3>
                <div className="line" />
                <div>
                  <p>{contentPage.complementaryInformation}</p>
                </div>
              </div>

              {modalVisible &&
                modalFileOpen !== null &&
                modalFileOpen !== undefined && (
                  <Modal
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    images={listfiles}
                    indexImg={modalFileOpen}
                  />
                )}

              <div className="conatinerInfo">
                <h3>Arquivos</h3>
                <div className="line" />
                <div className="containerImg">
                  {listfiles.map((img, index) => (
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => {
                        setModalFileOpen(index);
                        setModalVisible(true);
                      }}
                      key={img.id}
                      src={
                        img.mimetype === "application/pdf"
                          ? iconPdf
                          : img.mimetype === "text/xml" ||
                            img.mimetype === "application/xml"
                          ? iconXml
                          : `data:image/png;base64,${img.fileBase64}`
                      }
                      alt={img.name}
                    />
                  ))}
                </div>
              </div>
            </div>
          </InfoDevolution>

          {(admin || seller) && (
            <InfoDevolution>
              <div className="headerDevoltion">
                <h2>Dados internos</h2>
              </div>

              <div className="bodyDevoltion">
                <Form
                  onSubmit={
                    isDateInternal()
                      ? formikInternalData.handleSubmit
                      : () => {}
                  }
                >
                  <Input
                    label="Tipo de usuário que criou solicitação"
                    value={contentPage?.created_user ?? "-"}
                    readOnly
                  />

                  {!seller && (
                    <>
                      <h3>Visível para cliente</h3>
                      <div className="field">
                        <ContainerCheck>
                          <button
                            type="button"
                            className={
                              formikInternalData.values.visible === true
                                ? "check"
                                : ""
                            }
                            onClick={() =>
                              !disabledInputs &&
                              formikInternalData.setFieldValue("visible", true)
                            }
                          >
                            Sim
                          </button>

                          <button
                            type="button"
                            onClick={() =>
                              !disabledInputs &&
                              formikInternalData.setFieldValue("visible", false)
                            }
                            className={
                              formikInternalData.values.visible === false
                                ? "check"
                                : ""
                            }
                          >
                            Não
                          </button>
                        </ContainerCheck>
                      </div>
                    </>
                  )}
                  <h3>Tipo de solicitação</h3>
                  <div className="field">
                    <ContainerCheck>
                      <button
                        type="button"
                        className={
                          formikInternalData.values.refusal === true
                            ? "check"
                            : ""
                        }
                        onClick={() =>
                          !disabledInputs &&
                          formikInternalData.setFieldValue("refusal", true)
                        }
                      >
                        RECUSA
                      </button>

                      <button
                        type="button"
                        onClick={() =>
                          !disabledInputs &&
                          formikInternalData.setFieldValue("refusal", false)
                        }
                        className={
                          formikInternalData.values.refusal === false
                            ? "check"
                            : ""
                        }
                      >
                        DEVOLUÇÃO
                      </button>
                    </ContainerCheck>
                  </div>

                  <h3>Solicitação</h3>

                  <div className="field-group">
                    <div className="field">
                      <label htmlFor="requesterName">
                        Status da solicitação
                      </label>
                      <select
                        {...formikInternalData.getFieldProps("status")}
                        disabled={disabledInputs}
                      >
                        <option value={contentPage.status?.cod}>
                          {contentPage.status?.name}
                        </option>
                        {status.map((item) => {
                          if (item.user === 2) {
                            if (contentPage.brandObj?.is_licensed) {
                              if (item?.is_licensed !== false) {
                                return (
                                  <option key={item.id} value={item.cod}>
                                    {item.name}
                                  </option>
                                );
                              } else {
                                return null;
                              }
                            } else {
                              if (item.is_distribution !== false) {
                                return (
                                  <option key={item.id} value={item.cod}>
                                    {item.name}
                                  </option>
                                );
                              } else {
                                return null;
                              }
                            }
                          }

                          return null;
                        })}
                      </select>

                      {formikInternalData.touched.status &&
                      formikInternalData.errors.status ? (
                        <ErrorDiv>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formikInternalData.errors.status} </span>
                        </ErrorDiv>
                      ) : null}
                    </div>

                    <div className="field">
                      <label htmlFor="requesterName">
                        Responsável pela solicitação
                      </label>
                      <select
                        {...formikInternalData.getFieldProps("idUser")}
                        disabled={disabledInputs}
                      >
                        <option value="">Selecionar responsável</option>
                        {users.map((item) => (
                          <option
                            key={item.id}
                            value={item.id}
                          >{`${item.name} ${item.lastname}`}</option>
                        ))}
                      </select>
                      {formikInternalData.touched.idUser &&
                      formikInternalData.errors.idUser ? (
                        <ErrorDiv>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formikInternalData.errors.idUser} </span>
                        </ErrorDiv>
                      ) : null}
                    </div>
                  </div>

                  {contentPage &&
                    contentPage.status &&
                    Number(formikInternalData.values.status) !==
                      Number(contentPage.status.cod) && (
                      <div className="field-group">
                        {selectStatus(Number(formikInternalData.values.status))
                          .finisher && (
                          <div className="field">
                            <label htmlFor="statusObservation">
                              Responsabilidade da solicitação
                            </label>
                            <ObsSpan>
                              * Externa: Reponsabilidade cliente e representante
                            </ObsSpan>
                            <ObsSpan>
                              * Alpar: Remove as multas e bloqueio dos clientes
                            </ObsSpan>

                            <ContainerCheck>
                              <button
                                type="button"
                                className={
                                  formikInternalData.values.confirmAction ===
                                  true
                                    ? "check"
                                    : ""
                                }
                                onClick={() =>
                                  !disabledInputs &&
                                  formikInternalData.setFieldValue(
                                    "confirmAction",
                                    true
                                  )
                                }
                              >
                                Externa
                              </button>

                              <button
                                type="button"
                                onClick={() =>
                                  !disabledInputs &&
                                  formikInternalData.setFieldValue(
                                    "confirmAction",
                                    false
                                  )
                                }
                                className={
                                  formikInternalData.values.confirmAction ===
                                  false
                                    ? "check"
                                    : ""
                                }
                              >
                                Alpar
                              </button>
                            </ContainerCheck>

                            {formikInternalData.touched.confirmAction &&
                            formikInternalData.errors.confirmAction ? (
                              <ErrorDiv>
                                <FiAlertCircle color="#f00" size={16} />
                                <span>
                                  {formikInternalData.errors.confirmAction}
                                </span>
                              </ErrorDiv>
                            ) : null}
                          </div>
                        )}

                        <div className="field">
                          <label htmlFor="statusObservation">
                            Observação sobre alteração de status
                          </label>
                          <textarea
                            disabled={disabledInputs}
                            id="statusObservation"
                            {...formikInternalData.getFieldProps(
                              "statusObservation"
                            )}
                          ></textarea>
                          {formikInternalData.touched.statusObservation &&
                          formikInternalData.errors.statusObservation ? (
                            <ErrorDiv>
                              <FiAlertCircle color="#f00" size={16} />
                              <span>
                                {formikInternalData.errors.statusObservation}
                              </span>
                            </ErrorDiv>
                          ) : null}
                        </div>
                      </div>
                    )}

                  <div className="field">
                    <label htmlFor="requesterName">Motivo Interno</label>
                    <select
                      {...formikInternalData.getFieldProps("subReason")}
                      disabled={disabledInputs}
                    >
                      <option value="">Selecionar motivo interno</option>
                      {internalReasons.map((item) => (
                        <option key={item.id} value={item.cod}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {formikInternalData.touched.subReason &&
                    formikInternalData.errors.subReason ? (
                      <ErrorDiv>
                        <FiAlertCircle color="#f00" size={16} />
                        <span> {formikInternalData.errors.subReason} </span>
                      </ErrorDiv>
                    ) : null}
                  </div>

                  <div className="field-group">
                    <div className="field">
                      <label htmlFor="requesterName">
                        Multa para Representante
                      </label>
                      <select disabled>
                        <option value="">
                          {contentPage.fineRep === null ||
                          contentPage.fineRep === undefined
                            ? "Não definido"
                            : contentPage.fineRep
                            ? "SIM"
                            : "NÃO"}
                        </option>
                      </select>
                    </div>
                    <div className="field">
                      <label htmlFor="requesterName">Bloquear cliente</label>
                      <select disabled>
                        <option value="">
                          {contentPage.blockClient === null ||
                          contentPage.blockClient === undefined
                            ? "Não definido"
                            : contentPage.blockClient
                            ? "SIM"
                            : "NÃO"}
                        </option>
                      </select>
                    </div>

                    {contentPage.blockClient &&
                      selectStatus(Number(formikInternalData.values.status))
                        .finisher && (
                        <div className="field">
                          <label htmlFor="requesterName">
                            Cliente bloqueado no ERP
                          </label>
                          <select disabled>
                            <option value="">
                              {contentPage.blockClientAction === null ||
                              contentPage.blockClientAction === undefined
                                ? "Não definido"
                                : contentPage.blockClientAction
                                ? "SIM"
                                : "NÃO"}
                            </option>
                          </select>
                        </div>
                      )}
                  </div>

                  <div className="containerOBS">
                    <span> Observações sobre alterações na solicitação</span>

                    <div>
                      <ObsText>
                        <b>
                          <p>
                            Cliente realizou {contentPage.requestType} NFiscal.
                            Favor contatá-lo!
                          </p>
                        </b>
                      </ObsText>

                      {contentPage?.observation?.map((item, index) =>
                        item.authorType ? (
                          item.authorType === 2 && item.action ? (
                            <ObsText key={index}>
                              <VscTriangleRight color="#000" />

                              <p>
                                {item.authorType === 2 &&
                                  item.user &&
                                  item.user.name &&
                                  item.user.lastname &&
                                  `Operador ${item.user.name} ${
                                    item.user.lastname
                                  } ${item.actionDescription}  - ${new Date(
                                    item.created_at
                                  ).toLocaleDateString("pt-BR", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })}`}
                              </p>
                            </ObsText>
                          ) : item.status &&
                            item.status.name &&
                            item.authorType ? (
                            item.authorType === 1 ? (
                              <ObsText key={index}>
                                <VscTriangleRight color="#000" />
                                <p>
                                  {`Cliente  alterou status para ${
                                    item.status.name
                                  } - ${new Date(
                                    item.created_at
                                  ).toLocaleDateString("pt-BR", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })}`}
                                </p>
                              </ObsText>
                            ) : item.authorType === 2 &&
                              item.user &&
                              item.user.name &&
                              item.user.lastname ? (
                              <ObsText key={index}>
                                <VscTriangleRight color="#000" />
                                <p>
                                  {`Operador ${item.user.name} ${
                                    item.user.lastname
                                  } alterou status para ${item.status.name} : ${
                                    item.text
                                  } - ${new Date(
                                    item.created_at
                                  ).toLocaleDateString("pt-BR", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })}`}
                                </p>
                              </ObsText>
                            ) : item.authorType === 3 &&
                              item.seller &&
                              item.seller.fullName &&
                              item.seller.cod ? (
                              <ObsText key={index}>
                                <VscTriangleRight color="#000" />
                                <p>
                                  {`Representante ${item.seller.cod} ${
                                    item.seller.fullName
                                  } alterou status para ${item.status.name} : ${
                                    item.text
                                  } - ${new Date(
                                    item.created_at
                                  ).toLocaleDateString("pt-BR", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })}`}
                                </p>
                              </ObsText>
                            ) : item.authorType === 4 &&
                              item.seller &&
                              item.seller.fullName &&
                              item.seller.cod ? (
                              <ObsText key={index}>
                                <VscTriangleRight color="#000" />
                                <p>
                                  {`Gerente ${item.seller.cod} ${
                                    item.seller.fullName
                                  } alterou status para ${item.status.name} : ${
                                    item.text
                                  } - ${new Date(
                                    item.created_at
                                  ).toLocaleDateString("pt-BR", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })}`}
                                </p>
                              </ObsText>
                            ) : null
                          ) : null
                        ) : null
                      )}
                    </div>
                  </div>

                  <div className="field">
                    <label htmlFor="internalObservation">
                      Observação interna
                    </label>
                    <textarea
                      disabled={disabledInputs}
                      id="internalObservation"
                      {...formikInternalData.getFieldProps(
                        "internalObservation"
                      )}
                    ></textarea>
                  </div>

                  <h3>Dados da NF</h3>
                  <div className="field-group">
                    <div className="field">
                      <label htmlFor="numberInvoice">N° da NF</label>
                      <input
                        style={{ borderStyle: "dashed" }}
                        readOnly={true}
                        className="numberInvoice"
                        {...formikInternalData.getFieldProps("numberInvoice")}
                        disabled={disabledInputs}
                      />
                      {formikInternalData.touched.numberInvoice &&
                      formikInternalData.errors.numberInvoice ? (
                        <ErrorDiv>
                          <FiAlertCircle color="#f00" size={16} />
                          <span>{formikInternalData.errors.numberInvoice}</span>
                        </ErrorDiv>
                      ) : null}
                    </div>
                    <div className="field">
                      <label htmlFor="valueInvoice">Valor da NF / NFD </label>
                      <input
                        type="number"
                        className="valueInvoice"
                        {...formikInternalData.getFieldProps("valueInvoice")}
                        disabled={disabledInputs}
                      />
                      {formikInternalData.touched.valueInvoice &&
                      formikInternalData.errors.valueInvoice ? (
                        <ErrorDiv>
                          <FiAlertCircle color="#f00" size={16} />
                          <span>{formikInternalData.errors.valueInvoice}</span>
                        </ErrorDiv>
                      ) : null}
                    </div>
                  </div>

                  {!formikInternalData.values.refusal && !seller && (
                    <>
                      <h3>Dados do NFD</h3>
                      <div className="field-group">
                        <div className="field">
                          <label htmlFor="requesterName">N° da NFD</label>
                          <input
                            className="nfdNumber"
                            {...formikInternalData.getFieldProps("nfdNumber")}
                            disabled={disabledInputs}
                          />
                          {formikInternalData.touched.nfdNumber &&
                          formikInternalData.errors.nfdNumber ? (
                            <ErrorDiv>
                              <FiAlertCircle color="#f00" size={16} />
                              <span>{formikInternalData.errors.nfdNumber}</span>
                            </ErrorDiv>
                          ) : null}
                        </div>

                        <div className="field">
                          <label htmlFor="nfdDate">
                            Data de lançamento da NFD
                          </label>
                          <input
                            className="nfdDate"
                            type="date"
                            {...formikInternalData.getFieldProps("nfdDate")}
                            disabled={disabledInputs}
                          />
                          {formikInternalData.touched.nfdDate &&
                          formikInternalData.errors.nfdDate ? (
                            <ErrorDiv>
                              <FiAlertCircle color="#D0222D" size={16} />
                              <span> {formikInternalData.errors.nfdDate} </span>
                            </ErrorDiv>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}

                  <h3>Representante / Gestor</h3>
                  <div className="field-group">
                    <div className="field">
                      <label htmlFor="salesman">Representante</label>
                      <select
                        {...formikInternalData.getFieldProps("salesman")}
                        disabled={disabledInputs}
                      >
                        <option value="">Selecionar representante</option>
                        {salesman.map((item) => (
                          <option
                            key={item.id}
                            value={item.cod}
                          >{`${item.cod} ${item.abbreviation}`}</option>
                        ))}
                      </select>
                      {formikInternalData.touched.salesman &&
                      formikInternalData.errors.salesman ? (
                        <ErrorDiv>
                          <FiAlertCircle color="#D0222D" size={16} />
                          <span> {formikInternalData.errors.salesman} </span>
                        </ErrorDiv>
                      ) : null}
                    </div>

                    <div className="field">
                      <label htmlFor="brandManager">Gestor da Marca</label>
                      <select
                        {...formikInternalData.getFieldProps("brandManager")}
                        disabled={disabledInputs}
                      >
                        <option value="">Selecionar gestor da marca</option>
                        {brandManager.map((item) => (
                          <option
                            key={item.id}
                            value={item.cod}
                          >{`${item.cod} ${item.abbreviation}`}</option>
                        ))}
                      </select>
                      {formikInternalData.touched.brandManager &&
                      formikInternalData.errors.brandManager ? (
                        <ErrorDiv>
                          <FiAlertCircle color="#D0222D" size={16} />
                          <span>{formikInternalData.errors.brandManager} </span>
                        </ErrorDiv>
                      ) : null}
                    </div>
                  </div>

                  <h3>Extravio</h3>

                  <GroupInput>
                    <Input
                      {...formikInternalData.getFieldProps("lossNoteNumber")}
                      type="number"
                      label="Numero da nota de perda"
                      error={
                        formikInternalData.touched.lossNoteNumber &&
                        formikInternalData.errors.lossNoteNumber
                          ? formikInternalData.errors.lossNoteNumber
                          : undefined
                      }
                      step="any"
                    />
                    <Input
                      {...formikInternalData.getFieldProps(
                        "collectionNoteNumber"
                      )}
                      label="Numero da nota de cobrança"
                      error={
                        formikInternalData.touched.collectionNoteNumber &&
                        formikInternalData.errors.collectionNoteNumber
                          ? formikInternalData.errors.collectionNoteNumber
                          : undefined
                      }
                    />
                    <Input
                      {...formikInternalData.getFieldProps("collectionAmount")}
                      type="number"
                      label="Valor da cobrança"
                      step="any"
                      error={
                        formikInternalData.touched.collectionAmount &&
                        formikInternalData.errors.collectionAmount
                          ? formikInternalData.errors.collectionAmount
                          : undefined
                      }
                    />
                  </GroupInput>

                  {/* <h3>Transportadora</h3>
                  <div className="field-group">
                    <div className="field">
                      <label htmlFor="shippingCompany">transportadoras</label>
                      <select
                        {...formikInternalData.getFieldProps("shippingCompany")}
                        disabled={disabledInputs}
                      >
                        <option value="">Selecionar transportadora</option>
                        {shippingCompanies.map((item) => (
                          <option
                            key={item.id}
                            value={item.id}
                          >{`${item.cod} ${item.description}`}</option>
                        ))}
                      </select>
                      {formikInternalData.touched.shippingCompany &&
                      formikInternalData.errors.shippingCompany ? (
                        <ErrorDiv>
                          <FiAlertCircle color="#D0222D" size={16} />
                          <span>
                            {" "}
                            {formikInternalData.errors.shippingCompany}{" "}
                          </span>
                        </ErrorDiv>
                      ) : null}
                    </div>
                  </div> */}

                  {!formikInternalData.values.refusal &&
                    formikInternalData.values.subReason &&
                    !seller && (
                      <>
                        <h3>Transporte</h3>
                        <div className="field">
                          <label htmlFor="name">Responsável pelo frete</label>

                          {internalReasons &&
                            formikInternalData.values.subReason &&
                            responsibleFreight && (
                              <ContainerCheck>
                                {responsibleFreight &&
                                  responsibleFreight.map((element) => (
                                    <button
                                      key={element.id}
                                      type="button"
                                      className={
                                        formikInternalData.values
                                          .freightBrand === element.name
                                          ? "check"
                                          : ""
                                      }
                                      onClick={() => {
                                        formikInternalData.setFieldValue(
                                          "freightBrand",
                                          element.name
                                        );

                                        formikInternalData.setFieldValue(
                                          "freightPenalty",
                                          element.penalty
                                        );

                                        if (!element.penalty) {
                                          formikInternalData.setFieldValue(
                                            "freightFine",
                                            0
                                          );
                                        }
                                      }}
                                    >
                                      {element.description}
                                    </button>
                                  ))}
                              </ContainerCheck>
                            )}

                          {formikInternalData.touched.freightBrand &&
                          formikInternalData.errors.freightBrand ? (
                            <ErrorDiv>
                              <FiAlertCircle color="#D0222D" size={16} />
                              <span>
                                {formikInternalData.errors.freightBrand}
                              </span>
                            </ErrorDiv>
                          ) : null}
                        </div>

                        {formikInternalData.values.freightPenalty && (
                          <div className="field">
                            <label htmlFor="freightFine">Multa</label>
                            <input
                              type="number"
                              {...formikInternalData.getFieldProps(
                                "freightFine"
                              )}
                              disabled={disabledInputs}
                            />
                            {formikInternalData.touched.freightFine &&
                            formikInternalData.errors.freightFine ? (
                              <ErrorDiv>
                                <FiAlertCircle color="#D0222D" size={16} />
                                <span>
                                  {formikInternalData.errors.freightFine}
                                </span>
                              </ErrorDiv>
                            ) : null}
                          </div>
                        )}

                        <div className="field-group">
                          <div className="field">
                            <label htmlFor="freightValue">
                              Valor do frete (ida+volta)
                            </label>
                            <input
                              type="number"
                              {...formikInternalData.getFieldProps(
                                "freightValue"
                              )}
                              disabled={disabledInputs}
                            />
                            {formikInternalData.touched.freightValue &&
                            formikInternalData.errors.freightValue ? (
                              <ErrorDiv>
                                <FiAlertCircle color="#D0222D" size={16} />
                                <span>
                                  {formikInternalData.errors.freightValue}
                                </span>
                              </ErrorDiv>
                            ) : null}
                          </div>
                        </div>
                      </>
                    )}

                  {isDateInternal() && (
                    <div className="containerButton">
                      <ButtunSubmit
                        type="submit"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {loadingSubmit ? (
                          <Loading
                            size={22}
                            borderSize={4}
                            colorLoading="rgba(255,255,255)"
                            borderColor="rgba(255,255,255, 0.3)"
                          />
                        ) : (
                          "Salvar alterações"
                        )}
                      </ButtunSubmit>
                    </div>
                  )}
                </Form>
              </div>
            </InfoDevolution>
          )}
          <ContainerGroup
            style={{ flexDirection: client ? undefined : "column" }}
          >
            <ContainerGroup>
              {!client && !provider && (
                <InfoDevolution style={{ boxShadow: "none" }}>
                  <form onSubmit={hadleSubitChatInternal}>
                    <div className="headerDevoltion">
                      <h2>Mensagens internas</h2>
                    </div>

                    <div className="bodyChat">
                      {chatInternal.map((item, i) => {
                        let messageDate = new Date(item.created_at as Date);

                        // "admin" | "seller" | "seller_manager"

                        return (
                          <div key={i} className={selectAuthor(item.typeUser)}>
                            <div className="containerchat">
                              <div className="containerMessage">
                                <header>
                                  <span>{item.name}</span>
                                </header>
                                <p>{item.textMessage}</p>
                              </div>
                              <footer>
                                {moment(messageDate).format(
                                  " hh:mm A - DD/MM/YYYY"
                                )}
                                {` - `}
                                {item.typeUser === "client"
                                  ? "Cliente"
                                  : item.typeUser === "admin"
                                  ? "Operador"
                                  : item.typeUser === "seller_manager"
                                  ? "Gerente"
                                  : "Representante"}
                              </footer>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {((permission &&
                      permission.devolution &&
                      permission.devolution > 2) ||
                      seller) && (
                      <footer>
                        <div>
                          <textarea
                            placeholder="Digite uma nova mensagem"
                            defaultValue={textChatInternal}
                            ref={messageInternalRef}
                            onBlur={(e) => {
                              setTextChatInternal(e.target.value);
                            }}
                            typeof="text"
                          />
                          <button type="submit">
                            <IoMdSend size={24} color="#fff" />
                          </button>
                        </div>
                      </footer>
                    )}
                  </form>
                </InfoDevolution>
              )}

              {!provider && (
                <InfoDevolution style={{ boxShadow: "none" }}>
                  <form onSubmit={hadleSubitChat}>
                    <div className="headerDevoltion">
                      <h2>Mensagens {!client && "com cliente"}</h2>
                    </div>

                    <div className="bodyChat">
                      {chat.map((item, i) => {
                        let messageDate = new Date(item.created_at as Date);

                        return (
                          <div
                            key={i}
                            className={
                              admin || seller
                                ? item.typeUser === "client"
                                  ? "to"
                                  : "i"
                                : item.typeUser === "client"
                                ? "i"
                                : "to"
                            }
                          >
                            <div className="containerchat">
                              <div className="containerMessage">
                                <header>
                                  <span>{item.name}</span>
                                </header>
                                <p>{item.textMessage}</p>
                              </div>
                              <footer>
                                {moment(messageDate).format(
                                  "hh:mm A - DD/MM/YYYY"
                                )}
                                {` - `}
                                {item.typeUser === "client"
                                  ? "Cliente"
                                  : item.typeUser === "admin"
                                  ? "Operador"
                                  : item.typeUser === "seller_manager"
                                  ? "Gerente"
                                  : "Representante"}
                              </footer>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {(isDateInternal() || client) && (
                      <footer>
                        <div>
                          <textarea
                            placeholder="Digite uma nova mensagem"
                            ref={messageRef}
                            onBlur={(e) => setTextChat(e.target.value)}
                            defaultValue={textChat}
                            typeof="text"
                          />
                          <button type="submit">
                            <IoMdSend size={24} color="#fff" />
                          </button>
                        </div>
                      </footer>
                    )}
                  </form>
                </InfoDevolution>
              )}
            </ContainerGroup>

            {(isDateInternal() || client) && (
              <InfoDevolution style={{ boxShadow: "none" }}>
                <div className="headerDevoltion">
                  <h2>Anexar Arquivos</h2>
                </div>
                <div className="bodyDevoltion">
                  <ContainerDropZone>
                    <Dropzone
                      accept={[
                        "image/*",
                        "application/pdf",
                        "application/xml",
                        "text/xml",
                      ]}
                      onFileUploaded={(e) => setSelectFile(e)}
                    />
                    <ContainerPreviews>
                      {files.map((file) => (
                        <li key={`${generateHash()}-${file.name}`}>
                          <img
                            src={
                              file.type === "application/pdf"
                                ? iconPdf
                                : ["text/xml", "application/xml"].includes(
                                    file.type
                                  )
                                ? iconXml
                                : URL.createObjectURL(file)
                            }
                            alt=""
                          />
                          <div className="fileInfo">
                            <div>
                              <strong>{file.name}</strong>
                              <span>{filesize(file.size)}</span>
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                hadleDeleteFile(file.name);
                              }}
                            >
                              Excluir
                            </button>
                          </div>
                        </li>
                      ))}
                    </ContainerPreviews>
                  </ContainerDropZone>

                  <ButtunSubmit onClick={hadleSubmitFiles}>
                    Enviar anexos
                  </ButtunSubmit>
                </div>
              </InfoDevolution>
            )}
          </ContainerGroup>

          {(seller && activeSeller()) ||
          (admin &&
            ![26, 10].includes(Number(contentPage?.brandCod)) &&
            contentPage?.status?.approval &&
            +contentPage.status.responsable_permission_id ===
              Number(userStorage.permission)) ? (
            <>
              <FloatButton
                Icon={BsFileEarmarkCheck}
                title="Alterar status"
                notFloatButton={true}
              >
                <li
                  onClick={() => {
                    setModalConfirm(true);
                    setConfirmData({
                      color: "#41C57C",
                      name: "Reentregar",
                    });
                  }}
                >
                  <span>Reentregar </span>
                  <div
                    style={{
                      background: "#41C57C",
                    }}
                  >
                    <FaCheck color="#fff" size={18} />
                  </div>
                </li>

                <li
                  onClick={() => {
                    setModalConfirm(true);
                    setConfirmData({ name: "Aceitar", color: "#F05978" });
                  }}
                >
                  <span>Aceitar devolução/recusa</span>
                  <div
                    style={{
                      background: "#F05978",
                    }}
                  >
                    <IoClose color="#fff" size={28} />
                  </div>
                </li>
              </FloatButton>

              <div style={{ height: 100 }} />

              {modalConfirm && confirmData && (
                <ModalConfirm
                  modalVisible={modalConfirm}
                  setModalVisible={setModalConfirm}
                  headerOff
                  maxHeight={"95%"}
                  height={
                    confirmData.name === "Aceitar" &&
                    (typeUser === "manager" || typeUser === "user")
                      ? 700
                      : 440
                  }
                  maxWidth={650}
                  scrollOff
                >
                  <PopUpConfirmation>
                    <div className="headerPopUp">
                      <nav>
                        <BsFileEarmarkCheck size={100} opacity={0.2} />
                      </nav>
                      <span>
                        Você tem certeza que deseja {confirmData.name} a
                        devolução/recusa protocolo "{contentPage.id}"
                      </span>
                    </div>

                    {confirmData.name === "Aceitar" &&
                      (typeUser === "manager" || typeUser === "user") && (
                        <>
                          <div className="field" style={{ padding: "0 50px" }}>
                            <label htmlFor="name">
                              MULTAR REPRESENTANTE <span>*</span>
                            </label>

                            <select
                              style={{
                                border: errorBlockClient
                                  ? "2px solid #d21e26"
                                  : undefined,
                              }}
                              value={
                                penaltySeller === true
                                  ? 1
                                  : penaltySeller === false
                                  ? 0
                                  : undefined
                              }
                              onChange={(e) => {
                                if (
                                  seller ||
                                  (admin &&
                                    permission &&
                                    permission.is_distribution)
                                ) {
                                  setBlockClient(
                                    Number(e.target.value) >= 1 ? false : true
                                  );
                                }
                                setPenaltySeller(
                                  e.target.value
                                    ? Number(e.target.value) >= 1
                                      ? true
                                      : false
                                    : undefined
                                );
                              }}
                              onClick={() => {
                                setErrorBlockClient("");
                              }}
                            >
                              {blockClient === undefined && (
                                <option value={undefined}>Selecionar...</option>
                              )}
                              <option value={0}>NÃO</option>
                              <option value={1}>SIM</option>
                            </select>

                            {errorBlockClient ? (
                              <div className="alertIcon">
                                <AiFillCloseCircle />
                              </div>
                            ) : null}
                          </div>

                          {admin &&
                          permission &&
                          permission.is_validationSalesType &&
                          permission?.is_licensed ? (
                            <div
                              className="field-group"
                              style={{
                                flexDirection: "row",
                                flexWrap: "nowrap",
                              }}
                            >
                              <div
                                className="field"
                                style={{ marginBottom: 0 }}
                              >
                                <label htmlFor="name">
                                  FRETE PARA CLIENTE <span>*</span>
                                </label>

                                <select
                                  onChange={(e) => {
                                    setBlockClient(
                                      e.target.value
                                        ? Number(e.target.value) >= 1
                                          ? true
                                          : false
                                        : undefined
                                    );
                                  }}
                                  value={
                                    blockClient === true
                                      ? 1
                                      : blockClient === false
                                      ? 0
                                      : undefined
                                  }
                                >
                                  {penaltySeller === undefined && (
                                    <option value={undefined}>
                                      Selecionar...
                                    </option>
                                  )}
                                  <option value={0}>NÃO</option>
                                  <option value={1}>SIM</option>
                                </select>
                              </div>

                              <div
                                className="field"
                                style={{ marginBottom: 0 }}
                              >
                                <label htmlFor="name">
                                  BLOQUEAR CLIENTE <span>*</span>
                                </label>

                                <select
                                  disabled={true}
                                  style={{
                                    borderStyle: "dashed",
                                  }}
                                >
                                  {blockClient === undefined ? (
                                    <option value={undefined}></option>
                                  ) : (
                                    <option value={undefined}>
                                      {blockClient ? "SIM" : "NÃO"}
                                    </option>
                                  )}
                                </select>

                                <ObsSpan>
                                  * Bloqueio por tempo indefinido e carteira do
                                  cliente dos próximos 6 meses será cancelada
                                </ObsSpan>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="field-group"
                              style={{
                                flexDirection: "row",
                                flexWrap: "nowrap",
                              }}
                            >
                              <div
                                className="field"
                                style={{ marginBottom: 0 }}
                              >
                                <label htmlFor="name">
                                  FRETE PARA CLIENTE <span>*</span>
                                </label>

                                <select
                                  disabled={true}
                                  style={{
                                    borderStyle: "dashed",
                                  }}
                                >
                                  {blockClient === undefined ? (
                                    <option value={undefined}></option>
                                  ) : (
                                    <option value={undefined}>
                                      {blockClient ? "SIM" : "NÃO"}
                                    </option>
                                  )}
                                </select>
                              </div>

                              <div
                                className="field"
                                style={{ marginBottom: 0 }}
                              >
                                <label htmlFor="name">
                                  BLOQUEAR CLIENTE <span>*</span>
                                </label>

                                <select
                                  disabled={true}
                                  style={{
                                    borderStyle: "dashed",
                                  }}
                                >
                                  {blockClient === undefined ? (
                                    <option value={undefined}></option>
                                  ) : (
                                    <option value={undefined}>
                                      {blockClient ? "SIM" : "NÃO"}
                                    </option>
                                  )}
                                </select>

                                <ObsSpan>
                                  * Bloqueio por tempo indefinido e carteira do
                                  cliente dos próximos 6 meses será cancelada
                                </ObsSpan>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                    <div className="field" style={{ padding: "0 50px" }}>
                      <label htmlFor="name">
                        OBSERVAÇÃO <span>*</span>
                      </label>
                      <textarea
                        style={{
                          resize: "none",
                          border: errorObservation
                            ? "2px solid #d21e26"
                            : undefined,
                        }}
                        defaultValue={observation}
                        onBlur={(e) => {
                          setObservation(e.target.value);
                        }}
                        onClick={() => {
                          setErrorObservation("");
                        }}
                      />

                      {errorObservation ? (
                        <div className="alertIcon">
                          <AiFillCloseCircle />
                        </div>
                      ) : null}
                    </div>

                    <div
                      className="buttonsPopUp"
                      style={{ padding: "15px 10px" }}
                    >
                      <button
                        type="button"
                        onClick={() => setModalConfirm(false)}
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        style={{
                          background: confirmData.color,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          blockClient && typeUser === "user"
                            ? handleActiveConfirmationBlockClient()
                            : handleSubmitConfirm();
                        }}
                      >
                        {!loadingModal ? (
                          confirmData.name
                        ) : (
                          <Loading
                            size={22}
                            borderSize={4}
                            colorLoading="rgba(255,255,255)"
                            borderColor="rgba(255,255,255, 0.3)"
                          />
                        )}
                      </button>
                    </div>
                  </PopUpConfirmation>
                </ModalConfirm>
              )}

              {modalAlertBlockClient && (
                <ModalConfirm
                  modalVisible={modalAlertBlockClient}
                  setModalVisible={setModalAlertBlockClient}
                  headerOff={true}
                  maxHeight={350}
                  maxWidth={750}
                  scrollOff
                >
                  <PopUpConfirmation>
                    <div className="headerPopUp">
                      <nav>
                        <RiUserUnfollowLine size={95} opacity={0.2} />
                      </nav>
                      <span>
                        Você tem certeza que deseja bloquear o CLIENTE por tempo
                        indefinido e cancelar a carteira do cliente dos próximos
                        6 meses.
                      </span>
                    </div>
                    <div className="buttonsPopUp">
                      <button
                        type="button"
                        onClick={() => setModalAlertBlockClient(false)}
                      >
                        Cancelar
                      </button>
                      <button
                        style={{ background: "#F05978" }}
                        type="button"
                        onClick={() => handleSubmitConfirm()}
                      >
                        {!loadingModal ? (
                          "Finalizar"
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Loading
                              size={22}
                              borderSize={4}
                              colorLoading="rgba(255,255,255)"
                              borderColor="rgba(255,255,255, 0.3)"
                            />
                          </div>
                        )}
                      </button>
                    </div>
                  </PopUpConfirmation>
                </ModalConfirm>
              )}
            </>
          ) : null}

          {isActionBlockClient() && (
            <>
              <FloatButton
                Icon={BsFileEarmarkCheck}
                title="Alterar status"
                notFloatButton={true}
              >
                <li
                  onClick={() => {
                    setConfirmeBlockClientAction(true);
                  }}
                >
                  <span>Cliente Bloqueado ERP </span>
                  <div
                    style={{
                      background: "#d8bf3e",
                    }}
                  >
                    <FaUserLock color="#fff" size={18} />
                  </div>
                </li>
              </FloatButton>

              <div style={{ height: 100 }} />

              {confirmeBlockClientAction && (
                <ModalConfirm
                  title="Selecione o cliente"
                  modalVisible={confirmeBlockClientAction}
                  setModalVisible={setConfirmeBlockClientAction}
                  headerOff={true}
                  scrollOff={true}
                  maxHeight={350}
                  maxWidth={650}
                >
                  <PopUpConfirmation>
                    <div className="headerPopUp">
                      <nav>
                        <FaUserLock size={95} opacity={0.2} />
                      </nav>
                      <span>
                        Você confirma que realizou o bloqueio do cliente no ERP?
                        Após o confirmação não poderá ser alterado novamente.
                      </span>
                    </div>
                    <div className="buttonsPopUp">
                      <button
                        type="button"
                        onClick={() => setConfirmeBlockClientAction(false)}
                      >
                        Cancelar
                      </button>
                      <button type="button" onClick={handleActionBlockClient}>
                        Confirmar
                      </button>
                    </div>
                  </PopUpConfirmation>
                </ModalConfirm>
              )}
            </>
          )}

          <ModalConfirm
            title="Devolução"
            modalVisible={activeUpdateDevolutionModal}
            setModalVisible={setActiveUpdateDevolutionModal}
            maxWidth={750}
            scrollOff={true}
            maxHeight={500}
          >
            <ModalModificationDevolution
              inputSelect={{
                brandManagers: brandManager,
                salesmans: salesman,
                brands: brands,
              }}
              type="update"
              data={{
                id: Number(contentPage.id) ?? 0,
                brand: contentPage.brandCod,
                salesman: contentPage.salesman,
                brandManager: contentPage.brandManager,
                numberInvoice: contentPage.numberInvoice,
                situationRequest: contentPage.situationRequest,
              }}
              onRequestUpdate={() => {
                setUpdate(update + 1);
              }}
              onRequestClose={() => {
                setActiveUpdateDevolutionModal(false);
              }}
            />
          </ModalConfirm>
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default Devolution;
