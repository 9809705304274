import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaFileDownload, FaFileExcel, FaThList } from "react-icons/fa";
import { FcClock } from "react-icons/fc";
import { FiDownload } from "react-icons/fi";
import { HiBadgeCheck } from "react-icons/hi";
import { MdOutlineChecklist, MdOutlineDownloading } from "react-icons/md";
import { RiAlertFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { IPermissionUser, IUser } from "../../../@types";
import icon_xlsx from "../../../assets/icon_excel.png";
import icon_pdf from "../../../assets/icon_pdf.png";
import icon_xml from "../../../assets/icon_xml.png";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { InputCheckboxV2 } from "../../../components/Form/InputCheckboxV2";
import { Select } from "../../../components/Form/Select";
import { InfoCards } from "../../../components/InfoCards";
import { Image } from "../../../components/OcItem/styles";
import { OcItemGrid } from "../../../components/OcItemGrid";
import { Popover } from "../../../components/Popover";
import Loading from "../../../components/loadings/Loading";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { Pagination } from "../../../components/panel/table/Pagination";
import {
  FieldProps,
  TableContent,
} from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import { NOIMAGE_LINK, SPACE_LINK } from "../../../global/variables";
import api from "../../../service/api";
import * as UserStorage from "../../../service/localStorage/user";
import { arraysHaveSameElements } from "../../../utils/arraysHaveSameElements";
import { downloadFilaBase64 } from "../../../utils/downloadFilaBase64";
import { generateXlsx } from "../../../utils/generateXlsx";
import {
  ButtunSubmit,
  ContainerCheck,
  Form,
  InfoDevolution,
  ScreenLoading,
} from "../styles";

interface HomeProps {
  match: {
    params: {
      id: number;
    };
  };

  provider: boolean;
  admin: boolean;
  client: boolean;
  permission: IPermissionUser;
}

interface IFile {
  id: string;
  name: string;
  size: Number;
  key?: string;
  mimetype?: string;
}

export interface IScheduling {
  id: number;
  name: string;
  observation: string;
  type: string;
  is_valid: boolean;
  is_valid_oc: boolean;
  volume: number;

  apparel: number | null;
  equipment: number | null;
  footwear: number | null;
  start_date: Date;
  end_date: Date;
  closing_at: Date;
  created_at: Date;

  brand: {
    cod: number;
    name: string;
    is_licensed: boolean;
  };

  status: IStatusScheduling;

  errors: {
    field: string;
    reason: string;
  }[];

  provider: {
    id: number;
    fullname: string;
    phone: string;

    brands: {
      cod: number;
      name: string;
    }[];
    acess_provider: {
      email: string;
    };
  };

  logs: {
    id: number;
    created_at: Date;
    provider?: {
      fullname: string;
    };
    status?: {
      name: string;
    };
    user?: {
      name: string;
      lastname: string;
    };
  }[];

  files: IFile;
  ocs: OcScheduling[];
}

interface OcSchedulingError {
  reason: string;
  field: string;
}

export interface OcScheduling {
  id: string;
  errors?: OcSchedulingError[];
  isValid: boolean | null;
  code?: number;
  ocItem?: number;
  reference?: string;
  valueTotal?: number;
  invoiceNumber?: number;
  productCod?: number;
  paymentDays?: number;
  productIdProvider: string;
  qtd?: number;
  ncm?: string;
  origem?: string;
  file?: IFile;
  items: OcItemScheduling[];
  productDescription: string;
  productGroup: string;
  collection: string;
  dtBilling?: Date;
  dtDelivery?: Date;

  oc?: {
    ncm: string;
    origem: string;
    qtd: number;
    valueTotal: number;
    productReplacementPrice: number;
    productSellingPrice: number;
  };
}

export interface OcItemScheduling {
  id: string;
  qtd?: number;
  ean?: string;
  size?: string;
  value?: number;
}

export interface IStatusScheduling {
  id?: number;
  cod: number;
  name: string;
  color: string;
  user_id: number;
  responsable_id: number;
  finisher: boolean;
  is_active: boolean;
  is_observation: boolean;
  is_valid: boolean;
}

const Scheduling: React.FC<HomeProps> = ({ admin, provider, match }) => {
  const UserData = UserStorage.getUser() as IUser;
  const { addToast } = useToasts();
  const history = useHistory();

  const [contentPage, setContentPage] = useState<IScheduling>();

  const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<IStatusScheduling[]>([]);
  const [listFiles, setListFiles] = useState<IFile[]>([]);
  const [isUpdate, setIsUpdate] = useState(1);

  const [filters, setFilters] = useState<{
    reference?: string;
    invoiceNumber?: string;
    productCod?: string;
    isValid?: string;
    errors?: string[];
  }>({ reference: undefined, isValid: undefined });
  const [page, setPage] = useState<number>(1);
  const [modalOcItemGrid, setModalOcItemGrid] = useState<OcScheduling>();
  const [viewFilter, setViewFilter] = useState<boolean>(false);
  const [isLoadingOc, setIsLoadingOc] = useState<boolean>(false);

  const formikInternalData = useFormik({
    initialValues: {
      status: undefined,
      observation: undefined,
    },

    validationSchema: Yup.object({}),

    onSubmit: async (data) => {
      await handleSubmit(data);
    },
  });

  const formikFilter = useFormik({
    initialValues: {
      reference: undefined,
      invoiceNumber: undefined,
      productCod: undefined,
      isValid: undefined,
      errors: [],
    },
    validationSchema: Yup.object({}),
    onSubmit: (data) => {
      handleFilter(data);
    },
  });

  async function handleDownloadFile({
    key,
    filename,
    mimetype,
  }: {
    key: string;
    filename: string;
    mimetype: string;
  }) {
    addToast(`Gerando arquivo...`, {
      appearance: "info",
      autoDismiss: true,
      autoDismissTimeout: 10000,
    });

    setLoadingScreen(true);

    try {
      const getFileBase64 = await api.post(`scheduling/getFileBase64/`, {
        key: key,
      });

      downloadFilaBase64({
        base64: getFileBase64.data ?? "",
        filename: filename,
        mimetype: mimetype ?? "",
      });

      setLoadingScreen(false);
    } catch (error) {
      setLoadingScreen(false);

      return addToast(
        "Ocorreu um erro ao gerar arquivo, Tente novamente mais tarde ou entre em contato conosco (51) 3441-1000",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  function selectStatus(
    cod: number | undefined
  ): undefined | IStatusScheduling {
    var response: undefined | IStatusScheduling;

    if (cod && status) {
      for (const item of status) {
        if (item.cod === cod) {
          response = item;
        }
      }
    }

    return response;
  }

  async function handleSubmit(data: any) {
    try {
      var dataUpdate = {
        status: data.status,
        type: data.type,
        observation: data.observation,
      };

      if (contentPage) {
        await api.put(`/scheduling/${contentPage.id}`, dataUpdate);
        addToast("Status alterado com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push("/admin/agendamentos");
      }
    } catch (error) {}
  }

  async function handleExport(type: number) {
    const ocs = contentPage?.ocs ?? [];

    switch (type) {
      case 1:
        generateXlsx({
          data: ocs.map((oc) => ({
            Situação:
              oc.isValid === null
                ? "Pendente"
                : oc.isValid === true
                ? "Validado"
                : "Invalidado",
            "Pedido produto": oc?.productIdProvider ?? "-",
            "Coleção Alpar": oc?.collection ?? "-",
            "Cód.Produto": oc?.productCod,
            Referência: oc?.reference,
            Descrição: oc?.productDescription ?? "-",
            "Grupo de produtos": oc?.productGroup ?? "-",
            OC: oc?.code,
            Item: oc?.ocItem,
            "Mês entrega OC": oc?.dtDelivery
              ? new Date(oc?.dtDelivery).toLocaleDateString("pt-BR", {
                  month: "long",
                })
              : "-",
            Quantidade: oc?.qtd,
            "Quantidade OC": oc?.oc?.qtd,
            Total: oc?.valueTotal,
            "Valor unitário":
              oc?.qtd && oc?.valueTotal ? oc?.valueTotal / oc?.qtd : "-",
            "Vlr unit. OC":
              oc?.oc?.qtd && oc?.oc?.valueTotal
                ? oc?.oc?.valueTotal / oc?.oc?.qtd
                : "-",
            PDV: oc?.oc?.productReplacementPrice,
            "Preço de venda": oc?.oc?.productSellingPrice,
            NCM: oc?.ncm,
            "NCM cadastro": oc?.oc?.ncm,
            Origem: oc?.origem,
            "Origem cadastro": oc?.oc?.origem,
            NF: oc?.invoiceNumber,
            "Vencimento OC": oc.paymentDays,
            "Dt.Faturamento": moment(oc?.dtBilling).format("DD/MM/YYYY"),
            "Dt.Agenda": moment(contentPage?.start_date).format("DD/MM/YYYY"),
            "Dt. Fat. entre dt. Agend.":
              moment(contentPage?.start_date).diff(oc?.dtBilling, "days") ??
              "-",
            Motivo: oc?.errors?.map((item) => item.reason)?.join(","),
            Arquivo: oc?.file?.name,
          })),
          filename: "Itens OC",
        });
        break;

      case 2:
        let data = [];

        for (const oc of ocs) {
          for (const item of oc.items) {
            data.push({
              Situação:
                oc.isValid === null
                  ? "Pendente"
                  : oc.isValid === true
                  ? "Validado"
                  : "Invalidado",
              "Pedido produto": oc?.productIdProvider ?? "-",
              "Coleção Alpar": oc?.collection ?? "-",
              "Cód.Produto": oc?.productCod,
              Referência: oc?.reference,
              Descrição: oc?.productDescription ?? "-",
              "Grupo de produtos": oc?.productGroup ?? "-",
              OC: oc?.code,
              Item: oc?.ocItem,
              "Mês entrega OC": oc?.dtDelivery
                ? new Date(oc?.dtDelivery).toLocaleDateString("pt-BR", {
                    month: "long",
                  })
                : "-",
              Total: oc?.valueTotal,
              "Valor unitário":
                oc?.qtd && oc?.valueTotal ? oc?.valueTotal / oc?.qtd : "-",
              Tamanho: item.size,
              "Quantidade do tamanho": item.qtd,
              EAN: item.ean,
              NCM: oc?.ncm,
              Origem: oc?.origem,
              NF: oc?.invoiceNumber,
              "Dt.Faturamento": moment(oc?.dtBilling).format("DD/MM/YYYY"),
              "Dt.Agenda": moment(contentPage?.start_date).format("DD/MM/YYYY"),
              "Dt. Fat. entre dt. Agend.":
                moment(contentPage?.start_date).diff(oc?.dtBilling, "days") ??
                "-",
              Motivo: oc?.errors?.map((item) => item.reason)?.join(","),
              Arquivo: oc?.file?.name,
            });
          }
        }

        generateXlsx({
          data: data,
          filename: "Itens OC",
        });
        break;
    }
  }

  async function handleValidOC() {
    if (!contentPage) {
      return addToast(
        "Ocorreu um erro ao gerar arquivo, Tente novamente mais tarde ou entre em contato conosco (51) 3441-1000",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
    setLoading(true);
    try {
      await api.post(`/scheduling/valid-oc/${contentPage.id}`);

      setIsUpdate(isUpdate + 1);

      return addToast("Revalidação concluída", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      return addToast(
        "Ocorreu um erro ao gerar arquivo, Tente novamente mais tarde ou entre em contato conosco (51) 3441-1000",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleRereadingOC() {
    if (!contentPage) {
      return addToast(
        "Ocorreu um erro ao gerar arquivo, Tente novamente mais tarde ou entre em contato conosco (51) 3441-1000",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }

    try {
      await api.post(`/scheduling/rereading/${contentPage.id}`);

      return addToast(
        "Revalidação em andamento, isto pode levar um tempo em torno de 10 minutos. ",
        {
          appearance: "info",
          autoDismiss: true,
        }
      );
    } catch (error) {
      return addToast(
        "Ocorreu um erro ao gerar arquivo, Tente novamente mais tarde ou entre em contato conosco (51) 3441-1000",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  function handleFilter(data: any) {
    setViewFilter(false);
    setPage(1);

    setIsLoadingOc(true);
    const timer = setTimeout(() => {
      setFilters({
        ...data,
        errors: data.errors.length > 0 ? data.errors : undefined,
      });
      setIsLoadingOc(false);
    }, 500); // Simulate a fetch delay

    return () => clearTimeout(timer);
  }

  const columnsOc: FieldProps<OcScheduling>[] = [
    {
      name: "icon",
      field: "Situação",
      viewList: true,
      render: (item) => {
        return (
          <div style={{ textAlign: "center" }}>
            {item.isValid === null ? (
              <FcClock fontSize={"2rem"} />
            ) : item.isValid === true ? (
              <HiBadgeCheck fontSize={"2rem"} color="#21b543" />
            ) : (
              <RiAlertFill fontSize={"2rem"} color="#ec8c0d" />
            )}
          </div>
        );
      },
    },
    {
      name: "image",
      field: "Imagem",
      viewList: true,
      render: (item) => {
        const uri = `${SPACE_LINK}/Produtos/${item?.reference}_01_smaller`;

        return (
          <Image
            effect="blur"
            loading="lazy"
            style={{ maxWidth: "120px" }}
            height="100px"
            src={uri}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = NOIMAGE_LINK;
            }}
            alt={item.reference}
          />
        );
      },
    },
    {
      name: "reference",
      field: "Referência",
      viewList: true,
    },
    {
      name: "invoiceNumber",
      field: "NF",
      viewList: true,
    },
    {
      name: "productCod",
      field: "Cód.Prod.",
      viewList: true,
    },
    {
      name: "code",
      field: "OC",
      viewList: true,
    },
    {
      name: "ocItem",
      field: "Item",
      viewList: true,
    },
    {
      name: "qtd",
      field: "Qtd",
      viewList: true,
    },
    {
      name: "origem",
      field: "Orig.",
      viewList: true,
    },
    {
      name: "ncm",
      field: "Ncm",
      viewList: true,
    },
    {
      name: "totalFormat",
      field: "Total",
      viewList: true,
    },
    {
      name: "reason",
      field: "Motivo",
      viewList: true,
    },
    {
      name: "errors",
      field: "Motivos",
      viewList: false,
    },
    {
      name: "actions",
      field: "Ação",
      viewList: true,
      render: (item) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <button
              onClick={() => {
                setModalOcItemGrid(item);
              }}
              style={{
                background: "transparent",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#333",
                }}
              >
                <span style={{ marginBottom: "0.5rem", fontSize: "0.785rem" }}>
                  Grade
                </span>
                <FaThList fontSize={26} />
              </div>
            </button>
            <button
              onClick={() => {
                handleDownloadFile({
                  filename: item.file?.name ?? "",
                  key: item.file?.key ?? "",
                  mimetype: item.file?.mimetype ?? "",
                });
              }}
              style={{
                background: "transparent",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginLeft: "1rem",
                  color: "#333",
                }}
              >
                <span style={{ marginBottom: "0.5rem", fontSize: "0.785rem" }}>
                  Download
                </span>
                <FaFileDownload fontSize={26} />
              </div>
            </button>
          </div>
        );
      },
    },
    {
      name: "isValid",
      field: "Situação",
      viewList: false,
    },
  ];

  useEffect(() => {
    if (admin) {
      (async () => {
        const statusDB = await api.get<IStatusScheduling[]>(
          "/populate/scheduling/status",
          {
            params: { active: 1, user: true },
          }
        );

        setStatus(statusDB.data);
      })();
    }
  }, [admin]);

  useEffect(() => {
    setLoading(true);

    (async () => {
      if (!!match.params.id) {
        try {
          const response = await api.get<IScheduling>(
            `/scheduling/show/${match.params.id}`
          );

          if (!Boolean(response.data)) {
            return history.push("/404");
          }

          setContentPage(response.data);
        } catch (error) {
          return history.push("/404");
        } finally {
          setLoading(false);
        }
      }
    })();

    // eslint-disable-next-line
  }, [match?.params?.id, isUpdate]);

  useEffect(() => {
    (async () => {
      if (contentPage) {
        const filesDB = await api.get(`/scheduling/files/${contentPage.id}`);

        setListFiles(filesDB.data);
      }
    })();
  }, [contentPage]);

  useEffect(() => {
    if (contentPage && contentPage.status)
      formikInternalData.setFieldValue("status", contentPage.status.cod);
    // eslint-disable-next-line
  }, [contentPage]);

  useEffect(() => {
    setIsLoadingOc(true);
    const timer = setTimeout(() => {
      setIsLoadingOc(false);
    }, 500); // Simulate a fetch delay

    return () => clearTimeout(timer);
  }, [page]);

  return (
    <>
      <CreateAndDetailLayout isLoading={loading}>
        <PanelAndDetailAndCreateHeader title="Detalhes do Agendamento" goBack>
          {!provider && [2, 10].includes(Number(contentPage?.brand.cod)) && (
            <div className="containerActions">
              <button
                className="buttonActionDatasheet"
                style={{
                  background: "#518de6",
                  color: "white",
                  fontSize: "0.765rem",
                }}
                onClick={() => handleRereadingOC()}
              >
                <div>
                  <span>
                    <MdOutlineDownloading size={19} />
                  </span>
                  RELEITURA OCS
                </div>
              </button>
            </div>
          )}
        </PanelAndDetailAndCreateHeader>

        <InfoCards
          cards={[
            {
              title: "STATUS",
              value: contentPage?.status?.name ?? "-",
              colorLine: contentPage?.status?.color,
            },
            {
              title: "MARCA",
              value: contentPage?.name ?? "-",
            },
            {
              title: "DATA DO AGENDAMENTO",
              value:
                contentPage?.start_date && contentPage?.end_date
                  ? `${moment(contentPage?.start_date).format("DD/MM/YYYY")} - 
                  
                  
                  ${
                    moment(contentPage?.start_date).format("A") === "AM" ||
                    Number(moment(contentPage?.start_date).format("hh")) === 12
                      ? Number(moment(contentPage?.start_date).format("hh"))
                      : Number(moment(contentPage?.start_date).format("hh")) +
                        12
                  }:${moment(contentPage?.start_date).format("mm")}h
                  às 
                  ${
                    moment(contentPage?.end_date).format("A") === "AM" ||
                    Number(moment(contentPage?.end_date).format("hh")) === 12
                      ? Number(moment(contentPage?.end_date).format("hh"))
                      : Number(moment(contentPage?.end_date).format("hh")) + 12
                  }:${moment(contentPage?.end_date).format("mm")}h
                  
                  `
                  : `-`,
            },
            {
              title: "CRIADO EM",
              value: moment(contentPage?.created_at).format("DD/MM/YYYY"),
            },
            {
              title: "FINALIZADO EM",
              value: moment(contentPage?.closing_at).format("DD/MM/YYYY"),
              isNotVisible: !contentPage?.status.finisher,
            },
          ]}
        />

        {!provider && [2, 10].includes(Number(contentPage?.brand.cod)) && (
          <>
            <PanelFilter
              filters={filters}
              setFilters={setFilters}
              columns={columnsOc}
              onSubmit={formikFilter.handleSubmit}
              onResetForm={formikFilter.resetForm}
              setViewFilter={setViewFilter}
              viewFilter={viewFilter}
              normalizedFilters={(value, label) =>
                label === "errors" ? value.join(", ") : value
              }
            >
              <GroupInput>
                <Input
                  label="Código produto"
                  {...formikFilter.getFieldProps("productCod")}
                />
                <Input
                  label="Referência"
                  {...formikFilter.getFieldProps("reference")}
                />
              </GroupInput>

              <GroupInput>
                <Input
                  label="NF"
                  {...formikFilter.getFieldProps("invoiceNumber")}
                />
              </GroupInput>

              <Select
                label="Situação"
                data={[
                  { name: "AGUARDANDO", value: "aguardando" },
                  { name: "VALIDO", value: "valido" },
                  { name: "INVALIDO", value: "invalido" },
                ]}
                {...formikFilter.getFieldProps("isValid")}
              />

              <InputCheckboxV2
                height="120px"
                name="reasons"
                label="Motivos"
                checks={formikFilter.values.errors}
                onChange={(value) => {
                  formikFilter.setFieldValue("errors", value);
                }}
                data={
                  contentPage?.errors?.map((error) => ({
                    value: error.reason,
                    description: error.reason,
                  })) ?? []
                }
              />
            </PanelFilter>

            <TableHeader title="ITENS DA OC" isFetching={isLoadingOc}>
              <Popover
                isVisible
                Button={
                  <button style={{ background: "#1d6f42" }}>
                    <FaFileExcel size={19} />
                    <span>EXPORTAR</span>
                  </button>
                }
              >
                <li
                  onClick={() => {
                    handleExport(1);
                  }}
                >
                  <span>Exportar itens</span>
                </li>
                <li
                  onClick={() => {
                    handleExport(2);
                  }}
                >
                  <span>Exportar itens com grande</span>
                </li>
              </Popover>

              <button style={{ background: "#518de6" }} onClick={handleValidOC}>
                <MdOutlineChecklist size={19} />
                <span>REVALIDAR</span>
              </button>
            </TableHeader>

            <TableContent<OcScheduling>
              data={
                contentPage?.ocs
                  .filter((item) =>
                    filters.isValid === "aguardando"
                      ? item.isValid === null
                      : filters.isValid === "invalido"
                      ? item.isValid === false
                      : filters.isValid === "valido"
                      ? item.isValid === true
                      : true
                  )
                  .filter((f) =>
                    filters.invoiceNumber
                      ? Number(f.invoiceNumber) ===
                        Number(filters.invoiceNumber)
                      : true
                  )
                  .filter((f) =>
                    filters.reference ? f.reference === filters.reference : true
                  )
                  .filter((f) =>
                    filters.productCod
                      ? Number(f.productCod) === Number(filters.productCod)
                      : true
                  )
                  .filter((f) =>
                    filters.errors
                      ? arraysHaveSameElements(
                          filters.errors,
                          f.errors?.map((e) => e.reason) ?? []
                        )
                      : true
                  )
                  .sort((a, b) =>
                    a.isValid === false ? -1 : b.isValid === false ? 1 : 0
                  )
                  .slice((page - 1) * 5, page * 5)
                  .map((item) => ({
                    ...item,
                    productCod: item.productCod || "-",
                    totalFormat: Number(item.valueTotal).toLocaleString(
                      "pt-br",
                      {
                        style: "currency",
                        currency: "BRL",
                      }
                    ),
                    reason:
                      item?.errors?.map((i) => i.reason)?.join(",") ?? "-",
                  })) ?? []
              }
              columns={columnsOc.filter((f) => f.viewList)}
              isLoading={isLoadingOc}
            />
            <Pagination
              totalCountOfRegisters={
                contentPage?.ocs
                  .filter((item) =>
                    filters.isValid === "aguardando"
                      ? item.isValid === null
                      : filters.isValid === "invalido"
                      ? item.isValid === false
                      : filters.isValid === "valido"
                      ? item.isValid === true
                      : true
                  )
                  .filter((f) =>
                    filters.invoiceNumber
                      ? Number(f.invoiceNumber) ===
                        Number(filters.invoiceNumber)
                      : true
                  )
                  .filter((f) =>
                    filters.reference ? f.reference === filters.reference : true
                  )
                  .filter((f) =>
                    filters.productCod
                      ? Number(f.productCod) === Number(filters.productCod)
                      : true
                  )
                  .filter((f) =>
                    filters.errors
                      ? arraysHaveSameElements(
                          filters.errors,
                          f.errors?.map((e) => e.reason) ?? []
                        )
                      : true
                  ).length ?? 0
              }
              currentPage={page}
              onPageChange={setPage}
              registersPerPage={5}
            />
          </>
        )}

        {contentPage && (
          <InfoDevolution style={{ marginTop: "2rem" }}>
            <div className="headerDevoltion">
              <h2>Informações</h2>
            </div>
            <div className="bodyDevoltion">
              <div className="conatinerInfo">
                <h3>Fornecedor</h3>
                <div className="line"></div>

                <div>
                  <h4>CÓDIGO</h4>
                  <span>{contentPage.provider.id}</span>
                  <h4>NOME COMPLETO</h4>
                  <span>{contentPage.provider.fullname}</span>
                  <h4>E-MAIL</h4>
                  <span>{contentPage.provider.acess_provider.email}</span>
                  <h4>MARCA</h4>
                  <span>{contentPage.brand.name}</span>
                </div>
              </div>

              <div className="conatinerInfo">
                <h3>Dados do agendamento</h3>
                <div className="line"></div>
                <div>
                  <h4>CÓDIGO</h4>
                  <span>{contentPage.id}</span>
                  <h4>MARCA</h4>
                  <span>{contentPage.name}</span>
                  {contentPage?.type && (
                    <>
                      <h4>TIPO</h4>
                      <span>{contentPage?.type?.toLocaleUpperCase()}</span>
                    </>
                  )}

                  <h4>VOLUMES</h4>
                  <span>{contentPage.volume}</span>
                </div>
              </div>

              <div className="conatinerInfo">
                <h3>Valor do agendamento</h3>
                <div className="line" />
                <div>
                  <h4>CALÇADO</h4>
                  <span>
                    {Number(contentPage.footwear).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                  <h4>VESTUÁRIO</h4>
                  <span>
                    {Number(contentPage.apparel).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                  <h4>EQUIPAMENTO</h4>
                  <span>
                    {Number(contentPage.equipment).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                  <h4>TOTAL</h4>
                  <span>
                    {Number(
                      Number(contentPage.footwear) +
                        Number(contentPage.apparel) +
                        Number(contentPage.equipment)
                    ).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                </div>
              </div>

              {contentPage.observation && (
                <div className="conatinerInfo">
                  <h3>Observação</h3>
                  <div className="line" />
                  <span>{contentPage.observation}</span>
                </div>
              )}

              <div className="conatinerInfo">
                <h3>Arquivo de Romaneio</h3>
                <div className="line" />

                <div
                  className="containerImg"
                  style={{
                    overflowY: "auto",
                    maxHeight: "25vh",
                  }}
                >
                  {listFiles.map((img, index) => {
                    if (
                      img.mimetype !== "text/xml" &&
                      img.mimetype !== "application/pdf"
                    ) {
                      return (
                        <aside
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile({
                              filename: img.name,
                              key: img.key ?? "",
                              mimetype: img.mimetype ?? "",
                            })
                          }
                        >
                          <img src={icon_xlsx} alt={img.name} />
                          <span>{img.name}</span>
                        </aside>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>

              <div className="conatinerInfo">
                <div className="containerTitle">
                  <h3>Arquivos XML</h3>

                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleDownloadFile({
                        filename: `Todos XMLs - Agendamento:${contentPage.id}.zip`,
                        key: `Agendamentos/${contentPage.id}/xml/all.zip`,
                        mimetype: "application/octet-stream",
                      })
                    }
                  >
                    <FiDownload />
                    Baixar todos
                  </span>
                </div>

                <div className="line" />
                <div
                  className="containerImg"
                  style={{
                    overflowY: "auto",
                    maxHeight: "25vh",
                  }}
                >
                  {listFiles.map((img, index) => {
                    if (img.mimetype === "text/xml") {
                      return (
                        <aside
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile({
                              filename: img.name,
                              key: img.key ?? "",
                              mimetype: img.mimetype ?? "",
                            })
                          }
                        >
                          <img src={icon_xml} alt={img.name} />
                          <span>{img.name}</span>
                        </aside>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
              <div className="conatinerInfo">
                <div className="containerTitle">
                  <h3>Arquivo PDF</h3>

                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleDownloadFile({
                        filename: `Todos PDFs - Agendamento:${contentPage.id}.zip`,
                        key: `Agendamentos/${contentPage.id}/pdf/all.zip`,
                        mimetype: "application/octet-stream",
                      })
                    }
                  >
                    <FiDownload />
                    Baixar todos
                  </span>
                </div>

                <div className="line" />
                <div
                  className="containerImg"
                  style={{
                    overflowY: "auto",
                    maxHeight: "25vh",
                  }}
                >
                  {listFiles.map((img, index) => {
                    if (img.mimetype === "application/pdf") {
                      return (
                        <aside
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadFile({
                              filename: img.name,
                              key: img.key ?? "",
                              mimetype: img.mimetype ?? "",
                            })
                          }
                        >
                          <img src={icon_pdf} alt={img.name} />
                          <span>{img.name}</span>
                        </aside>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
            </div>
          </InfoDevolution>
        )}

        {admin && contentPage && (
          <InfoDevolution>
            <div className="headerDevoltion">
              <h2>LOGS </h2>
            </div>
            <div className="bodyDevoltion" style={{ padding: "1rem" }}>
              <ul style={{ listStyleType: "initial", marginLeft: "1rem" }}>
                {contentPage.logs.map((log) => (
                  <li>{`${
                    log?.user
                      ? `${log?.user.name} ${log?.user.lastname} (OPERADOR)`
                      : `${log?.provider?.fullname} (FORNECEDOR)`
                  } - ${log.status?.name} - ${moment(log.created_at).format(
                    "DD/MM/YYYY HH:mm"
                  )}`}</li>
                ))}
              </ul>
            </div>
          </InfoDevolution>
        )}

        {admin &&
          (contentPage?.brand.cod === 2 ? contentPage.is_valid_oc : true) &&
          Number(UserData.permission) ===
            Number(contentPage?.status.responsable_id) &&
          !contentPage?.status.finisher && (
            <InfoDevolution>
              <div className="headerDevoltion">
                <h2>Dados</h2>
              </div>

              <div className="bodyDevoltion">
                <Form onSubmit={formikInternalData.handleSubmit}>
                  <h3>Status</h3>

                  <div className="field">
                    <ContainerCheck
                      style={{
                        borderColor: selectStatus(
                          formikInternalData.values.status
                        )
                          ? selectStatus(formikInternalData.values.status)
                              ?.color
                          : undefined,
                      }}
                    >
                      {status.map((item) => {
                        if (contentPage?.status.is_valid) {
                          if (
                            (item.is_valid && item.finisher) ||
                            (item.cod === 9 && contentPage.status.cod !== 9)
                          ) {
                            return (
                              <button
                                type="button"
                                style={{
                                  background:
                                    formikInternalData.values.status ===
                                    item.cod
                                      ? item.color
                                      : undefined,
                                  borderColor: selectStatus(
                                    formikInternalData.values.status
                                  )
                                    ? selectStatus(
                                        formikInternalData.values.status
                                      )?.color
                                    : undefined,
                                }}
                                className={
                                  formikInternalData.values.status === item.cod
                                    ? "check"
                                    : ""
                                }
                                onClick={() =>
                                  formikInternalData.setFieldValue(
                                    "status",
                                    item.cod
                                  )
                                }
                              >
                                {item.name}
                              </button>
                            );
                          } else {
                            return null;
                          }
                        } else {
                          if (item.is_valid && item.finisher) {
                            return null;
                          } else {
                            return (
                              <button
                                type="button"
                                style={{
                                  background:
                                    formikInternalData.values.status ===
                                    item.cod
                                      ? item.color
                                      : undefined,
                                  borderColor: selectStatus(
                                    formikInternalData.values.status
                                  )
                                    ? selectStatus(
                                        formikInternalData.values.status
                                      )?.color
                                    : undefined,
                                }}
                                className={
                                  formikInternalData.values.status === item.cod
                                    ? "check"
                                    : ""
                                }
                                onClick={() =>
                                  formikInternalData.setFieldValue(
                                    "status",
                                    item.cod
                                  )
                                }
                              >
                                {item.name}
                              </button>
                            );
                          }
                        }
                      })}
                    </ContainerCheck>
                  </div>

                  {selectStatus(formikInternalData.values.status) &&
                    selectStatus(formikInternalData.values.status)
                      ?.is_observation && (
                      <div className="field">
                        <label htmlFor="observation">
                          Observação disponível para á marca
                        </label>
                        <textarea
                          id="observation"
                          {...formikInternalData.getFieldProps("observation")}
                        ></textarea>
                      </div>
                    )}

                  <div className="field">
                    <ButtunSubmit type="submit">Salvar alterações</ButtunSubmit>
                  </div>
                </Form>
              </div>
            </InfoDevolution>
          )}
      </CreateAndDetailLayout>

      {loadingScreen && (
        <ScreenLoading
          style={{
            height: "100vh",
            width: "100vw",
            position: "fixed",
            background: "#fff",
            opacity: 0.8,
            zIndex: 80,
            flexDirection: "column",
          }}
        >
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
          <span style={{ marginTop: "1rem", fontWeight: "bold" }}>
            GERANDO ARQUIVO...
          </span>
        </ScreenLoading>
      )}

      {
        <OcItemGrid
          data={modalOcItemGrid as OcScheduling}
          isVisible={!!modalOcItemGrid}
          onClose={() => setModalOcItemGrid(undefined)}
        />
      }
    </>
  );
};

export default Scheduling;
