import styled from "styled-components";

export const Field = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  margin-bottom: 24px;
  max-width: 100%;
  min-width: 150px;

  label {
    font-size: 0.875rem;
    margin-bottom: 4px;
    color: #555;

    .required {
      color: #f00;
      font-size: 11px;
    }
  }
  input {
    padding: 0 10px;
    background: #fff;
    min-height: 35px;
    max-height: 35px;
    font-size: 16px;
    color: #222;
    border: 2px solid #aaa;
    border-radius: 5px;
    flex: 1;

    width: 100%;
  }

  .selectClient {
    height: 40px;
    width: 100%;

    button {
      width: 100%;
      height: 100%;
      background: #d21e26;

      font-weight: bold;
      font-size: 1rem;
      color: #fff;
      border-radius: 5px;
    }
  }

  input:focus {
    border: 2px solid #333;
  }

  input::placeholder {
    color: #a0a0b2;
  }

  textarea {
    padding: 10px 10px;
    background: #fff;
    height: 80px;
    font-size: 16px;
    color: #222;
    border: 2px solid #aaa;
    border-radius: 5px;
    resize: vertical;

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: #333;
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #888;
    }

    &:focus {
      border: 2px solid #333;
    }
  }

  select {
    height: 35px;

    background: #fff;
    border-radius: 5px;
    border: 2px solid #aaa;
    padding: 4px 8px;

    font-size: 16px;
    color: #222 !important;
    cursor: pointer;
    /* -webkit-appearance: none; */
    -moz-appearance: none;
    /* appearance: none;  */

    &:focus {
      border: 2px solid #333;
      color: #333;
    }
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;

  padding: 5px 2px;
  span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #f00;
  }
`;

export const Contain = styled.div`
  display: flex;
  flex: 1;
  column-gap: 6px;

  button {
    background: transparent;
    color: #444;
    font-weight: bold;

    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100%;

    &:hover {
      transition: background 0.5s;
      background-color: #ddd;
    }
  }
`;
