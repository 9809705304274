import React, { ChangeEvent, FormEvent, useState } from "react";
import { BiMailSend } from "react-icons/bi";
import { FaArrowLeft, FaLock, FaUser, FaUserCircle } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { IPermissionUser } from "../../@types";
import Loading from "../../components/loadings/Loading";
import api from "../../service/api";
import * as authClient from "../../service/authClient";
import * as authProvider from "../../service/authProvider";
import * as authSeller from "../../service/authSeller";
import * as authUser from "../../service/authUser";
import * as clientStorage from "../../service/localStorage/client";
import * as PermissionUserStorage from "../../service/localStorage/permissionUser";
import * as ProviderStorage from "../../service/localStorage/provider";
import * as SellerStorage from "../../service/localStorage/seller";
import * as UserStorage from "../../service/localStorage/user";
import { ButtunSubmit } from "../../styles/global";
import logout from "../../utils/logout";
import { ContainerPrivacyPolicy } from "../HomeScreen/styles";
import { Container, ContainerInput, Error, Form } from "./styles";

interface IToken {
  access_token: string;
  iat: number;
  exp: number;
}

interface HeaderProps {
  admin?: boolean;
  client?: boolean;
  seller?: boolean;
  provider?: boolean;
}

interface User {
  id: string;
  name: string;
  lastname: string;
  email: string;
  token: string;
  permission: string;
}

interface Provider {
  id: string;
  email: string;
  fullname: string;
  phone: string;
  token: string;
  isShippingCompany: boolean;
}

interface Seller {
  id: number;
  cod: number;
  fullName?: string;
  abbreviation?: string;
  email: string;
  isSankhya: boolean;
  sellerTeamOn: boolean;
  brands: number[];

  is_manager: boolean;
  is_supervisor: boolean;

  securityCode: string;
  isSecurityCode: boolean;
}

interface Client {
  id?: string;
  cod?: number;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
}

interface ResponseSingInClient {
  client: Client;
  token: IToken;
}

export interface ResponseSingInSeller {
  seller: Seller;
  token: IToken;
}

interface ResponseSingInUser {
  user: User;
  token: IToken;
  permission: IPermissionUser;
}

interface ResponseSingInProvider {
  provider: Provider;
  token: IToken;
}

interface IRouteFirstAccess {
  token: string;
  email: string;
  cnpj: string;
  firtAccess: boolean;
}

const Login: React.FC<HeaderProps> = ({ admin, client, seller, provider }) => {
  const history = useHistory();

  const [error, setErrror] = useState("");
  const [loading, setLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [firstAccess, setFirstAccess] = useState(false);
  const [firstAccessEmail, setFirstAccessEMail] = useState("");
  const [formData, setFormData] = useState({
    cod: undefined,
    cnpj: "",
    email: "",
    password: "",
  });

  const [svgIcons, setSvgIcons] = useState({
    email: false,
    password: false,
  });

  function hadleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let data = value;
    // let tam = value.replace(/[^a-z0-9]/gi, "").length;

    // if (name === "cnpj") {
    //   if (tam <= 11) {
    //     //CPF
    //     console.log("CPF");
    //     data = data
    //       .replace(/\D/g, "")
    //       .replace(/(\d{3})(\d)/, "$1.$2")
    //       .replace(/(\d{3})(\d)/, "$1.$2")
    //       .replace(/(\d{2})(\d{1,2})/, "$1-$2")
    //       .replace(/(-\d{2})\d+?$/, "$1");
    //   } else {
    //     console.log("CNPJ");
    //     data = data
    //       .replace(/\D/g, "")
    //       .replace(/(\d{3})(\d)/, "$1.$2")
    //       .replace(/(\d{3})(\d)/, "$1.$2")
    //       .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    //       .replace(/(-\d{2})\d+?$/, "$1");
    //   }
    // }

    setFormData({
      ...formData,
      [name]: data,
    });
  }

  async function hadleSubmitUser(event: FormEvent) {
    event.preventDefault();
    const { email, password } = formData;

    setLoading(true);
    setErrror("");

    if (!email) {
      setLoading(false);
      return setErrror("Todos campos são obrigatorios");
    }

    if (!password) {
      setLoading(false);
      return setErrror("Todos campos são obrigatorios");
    }

    const data = { email, password };

    try {
      const response = await api.post<ResponseSingInUser>("/user/login", data);
      logout();
      setLoading(false);

      const { user, token, permission } = response.data;

      const { id, email, name, lastname } = user;

      UserStorage.setUser({
        id,
        email,
        name,
        lastname,
        permission: user.permission,
      });

      PermissionUserStorage.setUser({
        ...permission,
      });

      const { access_token, exp, iat } = token;
      authUser.setToken({
        access_token,
        exp,
        iat,
      });

      window.location.reload();
      history.push("/admin/home");
    } catch (err) {
      const error = err as any;

      setErrror("");
      setLoading(false);

      if (!error.response || error.response.status >= 404)
        return setErrror("Erro interno");

      if (error.response.status === 403)
        return setErrror("Login ou Senha incorretos");

      if (error.response.status === 401)
        return setErrror("Login ou Senha incorretos");
    }
  }

  async function hadleSubmitSeller(event: FormEvent) {
    event.preventDefault();
    const { cod, password } = formData;

    setLoading(true);
    setErrror("");

    if (!cod) {
      setLoading(false);
      return setErrror("Todos campos são obrigatorios");
    }

    if (!password) {
      setLoading(false);
      return setErrror("Todos campos são obrigatorios");
    }

    const data = { cod, password };

    try {
      const response = await api.post<ResponseSingInSeller>(
        "/seller/login",
        data
      );
      logout();
      setLoading(false);

      const { seller, token } = response.data;

      const {
        id,
        email,
        cod,
        abbreviation,
        fullName,
        is_manager,
        is_supervisor,
        isSankhya,
        brands,
        sellerTeamOn,
        securityCode,
        isSecurityCode,
      } = seller;

      SellerStorage.setUser({
        brands,
        isSankhya,
        id,
        email,
        cod,
        abbreviation,
        fullName,
        is_manager,
        is_supervisor,
        sellerTeamOn,
        securityCode,
        isSecurityCode,
      });

      const { access_token, exp, iat } = token;
      authSeller.setToken({
        access_token,
        exp,
        iat,
      });

      window.location.reload();
      history.push("/representante/home");
    } catch (err) {
      const error = err as any;

      setErrror("");
      setLoading(false);

      if (!error.response || error.response.status >= 404)
        return setErrror("Erro interno");

      if (error.response.status === 403)
        return setErrror("Login ou Senha incorretos");

      if (error.response.status === 401)
        return setErrror("Login ou Senha incorretos");
    }
  }

  async function hadleSubmitProvider(event: FormEvent) {
    event.preventDefault();
    const { email, password } = formData;

    setLoading(true);
    setErrror("");

    if (!email) {
      setLoading(false);
      return setErrror("Todos campos são obrigatorios");
    }

    if (!password) {
      setLoading(false);
      return setErrror("Todos campos são obrigatorios");
    }

    const data = { email, password };

    try {
      const response = await api.post<ResponseSingInProvider>(
        "/provider/auth/login",
        data
      );
      logout();
      setLoading(false);

      const { provider, token } = response.data;

      const { id, email, fullname, phone, isShippingCompany } = provider;

      ProviderStorage.setUser({
        id: Number(id),
        email: String(email),
        fullname: String(fullname),
        phone: String(phone),
        isShippingCompany: isShippingCompany,
      });

      const { access_token, exp, iat } = token;
      authProvider.setToken({
        access_token,
        exp,
        iat,
      });

      window.location.reload();
      history.push("/fornecedor/home");
    } catch (err) {
      const error = err as any;

      setErrror("");
      setLoading(false);

      if (!error.response || error.response.status >= 404)
        return setErrror("Erro interno");

      if (error.response.status === 403)
        return setErrror("Login ou Senha incorretos");

      if (error.response.status === 401)
        return setErrror("Login ou Senha incorretos");
    }
  }

  async function hadleSubmitClient(event: FormEvent) {
    event.preventDefault();
    setErrror("");
    setLoading(true);

    let cnpj = formData.cnpj.replace(/[^a-z0-9]/gi, "").trim();
    let password = formData.password;

    if (!password) {
      setLoading(false);
      return setErrror("Senha deve ser preenchida");
    }

    if (!cnpj) {
      setLoading(false);
      return setErrror("Todos campos são obrigatorios");
    }

    const data = { cnpj, password };
    try {
      logout();
      const response = await api.post<ResponseSingInClient>("/client/login", {
        cnpj: data.cnpj,
        password: data.password,
      });
      setLoading(false);

      const { client, token } = response.data;

      const { id, cod, cnpj, company_name, trade_name, email } = client;

      clientStorage.setUser({
        id,
        cod,
        cnpj,
        company_name,
        trade_name,
        email,
      });

      const { access_token, exp, iat } = token;
      authClient.setToken({
        access_token,
        exp,
        iat,
      });
      window.location.reload();
      history.push("/home");
    } catch (err) {
      const error = err as any;
      setLoading(false);

      if (!error.response || error.response.status >= 404)
        return setErrror("Erro interno");

      if (error.response.status === 403)
        return setErrror("Login ou Senha incorretos");

      if (error.response.status === 401)
        return setErrror("Login ou Senha incorretos");
    }
  }

  async function hadleSubmitClientCnpj(event: FormEvent) {
    event.preventDefault();

    setErrror("");
    setLoading(true);

    let cnpj = formData.cnpj.replace(/[^a-z0-9]/gi, "");

    if (!cnpj) {
      setLoading(false);
      return setErrror("CNPJ deve ser preenchido");
    }

    try {
      const response = await api.post<IRouteFirstAccess>(
        "/client/firstAccess",
        { cnpj }
      );

      if (!response.data.firtAccess) {
        setLoading(false);
        setFirstAccessEMail("");
        setFirstAccess(false);
        return setViewPassword(true);
      } else {
        setLoading(false);
        setFirstAccess(true);
        setFirstAccessEMail(response.data.email);
      }
    } catch (err) {
      const error = err as any;
      setLoading(false);

      if (!error.response || error.response.status >= 404)
        return setErrror("Erro interno");

      if (error.response.status === 403)
        return setErrror("Usuário bloqueado ou inexistente");
    }
  }

  return (
    <>
      <Container
        style={{
          backgroundColor: admin
            ? "#444"
            : seller
            ? "#D0222D"
            : client
            ? "#F0F0F0"
            : provider
            ? "#fff"
            : "#000",
        }}
      >
        <button
          id="arrowBack"
          type="button"
          onClick={() => {
            history.push("/");
          }}
          style={{
            color: admin ? "#fff" : seller ? "#fff" : "#333",
          }}
        >
          <FaArrowLeft size={26} />
        </button>

        <div>
          <svg
            id="logo-alpar"
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1181.000000 528.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,528.000000) scale(0.100000,-0.100000)"
              fill={
                admin ? "#FFF" : seller ? "#FFF" : provider ? "#333" : "#D0222D"
              }
              stroke="none"
            >
              <path
                d="M3886 4869 c-65 -16 -197 -83 -291 -150 -123 -87 -321 -288 -435
-440 -189 -252 -425 -681 -559 -1012 -51 -125 -73 -168 -109 -208 -41 -46
-129 -115 -137 -107 -1 1 1 50 6 107 16 184 -22 311 -124 413 -166 166 -554
204 -904 87 -238 -79 -493 -261 -493 -352 0 -84 48 -78 265 32 232 118 299
135 520 135 172 0 223 -11 307 -67 74 -50 108 -184 64 -251 -17 -27 -21 -28
-70 -23 -28 4 -88 13 -134 21 -348 64 -712 28 -958 -94 -252 -125 -423 -352
-424 -560 0 -222 163 -418 389 -466 88 -18 312 -18 431 1 306 49 514 145 890
410 83 59 153 105 155 103 2 -2 -3 -35 -11 -73 -10 -45 -14 -105 -12 -170 4
-110 21 -156 75 -207 135 -129 457 -63 807 165 49 32 90 56 92 54 5 -5 -175
-229 -376 -467 -478 -565 -710 -895 -821 -1166 -118 -289 -72 -447 129 -447
83 1 149 25 235 86 219 157 480 570 680 1077 111 280 180 416 312 615 84 126
149 204 207 251 l41 32 46 -36 c200 -152 570 -134 945 47 111 54 234 131 311
195 88 73 81 73 77 2 -15 -226 143 -429 378 -487 78 -20 350 -18 465 4 177 32
320 81 510 173 330 159 568 349 739 589 247 346 497 555 662 555 18 0 36 -4
39 -10 3 -5 -64 -98 -148 -207 -277 -355 -489 -662 -588 -851 -46 -87 -49
-135 -9 -177 57 -60 130 -54 226 21 215 167 629 413 920 547 1048 483 1990
534 2803 151 88 -42 117 -51 127 -43 25 20 15 34 -53 76 -271 165 -661 294
-1054 348 -161 22 -523 30 -694 16 -546 -47 -1086 -205 -1633 -480 -57 -29
-100 -46 -96 -39 164 278 445 548 729 700 115 62 140 90 115 135 -30 54 -25
53 -575 51 -575 -3 -551 0 -601 -81 -122 -195 -295 -437 -312 -437 -4 0 -7 69
-7 153 0 150 0 153 -32 220 -63 133 -172 212 -348 253 -123 29 -387 26 -515
-5 -222 -54 -405 -148 -542 -279 -71 -68 -78 -78 -78 -114 0 -32 4 -40 24 -48
37 -14 84 3 256 91 168 86 251 112 409 130 139 15 272 -2 362 -47 107 -54 150
-166 104 -276 -11 -28 -38 -30 -152 -9 -439 83 -837 36 -1094 -130 -45 -29
-60 -35 -55 -22 33 85 44 220 26 301 -36 156 -168 283 -340 327 -183 47 -493
-2 -747 -120 -42 -19 -78 -34 -80 -32 -2 2 13 44 32 92 40 98 37 110 -25 99
-84 -14 -199 -119 -304 -279 -33 -50 -88 -134 -124 -188 -45 -68 -115 -149
-232 -270 -261 -268 -427 -403 -615 -497 -163 -82 -267 -98 -317 -48 -19 19
-20 28 -14 103 11 147 66 277 159 373 23 24 105 95 182 157 337 271 626 547
789 750 196 246 360 528 433 742 29 87 37 128 41 209 5 121 -11 184 -61 232
-53 51 -115 63 -211 41z m37 -270 c17 -27 -28 -147 -105 -280 -167 -292 -418
-621 -642 -843 -89 -88 -316 -279 -323 -272 -7 6 62 176 107 266 88 177 269
458 431 670 152 199 342 392 443 451 55 31 73 33 89 8z m960 -1408 c251 -123
32 -498 -416 -711 -274 -131 -481 -154 -585 -64 -82 70 -87 187 -14 329 84
161 308 331 552 420 120 43 186 54 305 52 93 -2 118 -6 158 -26z m-3258 -406
c95 -20 170 -58 184 -94 34 -81 -64 -218 -225 -312 -155 -91 -273 -125 -469
-135 -187 -10 -302 22 -353 97 -31 44 -25 109 18 195 70 142 222 233 445 264
82 12 314 3 400 -15z m4538 -6 c146 -22 229 -57 247 -105 20 -52 -24 -148
-102 -220 -49 -47 -169 -122 -247 -153 -203 -84 -495 -101 -627 -36 -72 35
-99 79 -91 148 22 180 197 325 444 367 72 12 294 11 376 -1z m-3205 -1296
c-229 -448 -420 -757 -561 -910 -109 -118 -178 -79 -111 63 62 132 449 665
702 967 36 42 66 77 68 77 2 0 -42 -89 -98 -197z"
              />
              <path
                d="M11285 2595 c-39 -38 -28 -93 22 -114 51 -21 103 13 103 69 0 29 -44
70 -75 70 -14 0 -37 -11 -50 -25z"
              />
              <path
                d="M9860 2400 l-21 -30 23 0 c21 0 98 44 98 55 0 3 -18 5 -39 5 -33 0
-43 -5 -61 -30z"
              />
              <path
                d="M8915 2341 c-22 -10 -48 -32 -58 -49 -23 -40 -32 -119 -18 -155 15
-38 67 -59 118 -49 32 7 39 12 41 36 3 28 3 28 -33 22 -44 -9 -65 6 -65 45 0
42 16 76 45 95 22 14 30 15 53 5 22 -11 28 -10 39 5 18 25 16 42 -4 48 -55 15
-81 15 -118 -3z"
              />
              <path
                d="M9384 2348 c-38 -10 -52 -25 -60 -65 -5 -26 0 -37 31 -72 52 -57 38
-87 -30 -61 -25 10 -26 9 -23 -22 2 -25 9 -33 32 -39 66 -18 126 18 126 76 0
26 -9 45 -37 75 -27 31 -33 44 -24 53 8 8 19 8 40 0 26 -9 30 -8 40 15 7 13
10 27 7 29 -12 12 -74 19 -102 11z"
              />
              <path
                d="M10905 2352 c-36 -7 -65 -43 -65 -79 0 -24 10 -44 35 -72 30 -33 33
-40 20 -51 -11 -9 -22 -9 -46 -1 -42 15 -41 15 -37 -21 2 -26 9 -33 33 -40 34
-9 97 5 114 26 6 7 14 27 17 43 5 26 0 37 -31 72 -46 50 -42 75 9 66 29 -6 35
-4 41 15 4 12 3 25 -2 30 -11 10 -64 18 -88 12z"
              />
              <path
                d="M8055 2338 c-3 -7 -16 -66 -29 -130 l-23 -118 32 0 32 0 15 83 16 82
1 -82 1 -83 34 0 c32 0 35 3 67 74 18 41 35 73 37 71 4 -4 -16 -109 -25 -132
-4 -9 5 -13 29 -13 l33 0 24 118 c13 64 26 123 28 130 4 8 -9 12 -44 12 l-49
0 -42 -92 -41 -93 -1 93 0 92 -45 0 c-27 0 -47 -5 -50 -12z"
              />
              <path
                d="M8376 2226 c-36 -68 -66 -126 -66 -130 0 -3 16 -6 35 -6 28 0 38 5
47 25 9 20 18 25 50 25 34 0 38 -3 38 -25 0 -22 4 -25 36 -25 l36 0 -6 53 c-3
28 -8 87 -12 130 l-6 77 -44 0 -43 0 -65 -124z m104 14 c0 -47 -2 -50 -26 -50
-22 0 -24 3 -18 23 9 29 33 77 39 77 3 0 5 -22 5 -50z"
              />
              <path
                d="M8611 2233 c-13 -65 -26 -124 -29 -130 -3 -9 7 -13 31 -13 37 0 38 1
51 63 9 38 19 34 36 -15 14 -39 18 -43 53 -46 20 -2 37 -1 37 2 0 2 -9 26 -20
52 l-21 47 21 22 c11 11 23 35 26 52 5 26 1 37 -19 57 -22 22 -35 26 -84 26
l-59 0 -23 -117z m114 43 c0 -18 -29 -45 -39 -36 -2 3 -2 18 1 33 6 32 38 34
38 3z"
              />
              <path
                d="M9085 2220 l-65 -130 38 0 c29 0 39 5 47 23 7 16 20 23 47 25 33 3
38 0 44 -22 5 -21 12 -26 36 -26 l31 0 -7 103 c-10 159 -10 157 -62 157 l-44
0 -65 -130z m105 20 c0 -43 -3 -50 -20 -50 -11 0 -20 7 -20 15 0 14 31 85 37
85 2 0 3 -22 3 -50z"
              />
              <path
                d="M9621 2243 c-12 -60 -24 -118 -27 -130 -6 -22 -4 -23 75 -23 l81 0 0
30 c0 28 -2 30 -41 30 -34 0 -39 3 -35 18 7 23 36 161 36 173 0 5 -15 9 -34 9
l-33 0 -22 -107z"
              />
              <path
                d="M9812 2235 c-13 -64 -25 -122 -29 -131 -5 -12 1 -15 32 -12 l39 3 26
128 27 127 -36 0 -37 0 -22 -115z"
              />
              <path
                d="M9941 2233 c-13 -65 -26 -124 -28 -130 -4 -10 13 -13 63 -13 114 0
164 48 164 159 0 75 -21 94 -106 99 l-69 4 -24 -119z m127 23 c7 -57 -20 -106
-57 -106 -17 0 -17 4 -3 70 14 62 18 71 36 68 15 -2 22 -12 24 -32z"
              />
              <path
                d="M10191 2233 c-13 -65 -26 -124 -28 -130 -4 -10 15 -13 75 -13 77 0
80 1 84 25 7 32 2 35 -43 35 -36 0 -38 2 -30 23 6 16 18 23 47 27 31 4 40 10
42 28 3 19 -1 22 -32 22 -29 0 -36 4 -36 20 0 17 7 20 45 20 25 0 45 3 45 8 0
4 3 17 6 30 6 21 4 22 -73 22 l-78 0 -24 -117z"
              />
              <path
                d="M10420 2346 c0 -3 -11 -56 -25 -120 -14 -64 -25 -120 -25 -126 0 -5
15 -10 34 -10 34 0 35 2 42 45 8 55 23 54 42 -3 13 -40 16 -42 53 -42 l39 0
-23 52 -23 51 23 23 c30 30 31 86 3 114 -16 16 -33 20 -80 20 -33 0 -60 -2
-60 -4z m88 -74 c2 -13 -3 -25 -13 -28 -22 -9 -29 1 -21 31 8 31 30 28 34 -3z"
              />
              <path
                d="M10659 2348 c0 -2 -13 -60 -28 -130 l-27 -128 78 0 c43 0 78 3 78 8
0 4 3 17 6 30 6 20 3 22 -36 22 -37 0 -41 2 -38 23 2 18 10 23 44 25 35 3 43
7 47 28 4 22 1 24 -34 24 -32 0 -39 3 -39 20 0 17 7 20 44 20 31 0 46 5 50 16
15 40 9 44 -69 44 -41 0 -75 -1 -76 -2z"
              />
            </g>
          </svg>
        </div>
        <Form>
          {admin ? (
            <>
              <form onSubmit={hadleSubmitUser}>
                <ContainerInput>
                  <FaUser color={svgIcons.email ? "#333" : "#888"} size={20} />
                  <input
                    placeholder="Digite seu e-mail"
                    type="email"
                    id="email"
                    name="email"
                    onChange={hadleInputChange}
                    onFocus={() => {
                      setSvgIcons({ ...svgIcons, email: true });
                    }}
                    onBlur={() => {
                      setSvgIcons({ ...svgIcons, email: false });
                    }}
                  />
                </ContainerInput>

                <ContainerInput>
                  <FaLock
                    color={svgIcons.password ? "#333" : "#888"}
                    size={20}
                  />
                  <input
                    placeholder="Digite sua senha"
                    type="password"
                    id="password"
                    name="password"
                    onChange={hadleInputChange}
                    onFocus={() => {
                      setSvgIcons({ ...svgIcons, password: true });
                    }}
                    onBlur={() => {
                      setSvgIcons({ ...svgIcons, password: false });
                    }}
                  />
                </ContainerInput>

                {error && (
                  <Error>
                    <FiAlertCircle size={24} color="#ffcc00" />
                    <span>{error}</span>
                  </Error>
                )}

                <ButtunSubmit
                  style={{
                    backgroundColor: admin ? "#444" : "#D21E26",
                    opacity: loading ? 0.8 : 1,
                  }}
                  type="submit"
                >
                  {loading ? (
                    <Loading
                      size={22}
                      borderSize={4}
                      colorLoading="rgba(255,255,255)"
                      borderColor="rgba(255,255,255, 0.3)"
                    />
                  ) : (
                    "Entrar"
                  )}
                </ButtunSubmit>
              </form>
            </>
          ) : null}

          {client ? (
            firstAccess ? (
              <div className="containerAlertMail">
                <div className="containerLogoMail">
                  <BiMailSend />
                </div>
                <span>
                  Te enviamos uma confirmação, verifique seu e-mail{" "}
                  <b>{firstAccessEmail}</b> 😀
                </span>
              </div>
            ) : viewPassword ? (
              <>
                <form onSubmit={hadleSubmitClient}>
                  <header className="headerViewPassword">
                    <p>
                      <FaUserCircle size={24} /> {formData.cnpj}
                    </p>
                  </header>

                  <ContainerInput>
                    <FaLock
                      color={svgIcons.password ? "#333" : "#888"}
                      size={20}
                    />
                    <input
                      placeholder="Digite sua senha"
                      type="password"
                      id="password"
                      name="password"
                      onChange={hadleInputChange}
                      value={formData.password}
                      onFocus={() => {
                        setSvgIcons({ ...svgIcons, password: true });
                      }}
                      onBlur={() => {
                        setSvgIcons({ ...svgIcons, password: false });
                      }}
                    />
                  </ContainerInput>

                  {error && (
                    <Error>
                      <FiAlertCircle size={24} color="#ffcc00" />
                      <span>{error}</span>
                    </Error>
                  )}

                  <Link to="/cliente/senha/resetar">Esqueci minha senha</Link>

                  <div className="containerButtons">
                    <ButtunSubmit
                      style={{ backgroundColor: "#fff", color: "#D21E26" }}
                      type="button"
                      onClick={() => {
                        setViewPassword(false);
                      }}
                    >
                      Voltar
                    </ButtunSubmit>
                    <ButtunSubmit
                      style={{ backgroundColor: "#D21E26" }}
                      type="submit"
                    >
                      {loading ? (
                        <Loading
                          size={22}
                          borderSize={4}
                          colorLoading="rgba(255,255,255)"
                          borderColor="rgba(255,255,255, 0.3)"
                        />
                      ) : (
                        "Acessar"
                      )}
                    </ButtunSubmit>
                  </div>
                </form>
              </>
            ) : (
              <>
                <form onSubmit={hadleSubmitClientCnpj}>
                  <header
                    style={{
                      marginBottom: 15,
                      fontWeight: 700,
                      fontSize: 25,
                      color: "#333",
                      display: "flex",
                      width: "100%",
                      alignItems: "flex-start",
                    }}
                  >
                    <p>Entrar</p>
                  </header>

                  <ContainerInput>
                    <FaUser
                      color={svgIcons.email ? "#333" : "#888"}
                      size={20}
                    />
                    <input
                      placeholder="Digite seu CNPJ"
                      type="text"
                      id="cnpj"
                      maxLength={19}
                      name="cnpj"
                      onChange={hadleInputChange}
                      value={formData.cnpj}
                      onFocus={() => {
                        setSvgIcons({ ...svgIcons, email: true });
                      }}
                      onBlur={() => {
                        setSvgIcons({ ...svgIcons, email: false });
                      }}
                    />
                  </ContainerInput>

                  {error && (
                    <Error>
                      <FiAlertCircle size={24} color="#ffcc00" />
                      <span>{error}</span>
                    </Error>
                  )}

                  <ButtunSubmit
                    style={{ backgroundColor: admin ? "#444" : "#D21E26" }}
                    type="submit"
                  >
                    {loading ? (
                      <Loading
                        size={22}
                        borderSize={4}
                        colorLoading="rgba(255,255,255)"
                        borderColor="rgba(255,255,255, 0.3)"
                      />
                    ) : (
                      "Continuar"
                    )}
                  </ButtunSubmit>
                </form>
              </>
            )
          ) : null}

          {seller ? (
            <>
              <form onSubmit={hadleSubmitSeller}>
                <ContainerInput>
                  <FaUser color={svgIcons.email ? "#333" : "#888"} size={20} />
                  <input
                    placeholder="Digite seu código de representante"
                    type="number"
                    id="cod"
                    name="cod"
                    onChange={hadleInputChange}
                    onFocus={() => {
                      setSvgIcons({ ...svgIcons, email: true });
                    }}
                    onBlur={() => {
                      setSvgIcons({ ...svgIcons, email: false });
                    }}
                  />
                </ContainerInput>

                <ContainerInput>
                  <FaLock
                    color={svgIcons.password ? "#333" : "#888"}
                    size={20}
                  />
                  <input
                    placeholder="Digite sua senha"
                    type="password"
                    id="password"
                    name="password"
                    onChange={hadleInputChange}
                    onFocus={() => {
                      setSvgIcons({ ...svgIcons, password: true });
                    }}
                    onBlur={() => {
                      setSvgIcons({ ...svgIcons, password: false });
                    }}
                  />
                </ContainerInput>

                {error && (
                  <Error>
                    <FiAlertCircle size={24} color="#ffcc00" />
                    <span>{error}</span>
                  </Error>
                )}

                <Link to="/representante/senha/resetar">
                  Esqueci minha senha
                </Link>

                <ButtunSubmit
                  style={{
                    backgroundColor: admin ? "#444" : "#D21E26",
                    opacity: loading ? 0.8 : 1,
                  }}
                  type="submit"
                >
                  {loading ? (
                    <Loading
                      size={22}
                      borderSize={4}
                      colorLoading="rgba(255,255,255)"
                      borderColor="rgba(255,255,255, 0.3)"
                    />
                  ) : (
                    "Entrar"
                  )}
                </ButtunSubmit>
              </form>
            </>
          ) : null}

          {provider ? (
            <>
              <form onSubmit={hadleSubmitProvider}>
                <ContainerInput>
                  <FaUser color={svgIcons.email ? "#333" : "#888"} size={20} />
                  <input
                    placeholder="Digite seu e-mail"
                    type="text"
                    id="email"
                    name="email"
                    onChange={hadleInputChange}
                    onFocus={() => {
                      setSvgIcons({ ...svgIcons, email: true });
                    }}
                    onBlur={() => {
                      setSvgIcons({ ...svgIcons, email: false });
                    }}
                  />
                </ContainerInput>

                <ContainerInput>
                  <FaLock
                    color={svgIcons.password ? "#333" : "#888"}
                    size={20}
                  />
                  <input
                    placeholder="Digite sua senha"
                    type="password"
                    id="password"
                    name="password"
                    onChange={hadleInputChange}
                    onFocus={() => {
                      setSvgIcons({ ...svgIcons, password: true });
                    }}
                    onBlur={() => {
                      setSvgIcons({ ...svgIcons, password: false });
                    }}
                  />
                </ContainerInput>

                {error && (
                  <Error>
                    <FiAlertCircle size={24} color="#ffcc00" />
                    <span>{error}</span>
                  </Error>
                )}

                <Link
                  style={{ color: provider ? "#444" : undefined }}
                  to="/fornecedor/senha/resetar"
                >
                  Esqueci minha senha
                </Link>

                <ButtunSubmit
                  style={{
                    backgroundColor: admin
                      ? "#444"
                      : provider
                      ? "#444"
                      : "#D21E26",
                    opacity: loading ? 0.8 : 1,
                  }}
                  type="submit"
                >
                  {loading ? (
                    <Loading
                      size={22}
                      borderSize={4}
                      colorLoading="rgba(255,255,255)"
                      borderColor="rgba(255,255,255, 0.3)"
                    />
                  ) : (
                    "Entrar"
                  )}
                </ButtunSubmit>
              </form>
            </>
          ) : null}
        </Form>
      </Container>
      <div
        style={{
          backgroundColor: admin
            ? "#444"
            : seller
            ? "#D0222D"
            : client
            ? "#F0F0F0"
            : provider
            ? "#fff"
            : "#000",
        }}
      >
        <ContainerPrivacyPolicy
          style={{
            height: 64,
            marginTop: 0,
            color: seller || admin ? "#fff" : undefined,
          }}
        >
          <a
            // eslint-disable-next-line
            target="_blank"
            href="https://privacidade.alpardobrasil.com.br/"
            className="profile-details"
            style={{ color: seller || admin ? "#fff" : undefined }}
          >
            <span>Política de Privacidade</span>
          </a>

          <span>© 2021 Alpar do Brasil</span>
        </ContainerPrivacyPolicy>
      </div>
    </>
  );
};

export default Login;
