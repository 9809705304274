import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FaPaperclip } from "react-icons/fa";
import { IoReload } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { panelTypesDefault } from "../../../@types/panelTypes";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { Select } from "../../../components/Form/Select";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { Pagination } from "../../../components/panel/table/Pagination";
import {
  FieldProps,
  TableContent,
} from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import {
  RequestUpdateClient,
  RequestUpdateClientFilters,
  useRequestUpdateClient,
} from "../../../hook/queries/useRequestUpdateClient";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import { objectToForEach } from "../../../utils/objectToForEach";
import { setFormikValues } from "../../../utils/setFormikValues";

interface PanelProps extends panelTypesDefault {}

export const columns: FieldProps<RequestUpdateClient>[] = [
  {
    name: "id",
    field: "Id",
    viewList: false,
  },
  {
    name: "file",
    field: "",
    viewList: true,
    render(row) {
      return row.file.length > 0 ? <FaPaperclip /> : "";
    },
  },
  {
    name: "clientFormatted",
    field: "Cliente",
    viewList: true,
  },
  {
    name: "typeFormatted",
    field: "Tipo",
    viewList: true,
  },
  {
    name: "statusFormatted",
    field: "Situação",
    viewList: true,
    render: (data) => (
      <span
        style={{
          background: data.status?.color,
          fontWeight: "bold",
          borderRadius: 6,
          padding: 4,
          color: "#000",
        }}
      >
        {data.status?.name}
      </span>
    ),
  },
  {
    name: "created_atFormatted",
    field: "Data",
    viewList: true,
  },

  {
    name: "client",
    field: "Cliente",
    viewList: false,
    notViewFilter: true,
  },
  {
    name: "type",
    field: "Tipo",
    viewList: false,
    notViewFilter: true,
  },
  {
    name: "statusCode",
    field: "Situação",
    viewList: false,
    notViewFilter: true,
  },
  {
    name: "seller",
    field: "Representante",
    viewList: false,
    notViewFilter: true,
  },
  {
    name: "isFinished",
    field: "Finalizado",
    viewList: false,
    notViewFilter: true,
  },
];

const PanelUpdateImagens: React.FC<PanelProps> = ({ admin }) => {
  const history = useHistory();
  const query = useQueryParams();

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });
  const [filters, setFilters] = useState<RequestUpdateClientFilters>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });
  const [viewFilter, setViewFilter] = useState(false);

  const { data, isLoading, isFetching, refetch } = useRequestUpdateClient(
    page,
    filters
  );

  const formikFilter = useFormik({
    initialValues: {
      client: "",
      seller: "",
      type: "",
      statusCode: "",
      isFinished: "",
    },
    onSubmit: (data) => {
      handleFilter(data);
    },
  });

  async function handleFilter(data: RequestUpdateClientFilters) {
    setFilters(data);
    setPage(1);
    setViewFilter(false);
  }

  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de Atualização cadastral" />

      <PanelFilter
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        onSubmit={formikFilter.handleSubmit}
        onResetForm={formikFilter.resetForm}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        normalizedFilters={(value, label) => {
          switch (label) {
            case "type":
              return value === "image"
                ? "Imagem"
                : value === "update"
                ? "Alteração"
                : "Cadastro";
          }

          return value;
        }}
      >
        <GroupInput>
          <Input
            label="Cliente"
            {...formikFilter.getFieldProps("client")}
            placeholder="Buscar por código, razão social ou cnpj"
          />
        </GroupInput>

        <Select
          label="Representante"
          {...formikFilter.getFieldProps("seller")}
          data={
            data?.filters.sellers.map((item) => ({
              value: item.id,
              name: `${item.id} ${item.name}`,
            })) ?? []
          }
        />

        <GroupInput>
          <Select
            label="Tipo"
            {...formikFilter.getFieldProps("type")}
            data={
              data?.filters.types.map((type) => ({
                value: type.id,
                name: type.name,
              })) ?? []
            }
          />
          <Select
            label="Situação"
            {...formikFilter.getFieldProps("status")}
            data={
              data?.filters.status.map((type) => ({
                value: type.id,
                name: type.name,
              })) ?? []
            }
          />
          <Select
            label="Finalizado"
            {...formikFilter.getFieldProps("isFinished")}
            data={[
              {
                name: "Sim",
                value: "sim",
              },
              {
                name: "Não",
                value: "não",
              },
            ]}
          />
        </GroupInput>
      </PanelFilter>

      <TableHeader title="Atualizações" isFetching={isFetching && !isLoading}>
        <button onClick={() => refetch()}>
          <IoReload size={19} />
          <span>RECARREGAR</span>
        </button>
      </TableHeader>

      <TableContent<RequestUpdateClient>
        data={data?.requestUpdateClients}
        columns={columns.filter((f) => f.viewList)}
        isLoading={isLoading}
        onClickRow={(e) =>
          history.push(
            `/${admin ? "admin" : "representante"}/atualizacao-fotos/${e.id}`
          )
        }
      />
      <Pagination
        totalCountOfRegisters={data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
      />
    </PanelLayout>
  );
};

export default PanelUpdateImagens;
